@use "sass:color";
@use "utilities/index" as *;

@mixin onlyCards {
  .idf-search-wrapper {
    .idf-border,
    .idf-view-mode {
      display: none;
    }
  }
}

.idf-content-panel {
  //google like search
  .idf-page-wrapper.idf-policy-search {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: inherit;
    margin-top: 60px;
    @media screen and (max-height: 780px) {
      margin-top: 30px;
    }

    main {
      padding-bottom: 25vh;
      max-width: 400px;
      width: 90%;

      header {
        color: $flatblue;
        text-align: center;
        font-weight: 700;
        font-size: 46px;
        line-height: 58px;
        @media screen and (max-height: 780px) {
          font-size: 26px;
        }
      }

      .idf-search {
        position: relative;
        color: $flatblue;

        & > span {
          display: block;
          text-align: center;
          font-size: 20px;
          @media screen and (max-height: 780px) {
            font-size: 16px;
          }
        }

        .idf-search-input {
          margin-top: 20px;
          padding-left: 13px;
          border: 1px solid $bluegray;
          border-radius: 4px;
          background: white;
        }
      }
    }
  }

  .idf-search-wrapper {
    margin-bottom: 20px;
    position: relative;
    z-index: 44;

    .idf-btn-menu {
      min-width: 220px;
    }

    .idf-search-bar {
      display: flex;
      border: 1px solid $flatblue;
      border-radius: 4px;
      background: white;

      .idf-search {
        flex: 1;
        padding: 0 10px;
        border-right: 1px solid $flatblue;
      }

      .idf-actions {
        --paper-listbox-background-color: #dfe6ec;
        display: flex;
        align-items: center;
        padding: 0 10px;

        paper-dropdown-menu {
          --paper-input-container-shared-input-style_-_vertical-align: middle;
        }

        .idf-btn {
          margin: 0 10px;

          &:last-child {
            margin-right: 0;
          }

          &.idf-active {
            background: $lightblue;
            color: $bluegray;
          }
        }

        .idf-border {
          display: inline-block;
          width: 1px;
          height: 100%;
          background: $flatblue;
        }

        .idf-view-mode {
          --paper-icon-button_-_width: 30px;
          --paper-icon-button_-_height: 30px;
          display: flex;
          margin: 0 0 0 10px;
          border-radius: 3px;

          paper-icon-button {
            width: 30px;
            height: 30px;
            background-color: $bluegray;
            transition: all 0.3s;

            &:hover {
              background-color: color.adjust($bluegray, $lightness: -15%);
              color: $darkblue;
            }

            &:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            &:last-child {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }

          & > paper-icon-button:nth-child(1) {
            border-right: 1px solid $flatblue;
          }

          & > * {
            color: #8a9aa8;
            line-height: 1;

            &.idf-active {
              --iron-icon-fill-color: #{$darkblue};
              color: $darkblue;
            }
          }
        }
      }
    }

    .idf-advanced-filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: calc(1em + 4px);
      @media only screen and (max-width: 1270px) {
        height: 145px; //IE TEMP FIX
      }

      .idf-advanced-btn {
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
        padding-bottom: 3px;
      }
      //Filter btns align top
      .idf-row {
        align-self: flex-start;
        margin-top: 11px;
      }
    }
  }

  .idf-search-table {
    position: relative;

    .idf-row {
      margin-top: 5px;
      background: white;
    }

    .idf-header .idf-flex-row {
      padding: 1em 0.8em;
    }

    .idf-only-for-card {
      display: none;
    }

    .idf-table-content {
      // temp fix
      height: calc(100vh - 250px);

      .idf-action {
        position: relative;
        text-align: right;

        .idf-contextual-menu {
          top: auto;
          bottom: 1.8em;
          width: 152px;
        }
      }

      i.idf-contextual-menu-icon {
        display: block;
        float: right;
        margin-right: 9px;
        width: 2em;
        height: 100%;
        background-image: url("../img/contextual-menu.svg");
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      &--hasSearch {
        height: calc(100vh - 330px);
      }
    }
  }
}

.idf-search-table {
  @media only screen and (max-width: 960px) {
    //only cards
    .idf-search-wrapper {
      .idf-border,
      .idf-view-mode {
        display: none!important;
      }

      .idf-actions {
        padding-right: 0!important;
      }
    }
  }
}

.idf-search-table .idf-table:not(.idf-card-view) .cellListWidget > div {
  .cellListEvenItem:nth-child(1),
  .cellListEvenItem:nth-child(3),
  .cellListOddItem:nth-child(2) {
    .idf-contextual-menu {
      top: 1.7em;
      bottom: auto;
    }
  }
}

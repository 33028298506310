@use "sass:color";
@use "variables" as *;
@use "mixins" as *;

.idf-regular {
  @include bodytext();
}

.idf-bold-text {
  @include boldtext();
}

.idf-all-caps-label {
  @include allcapslabel();
}

.idf-important {
  @include bigboldtext();
}

.idf-body {
  color: $bodytext;
}

.idf-darkblue {
  color: $darkblue;
}

.idf-lightblue {
  color: $lightblue;
}

.idf-flatblue {
  color: $flatblue;
}

.idf-bluegray {
  color: $bluegray;
}

.idf-red {
  color: $red;
}

.idf-green {
  color: $green;
}

.idf-darkblue-bg {
  background-color: $darkblue;
}

.idf-white {
  color: white;
}

.idf-card {
  padding: 1em;
  border: 1px solid $darkgray;
  border-radius: 6px;
  background: white;

  &__header {
    padding: 15px;
    border-bottom: 1px solid $bluegray;
  }

  &__header-left {
    display: flex;
    justify-content: flex-start;
  }

  &__header-extra {
    position: relative;
    border-left: 1px solid #dfe6ec;
  }

  &__title {
    display: block;
    padding-bottom: 1rem;
    color: $darkblue;
    font-weight: 600;
    font-size: 20px;

    &--small {
      font-size: 0.9rem;
    }
  }

  &__content {
    padding: 1rem 0;
    font-size: 1rem;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1rem;

    &__footer {
      padding: 15px;
      font-size: 0.8rem;
    }

    .idf-btn {
      margin-top: 5px;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    &--alignCenter {
      justify-content: center;

      .idf-btn {
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    &--alignRight {
      justify-content: flex-end;

      .idf-btn {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  &--padding {
    padding: 30px;
  }

  &--medium {
    max-width: calc(100% - 30px);
    width: 440px;
  }

  &--noPadding {
    padding: 0;

    .idf-card__header {
      padding: 0;
    }

    .idf-card__title {
      padding: 0;
    }

    .idf-card__content {
      padding: 15px;
    }
  }

  &--centered {
    margin-right: auto;
    margin-left: auto;
  }
}

.idf-tag {
  display: inline-block;
  align-self: center;
  padding: 0.3em 0.55em;
  border-radius: 3px;
  background: $lightblue;
  color: white;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

  &--small {
    padding: 6px 10px;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 0.6rem;
    line-height: 1;
  }

  &--rounded {
    border-radius: 32px;
  }

  &--SUCCESS,
  &--success {
    background: $green;
    color: #fff;
  }

  &--warning {
    background: #ff9900;
    color: #fff;
  }

  &--FAILED,
  &--danger {
    background: $red;
    color: #fff;
  }

  &--icon {
    display: inline-flex;
    align-items: center;

    .bi {
      position: relative;
      top: 1px;
    }
  }

  &--bgFade {
    &.idf-tag--SUCCESS,
    &.idf-tag--success {
      background: rgba($green, 0.2);
      color: $green;
    }

    &.idf-tag--warning {
      background: rgba(#ff9900, 0.2);
      color: #ff9900;
    }

    &.idf-tag--FAILED,
    &.idf-tag--danger {
      background: rgba($red, 0.2);
      color: $red;
    }
  }
}

button.idf-tag {
  border: none;
}

.idf-divider {
  margin: 0;
  border-top: 1px solid $bluegray;
  border-bottom: none;
}

.idf-btn {
  @include allcapslabel();
  display: inline-block;
  padding: 0 40px;
  outline: none;
  border: none;
  border-radius: 3px;
  background: $flatblue;
  box-shadow: none;
  color: $lightblue;
  line-height: 2.3rem; //Shrink the height with the font size
  cursor: pointer;
  transition: $btnanimation;
  user-select: none;
  @media (max-width: 1600px) {
    padding: 0 25px;
  }

  &:active,
  &:hover {
    background-color: $darkblue;
    color: white;
    text-decoration: none;
  }

  &.idf-small {
    padding: 0 20px;
    line-height: 30px;
    @media (max-width: 1600px) {
      padding: 0 15px;
    }
  }

  &--blue {
    background-color: $blue;
    color: #fff;

    &:hover {
      background-color: color.adjust($blue, $lightness: -15%);
      color: #fff;
    }
  }

  &--lightblue {
    background-color: $lightblue;
    color: #fff;

    &:hover {
      background-color: color.adjust($lightblue, $lightness: 15%);
    }
  }

  &--green {
    background-color: $green;
    color: #fff;

    &:hover {
      background-color: color.adjust($green, $lightness: -15%);
    }
  }

  &--hasIcon {
    display: inline-flex;
    gap: 8px;

    svg {
      position: relative;
      top: -1px;
      vertical-align: middle;
    }
  }

  &--disabled,
  &[disabled] {
    background: color.adjust(#bfcdd9, $lightness: 15%) !important;
    color: color.adjust(#425a70, $lightness: 25%) !important;
    cursor: default;
  }
}

.idf-btn.idf-primary {
  background: $darkblue;
  color: #fff;

  &:active,
  &:hover {
    background: $lightblue;
    color: #fff;
  }
}

.idf-btn.idf-secondary {
  background: $bluegray;
  color: $blue;

  &:active,
  &:hover {
    background-color: $flatblue;
  }
}

.idf-btn.idf- {}

.idf-full-height {
  // empty for now
}

.idf-btn.idf-link {
  display: inline-block;
  background-color: transparent;

  &:hover {
    background-color: $bluegray;
    color: $blue;
  }

  &:active {
    background-color: $flatblue;
    color: $blue;
  }
}

.idf-btn.idf-red {
  display: inline-block;
  background-color: rgba($red, 0.1);
  color: $red;

  &.idf-link {
    background-color: transparent;

    &:hover {
      background-color: rgba($red, 0.1);
      color: $red;
    }
  }

  &:hover {
    background-color: $red;
    color: white;
  }

  &:active {
    background-color: $red;
    color: white;
  }
}

.idf-link {
  color: $blue;
  cursor: pointer;

  &:hover {
    color: $lightblue;
  }
}

.idf-white.idf-link {
  margin-right: 4px;
  color: white;

  &:hover {
    color: $blue;
  }
}

.idf-hidden {
  display: none !important;
  height: 0;
  transition: all 0.4s ease;
}

.idf-spinner {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0 auto;
  width: 85px;
  height: 85px;
  transform: translateY(-50%);
  pointer-events: none;
}

.idf-full-width {
  width: 100%;
}

a {
  color: $blue;
  cursor: pointer;

  &:hover {
    color: $lightblue;
  }
}

a.idf-white {
  color: white;

  &:hover {
    color: white;
  }
}

.idf-row {
  display: flex;
}
//container top actions

.idf-top-actions-wrapper {
  position: fixed;
  top: 53px;
  right: 15px;
  z-index: 991;
  display: flex;
  justify-content: flex-end;
  padding-top: 6px;
  width: 100%;
}

.idf-top-actions {
  --paper-listbox-background-color: #dfe6ec;
  z-index: 3;
  display: flex;
  gap: 6px;

  .idf-btn {
    margin: 0;
  }
}
// Search Filters

.idf-filters-wrapper {
  display: flex;
  flex: 1;
  @media only screen and (max-width: 1270px) {
    flex-wrap: wrap;
    margin-bottom: -10px;

    & > * {
      margin-bottom: 10px;
      max-width: 100%;
      width: calc(25% - 20px) !important;
    }
  }

  & > * {
    margin-right: 20px;
    min-width: 20px;
    width: 100%;
  }
}

.idf-separator {
  margin-top: 30px;
  margin-bottom: 8px;
  padding-bottom: 8px;
  width: 100% !important;
  border-bottom: 1px solid $bluegray;
  color: $flatlightblue;
}

.idf-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.idf-no-transform {
  text-transform: none !important;
}

.idf-url-text {
  color: $blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.idf-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.idf-hide-view-all {
  .idf-view-all {
    display: none !important;
  }
}

.idf-status {
  @include allcapslabel();
  color: $green !important;
  line-height: 15px;

  &.idf-expired {
    color: $flatblue !important;
  }

  &.idf-cancelled {
    color: $red !important;
  }
}

.idf-priority {
  @include allcapslabel();
  color: $flatblue !important;
  line-height: 15px !important;

  &.idf-low {
    color: $accent !important;
  }

  &.idf-medium {
    color: #c58d17 !important;
  }

  &.idf-high {
    color: $red !important;
  }
}

.idf-info-icon {
  display: inline-block;
  width: 18px;
  height: 12px;
  background: no-repeat center;
  background-image: url("../img/info-icon.svg");
  background-size: contain;
}

paper-input.idf-suffix.idf-input {
  --paper-input-container-input_-_padding: 9px 25px 9px 10px !important;

  & > div {
    margin-left: -150%;
  }
}

vaadin-date-picker-overlay {
  z-index: 1001;
}

.gwt-PopupPanel {
  z-index: 16;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px, rgba(0, 0, 0, 0.3) 0 18px 36px -18px;

  .iconsTable {
    border-spacing: 3px;

    td {
      padding: 6px;
      border-radius: 6px;
      background: $darkblue;
      line-height: 1;
      cursor: pointer;
      transition: all 0.3s;

      img {
        width: 16px;
        height: auto;
      }

      &:hover {
        background: $blue;
      }
    }
  }

  .gwt-Label {
    color: $red;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.gwt-selected-icon {
  text-align: center;

  img {
    width: 16px;
    height: auto;
    filter: brightness(0);
  }
}

paper-dropdown-menu {
  paper-listbox {
    --paper-listbox-background-color: #ffffff;
    padding: 0;

    paper-item {
      transition: background 0.3s, color 0.3s;

      &:hover {
        background: color.adjust($bluegray, $lightness: -15%);
        cursor: pointer;
      }

      &.iron-selected {
        background: $blue;
        color: #fff;
      }
    }
  }
}

.meddDisplay {
  position: fixed !important;
}

.OP {
  height: calc(100vh - 130px) !important;
}

.GU {
  height: 100% !important;
}

.idf-idfetch-iframe {
  @extend .scrollbar;
  width: 100%;
  height: calc(100vh - 140px);
  border: none;
  border-radius: 6px;
  background: #fff;
}

.idf-info-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  gap: 12px;
}

.idf-info-card {
  padding: 10px;
  width: 220px;
  border: 1px solid $bluegray;
  border-radius: 8px;
  list-style-type: none;
  font-size: 0.8rem;

  &__title {
    display: block;
    margin-bottom: 2px;
    font-weight: 600;
  }
}

.idf-attachment-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 6px 12px;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 32px;
  background-color: $bluegray;
  color: $blue;
  transition: all 0.3s;

  .bi{
    font-size: .9rem;
  }

  &:hover {
    color: $blue;
    background-color: color.adjust($bluegray, $lightness: -5%);
    text-decoration: underline;
  }
}

@use "../../utilities/index" as *;

/* SCENE TREE */

.idf-graph-scene-tree {
  background: $gray;
  border: 1px solid $darkgray;
  top: 85px;
  right: 30px;
  padding: 15px 20px;
  min-width: 153px;
  position: absolute;
  transition: all 0.2s ease;
}

.idf-graph-scene-tree summary {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

ul.idf-sceneTree {
  margin: 12px 0 0;
  list-style: none;
}

.idf-sceneItem .icon {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
}

.idf-sceneItem {
  margin-bottom: 5px;
  font-weight: 600;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

details[open] > ul {
  animation-name: slideDown;
  animation-duration: 0.2s;
  animation-timing-function: ease;
  overflow: hidden;
}

.idf-sceneItem {
  display: flex;
  align-items: center;
}

.idf-sceneItem .iconAction {
  cursor: pointer;
  height: 15px;
  transition: all .3s ease;
  background: no-repeat center;
  background-image: url('../img/graph/icon-visible.svg');
  display: block;
  width: 15px;
  opacity: 0.7;
  background-size: 85%;
}

.idf-sceneItem--hidden .iconAction {
  background-image: url('../img/graph/icon-invisible.svg') !important;
  opacity: .25;
}
@use "variables" as *;
@use "mixins" as *;

.idf-aside-content {
  //smaller table no data warning
  .idf-warning {
    padding: 0.3em 1em !important;
    background-color: $flatblue;
    color: $darkblue;
    border-radius: 3px;
    margin-top: 3px;
    display: block; //maybe inline-block
    opacity: 0.6;
  }
  //aside top row + actions
  .idf-action-row {
    justify-content: space-between;
    align-items: flex-start;

    .idf-actions {
      margin-top: -10px;
      margin-bottom: 5px;
    }
  }

  .idf-list .idf-warning {
    padding: 5px 0 !important;
  }
  //inside scroll
  .idf-scroll-slot {
    height: inherit;

    .idf-header {
      position: sticky;
      top: 0;
    }
  }

  .idf-section-title {
    margin-top: 35px;
    @include bigboldtext()
  }

  .idf-gray-cell .idf-row {
    background-color: $gray!important;
    margin-top: 5px;
  }

  .idf-small-section-title {
    font-size: .8rem;
    font-weight: 600;
    color: $darkblue;
    border-bottom: 1px solid $bluegray;
    padding-bottom: 8px;
    margin-bottom: 8px;
    margin-top: 1.5rem;
  }
}

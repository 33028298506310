@use "../../utilities/index" as *;

.idf-uv-review-view {
  //general info
  .idf-row {
    align-items: flex-start;
    justify-content: space-between;

    .idf-actions {
      margin-top: -10px;
      margin-bottom: 5px;
    }
  }

  section > .idf-bold-text.idf-darkblue {
    margin-top: 35px;
    margin-bottom: 7px;
    border-bottom: 1px solid $bluegray;
    line-height: 1.6;
  }

  .idf-uv-review-data {
    margin-top: 15px;

    .idf-list-details {
      display: flex;
      flex-wrap: wrap;

      & > * {
        margin-right: 20px;
        width: calc(50% - 40px);
      }
    }

    .idf-status-data {
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 25px;
    }

    .idf-change-status {
      display: flex;
      align-items: flex-end;
      margin: 10px 0 35px;

      .idf-btn {
        margin-bottom: 3px;
      }
    }

    paper-input,
    paper-input[focused],
    vaadin-combo-box {
      margin-right: 15px;
      width: 30%!important;
    }
  }

  .idf-addl {
    $columns: 14% 1fr 16% 17% 16% 78px;
    @include fixTable($columns);
    margin-bottom: 35px;
  }
}

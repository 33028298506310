@use "../../utilities/index" as *;

.context-menu:focus {
  outline: 0;
}

.context-menu * {
  user-select: none;
  vertical-align: middle;
  color: #666666;
}

.context-menu {
  position: absolute;
  display: block;
  opacity: 0;
  user-select: none;
  background-color: #f7f7f7;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition: opacity 0.2s ease-out, width 0.2s ease-out;
}

#contextMenu {
  border: 1px solid $darkgray;
  background-color: $gray;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
}

#contextMenu ul {
  margin: 4px 0;
  list-style: none;
}

#contextMenu .idf-contextMenu-item {
  padding: 4px 22px;
  transition: all 0.15s ease;
  cursor: pointer;
}

#contextMenu .idf-contextMenu-item:hover {
  background-color: #000;
}

#contextMenu .idf-contextMenu-item:hover {
  background: rgba(0, 0, 0, 0.04);
}

#contextMenu .idf-contextMenu-item:active {
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

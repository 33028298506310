@use "../../utilities/index" as *;

#nodePopupContent {
  background: $gray;
  border: 1px solid $darkgray;
  padding: 18px 20px;
  display: block;
  height: auto;
  z-index: 2;
  width: auto;
  position: absolute;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.25s ease;
  transform: translateX(-45px) translateY(-110px);
}

#nodePopupContent div {
  display: flex;
  align-content: center;
  margin-bottom: 5px;
  padding: 0;
  color: inherit;
  background: none;
  font-weight: 400;
  text-shadow: none;
}

.expiry-date {
  margin-bottom: 0;
}

#nodePopupContent label,
#nodePopupContent span {
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  background: none;
  font-weight: 500;
}

#nodePopupContent span {
  text-transform: capitalize;
}

#nodePopupContent div span {
  font-weight: 600;
  margin-left: 5px;
}

@use "../../utilities/index" as *;

.idf-uw-review-addl {
  .idf-row {
    margin-bottom: 15px;

    paper-input {
      max-width: 300px;
    }

    vaadin-combo-box,
    vaadin-date-picker {
      margin-left: 15px;
      max-width: 200px;
    }
  }

  paper-checkbox {
    display: block;
  }

  paper-button {
    margin: 30px 0;
  }
}

.idf-collapse {
  display: block;
  width: 14px;
  height: 14px;
  background-image: url("../img/colapse-icon.svg");
  background-size: cover;
  opacity: 0.28;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &.idf-collapsed {
    background-image: url("../img/expand-icon.svg");
  }
}

@use "../../utilities/index" as *;

.idf-policy-images {
  grid-template-columns: 3fr 1.8fr 1.2fr;

  & > div {
    border-radius: 6px;
  }

  img {
    max-width: 100% !important;
  }

  .idf-top-actions-wrapper {
    padding: 0;
    border: none;
    background: none;
  }

  .idf-car-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3px;

    a,
    label,
    span {
      font-size: 1rem;
    }

    label {
      width: 40%;
    }
  }

  &__pictures-wrapper {
    display: block;

    .idf-tabs {
      padding: 0 !important;
    }
  }

  &__pictures {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 1rem;
    height: calc(100vh - 220px);

    img {
      border-radius: 6px;
    }

    .thumbnail {
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;

      img {
        width: 100%;
        border: 2px solid transparent;
        border-radius: 6px;
        transition: border-color 0.3s, opacity 0.3s;
      }

      &.active,
      &:hover {
        img {
          border-color: #d04a10;
          opacity: 1;
        }
      }
    }

    .idf-vehicle-thumb {
      width: 120px;
      height: 100%;

      .thumbnail p.current {
        position: absolute;
        right: 0;
        bottom: 8px;
        left: 0;
        margin: 0 auto;
        padding: 4px 2px;
        width: 100px;
        border-radius: 32px;
        background: rgba(#000000, 0.6);
        font-size: 10px !important;
      }
    }

    .idf-vehicle-pic {
      width: calc(100% - 130px);
      height: 100%;

      canvas {
        border-radius: 6px;
      }
    }
  }

  &__tabs {}

  &__history {}

  .idf-tabs {
    padding: 1rem;

    .idf-tabs-menu {
      paper-tab {
        &:hover {
          &:after {
            background: #e97e50;
          }
        }

        &.idf-active {
          &:after {
            background: #CE4D16;
          }
        }
      }
    }
  }

  .idf-types-tabs {
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .idf-policy-images {
    .idf-vehicle-data-history {
      .cellListEvenItem .cellListOddItem {
        .cell {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;

          span {
            display: block;
            float: none !important;
          }

          .idf-number {
            width: calc(100% - 46px);
          }

          .idf-date {
            padding-left: 42px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .idf-vehicle-data-history {
    .cellListEvenItem,
    .cellListOddItem {
      padding: 10px 0;
      min-height: 42px;

      .idf-icon.legacy {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      .idf-number {
        display: block;
        float: none;
        padding-left: 40px;
        width: 100%;
        line-height: 1;
      }
    }
  }
}

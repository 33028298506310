@use "sass:color";
@use "../../utilities/index" as *;

.idf-advanced-analytics-summary {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  @media only screen and (min-width: 1100px) {
    .idf-top-connected-people {
      margin: 0 20px 10px 0;
    }

    .idf-top-connected-addresses {
      margin-bottom: 10px;
    }

    .idf-top-connected-vehicles {
      margin-top: 10px;
    }

    .idf-top-connected-phones {
      margin: 10px 0 0 20px;
    }
  }

  & > div {
    margin: 5px;
    padding: 15px;
    width: calc(50% - 10px);
    border: 1px solid $darkgray;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 4px 25px rgba(0,0,0,.08);
    //full width on smaller screen
    @media only screen and (max-width: 1100px) {
      margin-bottom: 15px;
      width: calc(100% - 10px);
    }

    .idf-widget-base__settings {
      display: none;
    }
  }
}

.idf-top-connected-summary {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2%;

    label {
      @include allcapslabel();
      display: inline-block;
      margin: 0;
      cursor: default;
    }

    button {
      @include boldtext();
      display: inline-block;
      padding: 0;
      border: none;
      background: none;
      color: $blue;
      transition: color 0.3s;
      -webkit-appearance: none;

      &:hover {
        color: color.adjust($blue, $lightness: 20%);
      }
    }
  }

  .idf-table {
    $columns: 100px 1fr 70px 110px;
    @include fixTable($columns);

    .idf-flex-table {
      @include md {
        .idf-flex-row {
          padding: 0.4em;
        }
      }
      @include lg {
        .idf-flex-row {
          padding: 0.55em;
        }
      }
    }

    .idf-all-caps-label.idf-link {
      margin-left: auto;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .idf-row:hover .idf-all-caps-label.idf-link {
      opacity: 1;
    }

    .idf-flags {
      font-weight: $bold;
    }

    .idf-slot > div > div:last-of-type .idf-flex-row {
      border-bottom: none;
    }

    .idf-slot > div > div:last-of-type,
    .idf-slot > div > div:nth-child(8),
    .idf-slot > div > div:nth-child(9) {
      .idf-contextual-menu {
        top: auto;
        bottom: 0.7em;
      }
    }

    .idf-c-1 {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;

      i {
        margin: 0;
        vertical-align: middle;
      }
    }
  }
}

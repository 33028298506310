@use "sass:color";
@use "utilities/index" as *;

.idf-details-page {
  overflow-y: auto;
  height: inherit;
  border-radius: 5px;

  &--default {
    padding-top: 200px;
    height: calc(100vh - 150px);
    @media only screen and (max-width: 1200px) {
      padding-top: 250px;
    }
    @media only screen and (max-width: 850px) {
      padding-top: 0;
    }
  }

  .idf-container {
    position: relative;
  }

  .idf-top-details {
    position: fixed;
    top: 120px;
    z-index: 22;
    display: flex;
    flex-flow: wrap;
    max-width: calc(100% - 250px);
    width: $container;
    border: 1px solid $bluegray;
    border-radius: 4px;
    background-color: white;
    //Mobile full width
    @media only screen and (max-width: 850px) {
      position: absolute;
      max-width: 100%;
    }

    &.idf-collapsed {
      .idf-title-wrapper {
        border-bottom: 1px solid transparent!important;
        transition-delay: 0.1s;
        transition-timing-function: ease-out;
        transition-duration: 0.1s;
      }

      .idf-score {
        transform: scale(0.5)!important;
      }

      small {
        display: none!important;
      }

      .idf-details-panel {
        display: flex!important;
        padding: 0 20px !important;
        height: 0!important;
      }
    }

    .idf-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid $bluegray;

      & > span {
        display: flex;
        align-items: center;
      }

      .idf-title {
        margin: 7px 7px 7px 18px;
        text-transform: capitalize;
        font-weight: normal;
        font-size: 19px;

        b {
          font-weight: $bold;
        }
      }
    }

    .idf-details-left {
      width: calc(100% - 140px);
      border-right: 1px solid $bluegray;

      .idf-details-panel {
        display: flex;
        overflow: hidden;
        padding: 20px 20px 25px;
        transition: all 0.2s linear;
        @media only screen and (max-width: 1200px) {
          flex-wrap: wrap;

          & > div {
            &:nth-child(n+3),
            &:nth-child(n+4) {
              margin-top: 15px;
            }
          }
        }

        & > div {
          margin-bottom: -.5rem;
          width: 50%;

          &:last-of-type {
            width: 40%;
          }
        }

        .idf-list-detail {
          margin-bottom: 0.5rem;
          padding-right: 10px;
        }
      }
    }
    //Responsive nav v.2
    nav {
      display: flex;
    }

    .idf-contextual-menu {
      width: 160px;
    }

    .idf-details-right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      text-align: center;

      .idf-top-actions {
        position: absolute;
        top: -57px;
        right: 0;
        display: flex;

        .idf-btn {
          margin-left: 15px;
        }
      }

      .idf-score {
        margin-bottom: 0;
        color: $lightblue;
        font-size: 55px;
        transition: all 0.2s linear;
        transform: scale(1);
      }

      small {
        @include allcapslabel();
        color: $lightblue;
      }
    }
    // &--sticky{
    //   max-width: 100%;
    //   width: 100%;
    //   position: sticky;
    //   top: 0;
    //   box-shadow: 0px -15px 0px 0px #f3f3f4;
    // }
  }

  .idf-tables {
    padding-top: 190px;
    @media only screen and (max-width: 1200px) {
      padding-top: 200px;
    }
    @media only screen and (max-width: 1160px) {
      padding-top: 210px;
    }

    &--noPadding {
      padding-top: 0;
    }
    //focus row
    .idf-row {
      box-shadow: inset 0 0 0 0 $flatblue;
      transition: box-shadow 0.2s ease;
    }

    .idf-focus .idf-row,
    .idf-focus.idf-row {
      box-shadow: inset 0 0 0 1.5px $flatblue;
    }

    .idf-table .idf-row {
      margin-top: 4px;
      background: white;

      .idf-flex-row {
        padding: 0.7em 0.8em;
      }
    }

    section {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding-bottom: 0;
      transition: padding-bottom 0.3s ease;

      &:hover .idf-section-title-wrapper span {
        color: $lightblue;
      }

      & > header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .idf-section-title-wrapper {
          display: flex;
          align-items: baseline;

          span {
            @include boldtext();
            margin-left: 8px;
            color: #425a708c;
            opacity: 0;
            transition: all 0.5s ease-out;
            pointer-events: none;
          }
        }

        .idf-collapse {
          display: block;
          width: 14px;
          height: 14px;
          background-image: url("../img/colapse-icon.svg");
          background-size: cover;
          opacity: 0.28;
          cursor: pointer;
          transition: all 0.25s ease-in-out;

          &:hover {
            opacity: 1;
          }
        }
      }

      .idf-action {
        position: relative;

        i.idf-contextual-menu-icon {
          display: block;
          float: right;
          margin-right: 4px;
          width: 2em;
          height: 100%;
          background-image: url("../img/contextual-menu.svg");
          background-position: center;
          background-size: 90%;
          background-repeat: no-repeat;

          &:hover {
            cursor: pointer;
          }
        }

        .idf-contextual-menu {
          min-width: 155px; //make it work for longer text
        }
      }

      .idf-warning {
        display: block;
        padding: 0.7em 0.8em;
        border-radius: 4px;
        background-color: $flatblue;
        color: $darkblue;
      }

      & > .idf-link {
        align-self: flex-end;
        padding: 10px 0;
        max-height: 30px;
        opacity: 1;
        transition: max-height 0.3s ease, padding 0.3s ease;
      }

      &.idf-no-data {
        .idf-header {
          display: none;
        }

        & > .idf-link {
          display: none;
        }
      }

      &.idf-collapsed {
        padding-bottom: 0px!important;

        .idf-section-title-wrapper span {
          opacity: 1;
          pointer-events: initial;
        }

        & > .idf-link,
        .idf-table {
          opacity: 0;
          transition: opacity 0.5s ease-out;
        }

        & > header .idf-collapse {
          background-image: url("../img/expand-icon.svg");
        }

        .idf-all-caps-label.idf-link {
          padding: 0;
          max-height: 0;
          pointer-events: none;
        }
      }

      &.idf-hide-view-all {
        padding-bottom: 30px;

        .idf-all-caps-label.idf-link {
          display: none;
        }
      }
    }
  }

  .idf-card {
    &--noPadding {
      .idf-card__header {
        padding: 0;
      }

      .idf-card__title {
        padding: 12px;
      }
    }

    &__content {
      .idf-card__title {
        padding-top: 0;
        padding-left: 0;
      }
    }
  }
}

.idf-detail-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: $lightblue;

  &__aside {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 400px;
    border-right: 1px solid $bluegray;
    background: #fafafa;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: #fafafa;
  }

  &__footer-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid $bluegray;
    font-size: 0.9rem;
  }

  &__main {
    width: calc(100% - 400px);
  }

  &__content {
    padding: 15px;
  }

  &__header {
    position: sticky;
    top: -4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    min-height: 45px;
    border-bottom: 1px solid $bluegray;
    background: #fff;

    &--border {
      border-top: 1px solid $bluegray;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__text {
    font-size: 0.9rem;
    line-height: 1.7;

    p {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.idf-info-item {
  display: inline-block;
  min-width: 172px;

  &__label {
    display: block;
    margin-bottom: 4px;
    color: #8398AB;
    font-weight: 600;
    font-size: 0.8rem;
  }

  &__value {
    font-size: 0.9rem;
  }

  &--full {
    display: block;
    width: 100%;
  }
}

.idf-idresolve-answers {
  &__switch {
    display: flex;
    display: inline-block;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    color: #859EB2;
    font-weight: 600;

    .idf-tag {
      display: inline-flex;
      align-items: center;
      margin-left: 12px;
      padding: 6px 14px;
      background-color: #859EB2;
      font-weight: 600;
      font-size: 0.8rem;
      transition: all 0.3s;

      .bi {
        display: none;
        margin-right: 4px;
        animation: zoomIn 0.5s;
      }

      &.is-active,
      &:Hover {
        background-color: $orange;
        color: #fff;
      }

      &.is-active {
        .bi {
          display: inline-block;
        }
      }
    }
  }

  &__accordion {
    position: relative;
    margin-bottom: 25px;
    border: 1px solid $bluegray;
    border-radius: 6px;
    font-size: 14px;

    &--byDate {
      .idf-idresolve-answers__toggle {
        position: absolute;
        top: 30px;
        right: 15px;
        width: 130px;
        border-radius: 6px;
        background-color: #DFE6EC;
        color: $lightblue;
      }

      .idf-idresolve-answers__question {
        &--byQuestion {
          display: none;
        }

        &--byDate {
          display: block;
        }
      }

      .idf-idresolve-answers__content {
        border-top: 1px solid $bluegray;
      }

      .idf-answer-list__item:last-child {
        border-bottom: none;
      }
    }
  }

  &__top {
    small {
      display: inline-block;
      color: $flatlightblue;

      &:before {
        display: inline-block;
        margin-right: 12px;
        margin-left: 12px;
        content: '●';
      }
    }
  }

  &__right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }

  &__question {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid $bluegray;
    background-color: #f4f7f9;
    font-size: 13px;
    gap: 10px;

    & > div {
      width: calc(100% - 90px);
    }

    & > .idf-idresolve-answers__title {
      display: block;
      padding: 0 !important;
      color: $lightblue;
      font-weight: 600;
      font-size: 13px;
    }
    // & > .idf-idresolve-answers__text {
    //   max-width: 650px;
    //   color: $orange;
    //   font-weight: 600;
    // }
    &--byQuestion {}

    &--byDate {
      display: block;
    }
  }

  &__title {
    display: block;
    font-weight: 400;

    p {
      margin: 0;
    }
  }

  &__text {
    line-height: 1.6;
  }

  &__helpText {
    display: block;
    margin-top: 4px;
    width: 100%;
    font-size: 0.8rem;
    line-height: 1.4;
  }

  &__rule {
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid $bluegray;
    font-size: 13px;
  }

  &__failReasons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 12px;
    border-bottom: 1px solid $bluegray;
    list-style-type: none;
    gap: 10px;

    li {
      width: calc(33.33333% - 10px);
      color: $lightblue;
      font-size: 13px;

      label {
        display: block;
        margin-bottom: 0;
        width: 100%;
        color: $flatlightblue;
        font-size: 13px;
        cursor: text;
      }

      strong {
        color: $lightblue;
        font-weight: 600;
      }

      &:first-child {
        margin-bottom: 4px;
        width: 100%;
        color: $flatlightblue;
        font-weight: 600;
        font-size: 13px;
      }
    }

    &:empty {
      display: none;
    }
  }

  &__content {
    display: none;

    .idf-idresolve-answers__title {
      display: block;
      padding: 10px 15px;
    }
  }

  &__date {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    color: #748fa8;
    font-weight: 600;
    gap: 14px;

    svg {
      color: $orange;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;

    .idf-btn {
      padding: 0;
      background-color: #fff;
      color: $flatlightblue;
      font-size: 0.65rem !important;
      line-height: 1;

      &:Hover {
        color: $orange;
      }
    }
  }

  &__toggle {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 0;
    padding-left: 0;
    width: auto;
    border: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #fff;
    color: $flatlightblue;
    font-weight: 600;
    font-size: 0.7rem;
    transition: color 0.3s, background-color 0.3s;

    .bi {
      margin-left: 4px;
      width: 12px;
      transition: transform 0.3s;
    }

    &:Hover {
      color: $orange;
    }

    &.is-active {
      // color: $orange;
      .bi {
        transform: rotate(-180deg);
      }
    }
  }

  &__group {
    overflow: hidden;
    margin: 12px;
    width: calc(100% - 24px);
    border: 1px solid $bluegray;
    border-radius: 6px;
    background-color: color.adjust($bluegray, $lightness: 50%);

    .idf-idresolve-answers__question-item {
      &:last-child {
        .idf-answer-list__item {
          border-bottom: none;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }
    }
  }

  &__error {
    padding: 12px;
    border-bottom: 1px solid $bluegray;
    background: rgba($orange, .05);
    color: $orange;
    text-align: center;
    font-size: 0.8rem;
  }

  .idf-tab-content {
    animation: fadeIn 0.5s;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.idf-answer-list {
  margin: 0;
  padding: 0;
  border-top: 1px solid $bluegray;
  list-style-type: none;

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid $bluegray;

    &:hover {
      .idf-answer-list__toggle {
        opacity: 1;
      }
    }

    a {
      display: inline-flex;
      color: $blue;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        text-decoration: underline;
      }

      .bi {
        position: relative;
        top: 1px;
        margin-right: 4px;
      }
    }

    &:nth-of-type(odd) {
      background-color: #f2f5f7;
    }

    &--hasTable {
      flex-wrap: nowrap;
      overflow: auto;
      padding: 0;
      max-height: 400px;
      white-space: nowrap;
    }
  }

  &__no {
    display: inline-block;
    width: 24px;
    font-size: 11px;
    opacity: 0.6;
  }

  &__date {
    display: inline-block;
    width: 130px;
    font-weight: 600;
    font-size: 11px;
    opacity: 0.6;
  }

  &__text {
    padding-left: 10px;
    font-weight: 400;
    font-size: 13px;
  }

  &__content {
    width: calc(100% - 220px);
  }

  &__info {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 200px;
    gap: 12px;
  }

  &__toggle {
    margin: 0;
    padding: 0;
    outline: transparent;
    border: none;
    background: none;
    color: $lightblue;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    line-height: 1.5;
    opacity: 0.2;
    transition: opacity 0.3s, color 0.3s;

    &.is-active,
    &:hover {
      color: $blue;
      opacity: 0.6;
    }
  }

  &__details {
    display: none;
    width: 100%;

    & > td {
      padding: 0 !important;
    }

    .idf-detail-table-wrapper {
      display: none;
    }

    .idf-answer-list__item {
      border: none;
      background-color: #fff;

      &:last-child {
        border-bottom: 1px solid $bluegray;
      }

      &:nth-of-type(odd) {
        background-color: #f2f5f7;
      }

      &--failed {
        background: rgba($orange, .1);
        color: $orange;
      }
    }
  }

  &__badge {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: $lightblue;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 11px;
    line-height: 22px;

    &--success {
      background: rgba($green, .1);
      color: $green;
    }

    &--warning {
      background: rgba(#d4a300, .1);
      color: #d4a300;
    }

    &--danger {
      background: rgba($red, .1);
      color: $red;
    }
  }

  &--bySession {
    .idf-answer-list__item {
      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        &:first-child {
          margin-bottom: 12px;
        }
      }

      .idf-answer-list__no {
        width: 60px;
        color: $flatlightblue;
        font-size: 0.8rem;
      }

      .idf-answer-list__text {
        padding-left: 20px;
        width: calc(100% - 60px);
        font-size: 13px;
      }

      strong.idf-answer-list__text {
        font-weight: 600;
      }
    }
  }
}

.idf-answer-table {
  max-width: inherit;
  width: 100%;
  font-size: 13px;

  thead {
    th {
      padding: 12px;
      min-width: 220px;
      // width: 220px;
      border-bottom: 1px solid $bluegray;
      background-color: #e1eaf0;
      color: #425A70;
      vertical-align: top;
      text-align: left;
      white-space: normal;
      font-weight: 600;

      p,
      span {
        white-space: normal;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  td {
    padding: 12px;
    min-width: 220px;
    width: 220px;
    border-bottom: 1px solid $bluegray;
    vertical-align: middle;
  }

  &__details {
    display: none;

    & > td {
      padding: 0 !important;
    }
  }

  &__icon {
    position: absolute;
    top: -7px;
    left: 14px;
    z-index: 4;
    width: 14px;
    height: 14px;
    color: $orange;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    width: 100%;
    height: calc(100% - 1px);
    outline: transparent;
    border: none;
    background: rgba(#ffffff, 0.1);
    color: $blue;
    text-align: left;
    font-weight: bold;
    font-size: 11px;
    opacity: 0;
    transition: opacity 0.3s;
    backdrop-filter: blur(6px);
  }

  &__row {
    position: relative;

    &:Hover {
      .idf-answer-table__toggle {
        opacity: 1;
      }
    }

    &.is-active {
      & > td {
        border-color: $blue;
      }
    }

    &--failed {
      & > td {
        background-color: rgba($orange, .1) !important;
      }

      .idf-answer-table__icon {
        opacity: 1;
      }

      &.is-active {
        & > td {
          border-color: $orange;
        }
      }
    }

    &:nth-of-type(even) {
      & > td {
        background-color: #fafafa;
      }
    }

    &:last-child,
    &:nth-last-child(2) {
      td {
        border: none;
      }
    }
  }
}

.idf-detail-table-wrapper {
  border: 1px solid rgba($blue, .5);

  &--hasFailed {
    border-color: rgba($orange, .5);
  }
}

.idf-detail-table {
  width: 100%;

  tr {
    td {
      padding: 14px 12px;
      border-bottom-color: transparent;
      background-color: #fafafa;
      font-size: 12px;
    }

    &:nth-of-type(even) {
      td {
        background-color: #f4f4f4;
      }
    }
  }

  &__failed-cell {
    background-color: rgba($orange, .2) !important;
    color: $orange;
  }
}

.idf-small-link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  outline: transparent;
  border: none;
  background: none;
  color: $blue;
  font-weight: 600;
  font-size: 12px;
  transition: color 0.3s;
  gap: 6px;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: $orange;
  }
}

.idf-policy-details-modal {
  &__table {
    max-width: inherit;
    width: 100%;

    th {
      padding: 12px;
      border-bottom: 1px solid $bluegray;
      color: #425A70;
      font-weight: 600;
    }

    td,
    th {
      padding: 16px 12px;
      min-width: 220px;
      width: 220px;
      text-align: left;
      font-size: 13px;
    }

    tr {
      .idf-policy-details-modal__fixed-column {
        background-color: #f0f3f5;
      }

      th.idf-policy-details-modal__fixed-column {
        background-color: #fff !important;
      }

      &:nth-of-type(odd) {
        td {
          background-color: #f9fbfc;
        }

        .idf-policy-details-modal__fixed-column {
          background-color: #f6f7f8;
        }
      }
    }
  }

  &__icon {
    position: relative;
    top: -2px;
    margin-right: 6px;
    width: 14px;
    height: 14px;
    color: $orange;
    vertical-align: middle;
  }

  &__fixed-column {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 40;
    min-width: 320px;
    width: 320px;
    border-bottom: 1px solid $bluegray;
  }

  .idf-idresolve-answers__question {
    display: block;
    padding: 0;
    border: none;
    background: none;
  }

  .idf-steps {
    position: relative;
    bottom: -1px;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $bluegray;
  }

  .idf-modal__body {
    overflow-x: auto;
    margin: 0;
    padding: 0 0 15px;
  }

  .idf-tittle_repeating {
    width: 100% !important;
    text-align: left !important;
  }
}

.idf-unresolved-sessions {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    overflow: hidden;
    margin: 10px 0 0;
    max-height: 130px;
    border: 1px solid $bluegray;
    border-radius: 6px;
    list-style-type: none;
    transition: all 0.3s;

    &.is-active {
      overflow-y: auto;
      max-height: 432px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 12px;
    border-bottom: 1px solid $bluegray;
    font-size: 12px;
    line-height: 1.5;
    gap: 12px;

    &:last-child {
      border: none;
    }
  }

  &__no {
    display: inline-block;
    width: 18px;
    color: $flatlightblue;
    font-size: 12px;
  }

  &__date {
    display: inline-block;
    margin: 0;
    width: 200px;
    font-size: 12px;
    line-height: 1.5;
  }

  &__token {
    display: inline-block;
    padding: 6px 12px;
    border: 1px solid #FAA300;
    border-radius: 32px;
    background: rgba(#faa300, 0.10);
    color: #A66C00;
    font-weight: 400;
    line-height: 1;
  }

  &__alert {
    display: flex;
    padding: 6px 12px;
    border-color: #b8daff;
    border-radius: 6px;
    background-color: #cce5ff;
    color: #004085;
    font-weight: 600;
    font-size: 0.9rem;
    gap: 6px;
  }
}

@use "../../utilities/index" as *;

/* SEARCH */

input[type="search"].idf-graph-search {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 149px;
  padding: 8px 8px 8px 36px;
  border-radius: 0;
  background-image: url("../img/graph/search-background.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 16px;
  outline: none;
  -webkit-appearance: searchfield;
  font-size: 13px;
}
input[type="search"].idf-graph-search:focus {
  outline: none;
  box-shadow: none;
  border-color: $darkblue;
}

input[type="search"].idf-graph-search::-webkit-search-cancel-button {
  display: block;
  -webkit-appearance: none;
  opacity: 1;
  width: 10px;
  background: url("../img/graph/clear.svg");
  height: 10px;
  background-size: 10px;
  transition: all 0.1s ease;
}

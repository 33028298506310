@use "../../utilities/index" as *;

.innerDiv {
  height: 100%;
}

.video-button {
  position: absolute;
  width: 32px;
  height: 95px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px 32px;
  background-color: transparent;
  display: inline-block;
  border: none;
  opacity: 1;
}

.video-button:disabled {
  opacity: 0.5;
}

.video-button:hover {
  cursor: pointer;
  border: none;
}

.video-button:focus {
  outline: none;
}

.video-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.idf-graph-timeline {
  background: $gray;
  border-top: 1px solid $darkgray;
  padding: 15px 20px;
  display: block;
  height: auto;
}

.idf-graph-timeline summary {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}

.graphComponent--extended #timelineComponent {
  height: 0;
}

#timelineComponent text {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
}

.video-button {
  position: absolute;
  width: 32px;
  height: 82px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px 32px;
  background-color: transparent;
  display: inline-block;
  border: none;
  opacity: 1;
}

.stop {
  background-image: url("../img/graph/pause.svg");
}

.stop:hover {
  background-image: url("../img/graph/pause-hover.svg");
}

.play {
  background-image: url("../img/graph/play.svg");
}

.play:hover {
  background-image: url("../img/graph/play-hover.svg");
}

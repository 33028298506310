@use "../utilities/index" as *;

.idf-scheduler {
  .idf-card {
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0,0,0, .06);

    .idf-row {
      gap: 15px;

      paper-input.idf-input {
        --paper-font-caption_-_line-height: 0;
      }
    }

    .idf-row > * {
      width: 23%;
    }

    .idf-email,
    .idf-name {
      width: 66%;
    }

    multiselect-combo-box {
      width: auto!important;
    }

    paper-button {
      margin: 30px 0 0;
    }

    paper-checkbox {
      display: block;
      margin: 9px 0;
    }
  }

  section {
    margin-top: 30px;
  }

  .idf-recent-reports {
    $columns: 1fr 15% 30% 17% 45px;
    @include fixTable($columns);

    .idf-row-details {
      position: relative;
      overflow: hidden;
      padding: 0 1.4em;
      max-height: 0;
      opacity: 0;
      transition: all 0.2s ease;

      .idf-row-details-content {
        margin: 0;

        ul {
          margin: 0;

          li {
            display: flex;
            align-items: center;
            margin-bottom: 7px;

            div {
              margin-right: 12px;
            }

            a {
              @include allcapslabel();
              opacity: 0;
              transition: all 0.3s ease;
            }

            span {
              display: inline-block;
              margin: 2px 8px;
              width: 1px;
              height: 8px;
              background-color: $flatlightblue;
              opacity: 0;
              transition: all 0.3s ease;
            }

            &:hover {
              a,
              span {
                opacity: 1;
              }
            }
          }
        }
      }

      footer {
        @include allcapslabel();
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
        padding: 0.4em 1em;
        background: $blue;
        color: white;

        span {
          cursor: pointer;
        }
      }
    }

    .idf-flex-row.idf-action {
      margin-right: 0!important;
    }

    .idf-tag {
      background: $bluegray;
    }

    .idf-exteded {
      .idf-flex-row {
        border-bottom: solid 1px $blue;
        background: $blue;
        color: white;
      }

      .idf-row-details {
        display: block;
        padding: 1.3em 1.4em 2em;
        max-height: 100%;
        border: 2px solid $blue;
        border-top: none;
        border-bottom: none;
        background: white;
        opacity: 1;
        transition: all 0.2s ease;
        grid-column-start: 1;
        grid-column-end: span 13;
      }

      .idf-flex-table {
        grid-template-rows: auto auto;
      }

      i.idf-contextual-menu-icon {
        background-image: url("../img/contextual-menu-w.svg");
      }
    }
  }
}

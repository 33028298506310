@use "sass:color";
@use "../../utilities/index" as *;

.idf-agent-view.idf-details-page {
  overflow-y: auto;
  margin-top: 54px;
  padding-right: 15px;
  height: calc(100vh - 184px);
  scroll-behavior: smooth;
  @media only screen {
    #idf-print {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -9999999;
      visibility: hidden;
      opacity: 0;
    }
  }

  .container-fluid {
    overflow: hidden;
    padding-right: 0;
    padding-left: 0;
  }

  .idf-details-left {
    width: 100%;
    border-right: none;

    .idf-title {
      margin-left: 0!important;
    }
  }

  .idf-policies {
    $columns: 9% 9% 14% 34% 8% 8% 10% 14% 37px;
    @include mediumTable($columns);
  }

  .idf-rules {
    $columns: 40% 20% 20% 20% 37px;
    @include mediumTable($columns);
  }

  .idf-sort-title {
    position: relative;
    padding-left: 18px !important;
    cursor: pointer;

    &:Hover {
      opacity: 0.8;
    }

    &:before {
      position: absolute;
      top: calc(50% - 4px);
      left: 6px;
      width: 0;
      height: 0;
      border-width: 0 3px 5px 3px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      content: '';
      opacity: 0.2;
      transform: translateY(-50%);
    }

    &:after {
      position: absolute;
      top: calc(50% + 4px);
      left: 6px;
      width: 0;
      height: 0;
      border-width: 5px 3px 0 3px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
      content: '';
      opacity: 0.2;
      transform: translateY(-50%);
    }
  }

  .sort-asc {
    &:before {
      border-color: transparent transparent #fff transparent;
      opacity: 1;
    }
  }

  .sort-desc {
    &:after {
      border-color: #fff transparent transparent transparent;
      opacity: 1;
    }
  }

  .idf-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
    }

    &__title {
      padding: 0;
      font-size: 0.8rem;
      line-height: 1.4;
    }

    &__content {
      position: relative;
      flex: 1;
      padding: 12px;
      font-size: 0.9rem;
    }

    &__filters {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      border-bottom: 1px solid $bluegray;
    }

    &__filterItem {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 48%;
      margin-bottom: 12px;

      label {
        font-size: 0.7rem;
      }

      vaadin-combo-box {
        flex: 1;
      }
    }

    &__tooltip {
      color: $bluegray;
    }

    &__settings {
      outline: transparent;
      border: none;
      background: none;
      color: $flatlightblue;
      font-size: 1rem;
      transition: color 0.3s, opacity 0.3s;

      &.is-active,
      &:hover {
        color: $blue;
      }
    }

    &--export {
      .idf-card__settings {
        opacity: 0;
      }
    }
  }

  [data-tooltip = ""] {
    &:after,
    &:before {
      display: none;
    }
  }

  .highcharts-container {
    height: 100% !important;

    svg {
      height: 100%;
    }
  }

  .idf-agent-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background-color: color.adjust($blue, $lightness: -15%);
    background-position: right bottom;
    background-size: cover;
    color: #fff;
    gap: 15px;
    @include md {
      gap: 10px;
    }

    &__section {
      padding: 20px;
      @include md {
        padding: 15px 10px;
      }

      .idf-list-details {
        display: flex;
        flex-wrap: wrap;
        line-height: 1.5;
        gap: 8px;

        label {
          margin-bottom: 4px;
          color: rgba(#ffffff, 0.75);
          font-weight: 400;
          font-size: 0.9rem;
        }

        span {
          display: block;
          color: #fff;
          word-break: break-word;
          letter-spacing: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.4;
        }
      }

      .idf-list-detail {
        display: block;
        width: calc(50% - 8px);
      }
    }

    &__divider {
      display: inline-block;
      width: 1px;
      background: $white-1;
    }

    &__rank {
      strong {
        display: block;
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 1.8rem;
        opacity: 1;

        small {
          font-weight: 300;
          font-style: italic;
          font-size: 75%;
        }
      }
    }
  }

  .idf-agent-card-warning {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: $gray;
    color: #9fb4c6;
    text-align: center;

    svg {
      width: 64px;
      height: auto;
    }

    &__title {
      display: block;
      margin-top: 15px;
      text-align: center;
      font-weight: 600;
    }
  }

  .idf-agent-progress {
    --paper-progress-active-color: #CE4D16;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 4px;
    border-radius: 0;
  }

  .idf-agent-profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    &__img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__info {
      padding-left: 12px;
      width: calc(100% - 48px);
    }

    &__name {
      display: block;
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.4;
    }

    &__id {
      font-weight: 300;
      font-size: 0.9rem;
    }
  }

  .idf-number-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    gap: 30px;

    .idf-number-card {
      width: calc(50% - 15px);
      height: calc(50% - 15px);
    }
  }

  .idf-number-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 20px;
    border: 1px solid $bluegray;
    border-radius: 6px;
    background: #fff;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;
    }

    &__icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 12px 0 0;
      width: 28px;
      height: 28px;
      border-radius: 9px;
      background: $gray;
      color: $blue;
      font-size: 1rem;
      line-height: 1;
    }

    &__stat {
      color: $lightblue;
      font-weight: 600;
      font-size: 0.9rem;

      &--up {
        color: $green;
      }

      &--down {
        color: $red;
      }
    }

    &__info {
      color: color.adjust($lightblue, $lightness: 15%);
      font-weight: 600;
      font-size: 0.8rem;
    }

    &__big {
      display: block;
      margin-top: 25px;
      margin-bottom: 12px;
      color: $darkblue;
      font-weight: bolder;
      font-size: 2.2rem;
    }

    &__small {
      color: $lightblue;
      font-size: 0.9rem;
    }
  }

  .idf-section-title-wrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }
  }

  .idf-agent-widget-rule-table {
    $columns: 25% 25% 25% 25%;
    @include mediumTable($columns);
  }

  .idf-full-map {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .highcharts-contextmenu {
    display: block !important;
  }

  .highcharts-menu {
    padding: 6px !important;
    border-color: $bluegray !important;
    border-radius: 6px;
    box-shadow: 0 6px 16px -2px rgba(0,0,0,0.1) !important;
  }

  .highcharts-menu hr {
    margin: 6px auto;
    max-width: calc(100% - 20px);
  }

  .highcharts-menu-item {
    border-radius: 6px;
  }

  .highcharts-menu-item:hover {
    background-color: rgba(8, 120, 207, 0.1) !important;
    color: $blue !important;
  }

  .idf-table {
    .idf-table-content {
      .idf-flex-row {
        margin-top: 4px;
        border-radius: 4px;
        background-color: #fafafa;
        font-size: 12px;
      }

      .idf-c-1 {
        width: calc(100% - 210px);
      }

      .idf-c-2,
      .idf-c-3 {
        width: 80px;
      }

      .idf-c-4 {
        width: 130px;
      }
    }
  }

  .idf-agent-card {
    padding: 20px;
    height: 100%;
    border: 1px solid $bluegray;
    border-radius: 6px;
    background-color: #fff;

    &__icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: 32px;
      height: 32px;
      border-radius: 12px;
      background-color: $gray;
      box-shadow: 0 8px 16px rgba(0,0,0,.05);
      color: $blue;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__status {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
    }

    &__indicator {
      display: inline-flex;
      align-items: center;
      color: $lightblue;
      color: $legacy;
      gap: 6px;

      &--up {
        color: $green;
      }

      &--down {
        color: $red;
      }
    }

    &__label {
      color: $flatlightblue;
      font-weight: 600;
      font-size: 0.8rem;
      cursor: default;
    }

    &__numbers {
      display: flex;
      justify-content: flex-start;
      margin-top: 32px;
      color: $lightblue;
      gap: 15px;
      @media(max-width: 1600px) {
        gap: 10px;
      }

      &-label {
        margin: 0 0 8px;
        color: $flatlightblue;
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 1.5;
      }

      &-divider {
        width: 1px;
        background-color: $bluegray;
      }
    }

    &__big {
      color: $lightblue;
      font-size: 2rem;
      @media(max-width: 1600px) {
        font-size: 1.3rem;
      }
    }

    &__small {
      color: $lightblue;
      font-size: 2rem;
      @media(max-width: 1600px) {
        font-size: 1.3rem;
      }

      strong {
        font-weight: 400;
      }
    }

    &__name {
      display: block;
      margin-top: 24px;
      color: $lightblue;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  .idf-agent-card-wrapper {
    margin-bottom: 30px;

    .idf-card__content {
      overflow: hidden;
      padding: 0;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;

      & > div {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .idf-agent-item {
      flex: 1;
      margin: 0 !important;
      padding: 0;
      min-width: 33.33333333%;
      max-width: 100%;

      .idf-agent-card {
        position: relative;
        top: -1px;
        left: -1px;
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        border: none;
        border-top: 1px solid $bluegray;
        border-left: 1px solid $bluegray;
        border-radius: 0;
      }
    }
  }
}

.idf-agent-view-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    gap: 12px;

    label {
      font-size: 13px;
    }

    paper-dropdown-menu {
      --paper-input-container-input_-_line-height: 26px;
    }

    paper-listbox {
      --paper-font-subhead_-_font-size: 12px;
    }
  }

  &__divider {
    display: inline-block;
    width: 1px;
    height: 32px;
    background: $bluegray;
  }

  &__exportButtons {
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.3s;

    &.is-active {
      padding-right: 192px;
    }

    .idf-btn {
      padding-right: 14px !important;
      padding-left: 14px !important;
      text-align: center;
    }

    .idf-secondary {
      position: relative;
      z-index: 6;
      background-color: rgba($blue, .1);

      &[disabled] {
        background-color: rgba($blue, .3) !important;
      }

      &.is-active {
        background-color: $blue;
        color: #fff;

        &:Hover {
          background-color: color.adjust($blue, $lightness: -15%);
        }
      }
    }
  }

  &__hiddenButtons {
    position: relative;
    position: absolute;
    top: 0;
    right: -60px;
    display: flex;
    align-items: center;
    width: 180px;
    opacity: 0;
    transition: all 0.3s;
    gap: 12px;

    &.is-active {
      right: 0;
      opacity: 1;
    }

    .idf-blue {
      width: 94px !important;
    }

    .idf-red {
      padding-right: 6px;
      padding-left: 6px;
      width: 70px !important;
      text-align: center;
    }
  }
}

.dashboard-section {
  position: relative;
  z-index: 4;
  overflow-x: hidden;

  &__title {
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $bluegray;
    color: $darkblue;
    font-weight: 600;
    font-size: 1.1rem;
  }
}

.idf-agent-nav {
  position: fixed;
  top: 104px;
  left: 220px;
  z-index: 990;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: calc(100% - 220px);
  background-color: $gray;
  box-shadow: rgba(0, 0, 0, .1) 0 10px 15px -3px;
  transition: width 0.3s, left 0.3s;

  &__link {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 14px 10px;
    outline: transparent;
    border: none;
    background: none;
    color: $lightblue;
    font-weight: 600;
    font-size: 0.8rem;
    transition: all 0.3s;
    gap: 8px;
    @media(max-width: 1500px) {
      padding: 12px 4px;
      font-size: 0.8rem;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      width: 0;
      height: 2px;
      background: $orange;
      content: '';
      transition: width 0.6s;
    }

    &.is-active,
    &:hover {
      color: $orange;

      &:after {
        width: 100%;
      }
    }
  }

  &--wide {
    left: 60px;
    width: calc(100% - 60px);
  }
}

.idf-agent-view {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: $white-5;
    opacity: 0;
    transition: opacity 0.3s, backdrop-filter 0.3s;
    backdrop-filter: blur(2px);
    z-index: -1;

    &.is-visible {
      opacity: 1;
      z-index: 4;
    }
  }
}

.idf-agent-context-menu {
  position: absolute;
  top: 6px;
  right: -366px;
  z-index: 16;
  display: none;
  width: 360px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 6px 16px rgba(0,0,0,.15);

  &--small {
    right: -296px;
    width: 290px;
  }

  &.is-active {
    display: block;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid $bluegray;
    color: $lightblue;
    font-weight: 600;
    font-size: 0.8rem;
  }

  &__close {
    padding: 0;
    outline: transparent;
    border: none;
    background: none;
    color: color.adjust($bluegray, $lightness: -20%);
    font-size: 1.2rem;
    transition: color 0.3s;

    &:hover {
      color: $orange;
    }
  }

  &__body {
    position: relative;
    padding: 12px;
  }

  &__filters {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $bluegray;
    gap: 12px;

    label {
      width: 100%;
      font-size: 0.8rem;
    }

    paper-input,
    vaadin-combo-box {
      width: 100%;
    }
  }

  &__item {
    width: calc(50% - 6px);
  }

  &__buttons {
    width: 100%;

    .idf-btn {
      width: 100%;
    }
  }

  &__export {
    width: 100%;
  }

  &__compare {
    margin-top: 6px !important;
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
  }

  &__reset {
    margin-top: 6px;
  }

  .idf-btn {
    margin: 0;
    text-align: center;
  }
}

.idf-agent-export-overlay {
  position: absolute;
  z-index: 6;
  display: none;
  padding: 12px;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: border-color 0.3s;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover {
    border-color: rgba($accent, .4);
  }

  &.is-active {
    border-color: $accent;
  }

  paper-checkbox {
    --paper-checkbox-unchecked-color: #9fb4c6;
    --paper-checkbox-border-radius: 6px;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    width: 18px;
  }
}

.idf-export-progress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(#ffffff, 0.8);
  color: $lightblue;
  font-size: 2rem;
  transition: opacity 1s;
  animation: fadeIn 0.3s;
  backdrop-filter:blur(12px);

  &__center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__body {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;

    svg {
      width: 64px;
      height: 64px;
    }
  }

  &__icon {
    margin: 0;
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    color: $lightblue;
    font-size: 0.9rem;
  }

  &__label {
    display: block;
    margin-top: 8px;
    color: $flatlightblue;
    font-size: 0.8rem;
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &.is-active {
    display: block;
  }
}

.idf-progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 320px;
  gap: 16px;

  &__fill {
    position: relative;
    overflow: hidden;
    width: calc(100% - 46px);
    height: 24px;
    border-radius: 4px;
    background-color: $bluegray;

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $blue;
      transition: width 0.3s;
    }

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 15;
      width: 200%;
      background-image: linear-gradient(140deg, transparent, color.adjust($bluegray, $lightness: 20%), transparent);
      content: '';
      animation: shine 2500ms linear infinite;
    }
  }

  &__percentage {
    display: inline-block;
    width: 46px;
    color: $lightblue;
    font-weight: 600;
    font-size: 0.9rem;
  }
}

.idf-agent-view--export {
  overflow: visible;
  width: 594px !important;
  height: auto !important;

  .idf-agent-export-overlay {
    display: none !important;
  }

  .col-xl-3 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .col-xl-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .col-xl-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .idf-card {
    height: auto !important;
    box-shadow: none !important;
    transition: none !important;
    // overwrite default height, fill container
    &__content {
      height: 100% !important;

      & > div {
        width: 100% !important;
        height: 100% !important;
      }

      .idf-table-content {
        height: 100% !important;
      }
    }
  }

  .idf-agent-card {
    padding: 15px !important;

    &__label {
      font-size: 11px !important;
    }

    &__numbers {
      flex-wrap: wrap;
      margin-top: 16px !important;

      &-divider {
        display: none;
      }
    }

    &__big {
      display: block;
      width: 100%;
      text-align: left;
      font-size: 20px !important;
    }

    &__small {
      width: 100%;
      font-size: 18px !important;
    }
  }

  .highcharts-button,
  .highcharts-exporting-group {
    display: none !important;
  }
}

.highcharts-container,
.highcharts-container svg {
  width: 100% !important;
}

.idf-agent-policies-aside-wrapper {
  padding: 15px 0;

  .idf-table-content {
    height: calc(100vh - 180px);
  }
}

.idf-compare-widget-container {
  &__title {
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    margin-bottom: 12px;
  }

  &__chart {
    padding: 12px;
    border: 1px solid $bluegray;
    background-color: #fff;
    border-radius: 6px;
  }
}

.idf-modal {
  &--compare-chart {
    top: 50% !important;
    transform: translateY(-50%);
    max-height: inherit !important;

    .idf-modal__body {
      background-color: $gray;
      margin: 0;
      padding: 30px;
    }
  }

  &__filters {
    padding: 12px;
    background-color: #fff;
    border-bottom: 1px solid $bluegray;
    margin: 0;
  }
}

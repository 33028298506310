@use "utilities/index" as *;

header.idf-webservice-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid #BFCDD9;
  border-radius: 4px;
  background: white;

  .idf-wss-input {
    width: 100%;
  }

  .idf-wss-filters {
    display: flex;
    align-items: center;

    select {
      margin: 0 10px 0 0 !important;
    }
  }
}

.idf-webservice-search-list > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.2em;
  @media only screen and (max-width: 1900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }

  .idf-card {
    p {
      margin: 0 0 3px;
    }

    header {
      display: flex;
      justify-content: space-between;

      .idf-tag {
        align-self: flex-start;
      }
    }

    footer {
      display: flex;
      justify-content: space-between;
      padding: 1.8em 0 0;
      text-align: left;

      span:nth-child(2) {
        position: relative;
        align-self: flex-end;
        margin-bottom: 3px;
        width: 87px;
        height: 1em;

        a {
          position: absolute;
          width: 100%;
          text-align: right;
          opacity: 0;
          cursor: pointer;
          transition: all 0.35s cubic-bezier(0.45, 0.2, 0, 1.6);
          transform: translateY(1em);
        }
      }
    }

    &:hover footer span a {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

aside.idf-webservice {
  display: flex;
  flex-direction: column;
  background: white;

  button {
    align-self: flex-end;
    margin: 0.8rem 2rem -0.4rem 2rem!important !important;
  }

  .idf-aside-content > div {
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    margin-top: 20px;
    height: calc(100% - 40px);

    .idf-code {
      width: 49%;

      .idf-bold-text {
        color: $darkblue;
      }

      textarea {
        width: 100%;
        height: calc(100% - 30px);
        border-radius: 0;
        background-color: white;
        box-shadow: none;
        resize: none;
        cursor: unset;

        &:focus {
          border: 1px solid $lightblue;
          box-shadow: none;
        }
      }
    }
  }

  &.idf-active {
    width: calc(100% - 191px);
  }
}

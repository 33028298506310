@use "../utilities/index" as *;

.idf-avatar {
  position: relative;
  overflow: hidden;
  margin-right: 3px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: #f1f1f1;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $blue;
    font-weight: 600;
    transform: translate(-50%, -50%);
  }
}

.idf-avatar-wrapper {
  position: relative;
  margin: 0 0 0 8px;

  &:before {
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border: 1px solid $orange;
    border-radius: 50%;
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .idf-avatar {
    margin: 0 !important;
  }
}

.idf-profile-widget-content {
  &:hover {
    .idf-avatar-wrapper {
      &:before {
        opacity: 1;
      }
    }
  }
}

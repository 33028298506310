@use "../utilities/index" as *;

paper-tabs {
  --iron-icon-fill-color: #DFE6EC;
}

.idf-steps {
  &--orange {
    --paper-tabs-selection-bar-color: #CE4D16 !important;
    --paper-tab-content_-_color: #425a70 !important;

    paper-tab {
      opacity: 0.6;
      transition: opacity 0.3s;

      &.idf-active,
      &:hover {
        opacity: 1;
      }
    }
  }
}

.idf-aside-steps {
  border-bottom: 1px solid $bluegray;

  .idf-steps {
    position: relative;
    bottom: -1px;
  }
}

@use "../utilities/index" as *;

.idf-list-details {
  .idf-list-detail {
    display: flex;
    margin-bottom: 6px;

    label {
      @include bodytext();
      display: inline-block;
      margin: 0 0.3rem 0 0;
      cursor: default;
    }

    span {
      @include boldtext();
      display: inline;
      color: $darkblue;
      vertical-align: middle;
    }
  }

  &.idf-vertical {
    .idf-list-detail {
      flex-direction: column;
      margin-bottom: 12px;

      & > * {
        margin-bottom: 7px;
      }
    }
  }

  &.idf-aside-list {
    display: flex;
    flex-wrap: wrap;

    .idf-list-detail {
      flex-direction: column;
      margin-bottom: 10px;

      & > * {
        margin-bottom: 7px;
      }
    }

    & > * {
      margin-right: 20px;
      width: calc(50% - 40px);
    }

    * {
      display: block!important;
    }
  }
}

@use "sass:color";
@use "../../utilities/index" as *;

.idf-advanced-analytics-timeline {
  .idf-stats-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px;
  }

  .idf-stats-card {
    padding: 8px 16px;
    border: 1px solid $bluegray;
    border-radius: 6px;
    background-color: #fafafa;
    text-align: center;

    &__big {
      display: block;
      margin-bottom: 6px;
      color: $lightblue;
      font-weight: 700;
      font-size: 1.3rem;
    }

    &__small {
      margin: 0;
      color: color.adjust($lightblue, $lightness: 15%);
      font-size: 0.8rem;
      line-height: 1.4;
    }
  }

  .idf-card {
    margin-bottom: 20px;
    padding: 0;

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      border-bottom: 1px solid $bluegray;
      transition: border 0.3s;

      &--noBorder {
        border-bottom: none;
      }
    }

    &__header-left {
      display: flex;
      justify-content: flex-start;
    }

    &__header-right {
      position: relative;
      display: flex;
      justify-content: flex-end;
    }

    &__header-extra {
      position: relative;
      border-left: 1px solid $bluegray;
    }

    &__title {
      display: inline-flex;
      align-items: center;
      padding: 15px;
      font-weight: 600;
      font-size: 0.9rem;
      gap: 4px;
    }

    &__toggle {
      display: inline-flex;
      align-items: center;
      padding: 0 15px;
      border: none;
      border-left: 1px solid $bluegray;
      border-top-right-radius: 3px;
      background-color: #fff;
      color: #94a6b5;
      font-weight: 600;
      font-size: 0.7rem;
      transition: background 0.3s;

      .bi {
        position: relative;
        top: -1px;
        margin-left: 8px;
        vertical-align: middle;
        font-size: 14px;
        transition: transform 0.3s;
      }

      &:hover {
        background-color: $bluegray;
      }

      &--collapsed {
        border-bottom-right-radius: 3px;

        .bi {
          transform: rotate(-180deg);
        }
      }
    }

    &__body {
      padding: 15px;
      min-height: 68px;

      .idf-warning {
        display: inline-block;
        padding: 12px 18px;
        border-color: #ffeeba;
        border-radius: 6px;
        background-color: #fff3cd;
        color: #856404;
        font-weight: bold;
        font-size: 0.9rem;
      }

      .idf-slot {
        & > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 15px;

          .cellListEvenItem,
          .cellListOddItem {
            width: calc(16.666667% - 15px);
            @media(max-width:1600px) {
              width: calc(20% - 15px);
            }
            @media(max-width:1400px) {
              width: calc(25% - 15px);
            }
            @media(max-width:1200px) {
              width: 240px;
            }
          }
        }
      }
    }

    .idf-search-toggle {
      position: relative;
      top: auto;
      left: auto;
      border: none;
    }

    .idf-search-tab {
      margin: 8px 0;
      padding-right: 12px;

      .idf-row {
        margin: 0;
        border: none;
        border-radius: 0;
        gap: 12px;
      }

      paper-button,
      paper-input,
      vaadin-combo-box {
        margin: 0;
      }

      paper-input {
        --paper-font-caption_-_line-height: 1px;
        --paper-input-error_-_bottom: -12px;
        --paper-input-error_-_padding: 8px 4px;
        --paper-input-error_-_margin-top: auto;
        --paper-input-error_-_border-radius: 32px;
        --paper-input-error_-_color: #fff !important;
        --paper-input-error_-_background: #aa2e2e;
      }
    }
  }

  .idf-aa-skeleton-card {
    margin: 12px 0;
    padding: 12px;
    border: 1px solid rgba($bluegray, 0.5);
    border-radius: 6px;

    &__item {
      @extend .idf-shine;
      margin-bottom: 12px;
      height: 10px;
      border-radius: 3px;
      background-color: #f3f5f7;

      &:last-child {
        margin-bottom: 0;
      }

      &--title {
        width: 70px;
        background-color: color.adjust(#f3f5f7, $lightness: -4%);
      }

      &--text {
        width: 160px;
      }

      &--button {
        width: 40px;
        background-color: rgba($blue, 0.1);
      }
    }
  }

  .mt-15 {
    margin-top: 15px;
  }

  .idf-info-card {
    position: relative;
    padding: 12px;
    width: 100%;
    height: 100%;
    border: 1px solid $bluegray;
    border-radius: 6px;
    color: #607487;

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 12px;
      gap: 4px;

      svg {
        width: 14px;
        height: 14px;
      }

      &:first-child {
        padding-right: 32px;
      }
    }

    .idf-state {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 3px 6px;
      border-radius: 4px;
      background: $lightblue;
      color: #fff;
      font-weight: 600;
    }

    .idf-link {
      display: inline-block;
      margin: 10px;
      font-weight: bold;
      transition: color 0.3s;
    }

    .idf-analytics-history {
      display: none;
    }

    .idf-name {
      text-transform: uppercase;
      font-weight: bold;
    }

    .idf-claim {
      .idf-name {
        width: calc(100% - 24px);

        i {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .idf-aa-details {
    position: absolute;
    top: 160px;
    right: 15px;
    z-index: 14;
    width: 360px;
    color: $lightblue;
    animation: fadeInRight 0.5s;

    &__accordion {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      width: 100%;
      border: none;
      border-top: 1px solid $bluegray;
      background-color: #fafafa;
      color: $lightblue;
      font-size: 13px;
      transition: background 0.3s;

      img {
        width: 14px;
        height: 14px;
      }

      &.is-active,
      &:hover {
        background-color: color.adjust(#fafafa, $lightness: -2%);
      }

      &.is-active {
        .bi-chevron-down {
          transform: rotate(-180deg);
        }
      }
    }

    &__title {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 600;
      gap: 8px;
    }

    &__badge {
      padding: 3px 6px;
      border-radius: 4px;
      background-color: $bluegray;
      color: $lightblue;
      font-size: 10px;
      line-height: 1;
    }

    &__body {
      .idf-aa-details__accordion {
        &:first-child {
          border-top: none;
        }

        &:last-child {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }

    &__content {
      position: relative;
      display: none;
      overflow-y: auto;
      padding: 0 12px;
      max-height: 164px;
      background-color: color.adjust(#fafafa, $lightness: 4%);

      .simplebar-content {
        position: relative;
      }
    }

    .bi-chevron-down {
      transition: transform 0.3s;
    }

    .idf-card__header {
      align-items: center;
    }

    .idf-card__title {
      padding: 12px;
    }

    paper-icon-button {
      width: 32px;
      height: 32px;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }

    &--dark {
      border-color: $darkblue;
      background: $lightblue;
      color: #d9dee2;

      .idf-card {
        &__header {
          border-color: $white-1;
        }

        &__title {
          color: #d9dee2;
        }
      }

      .idf-aa-details__accordion {
        border-color: $white-1;
        background-color: $lightblue;
        color: #d9dee2;

        &:Hover {
          background-color: $darkblue;
        }

        &.is-active {
          background-color: color.adjust($darkblue, $lightness: 4%);
        }
      }

      .idf-aa-details__badge {
        background: $black-3;
        color: #fff;
      }

      .idf-aa-details__content {
        background: $darkblue;
      }

      .idf-aa-list {
        &__button {
          border-color: $white-1;
          color: #d9dee2;
        }

        &__content {
          border-color: $darkblue;
          background: $lightblue;
          color: #d9dee2;
        }

        &__back {
          color: #fff;
        }
      }

      .idf-info-card {
        color: #d9dee2;

        .idf-state {
          background: $black-3;
        }

        .idf-link {
          color: #37a8ff;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  .idf-aa-list {
    margin: 0;
    padding: 0;
    color: #607487;
    list-style-type: none;
    font-size: 12px;

    &__item {
      &:last-child {
        .idf-aa-list__button {
          border: none;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      width: 100%;
      border: none;
      border-bottom: 1px dashed $bluegray;
      background: none;
      color: #607487;
      font-weight: 500;
      font-size: 12px;
      transition: color 0.3s;

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
      }

      .bi {
        font-size: 10px;
        opacity: 0.6;
      }

      &.is-active,
      &:hover {
        color: $orange;
      }
    }

    &__content {
      position: absolute;
      top: 80px;
      right: 200px;
      z-index: -1;
      visibility: hidden;
      width: 240px;
      height: auto;
      border: 1px solid $bluegray;
      border-radius: 4px;
      background: #fff;
      opacity: 0;
      transition: opacity 0.3s, right 0.3s, visibility 0.3s;

      & > div {
        overflow-y: auto;
        max-height: 400px;

        .idf-info-card {
          border-bottom: 1px solid $bluegray;
          border-radius: 0;

          &:last-child {
            border: none;
          }
        }
      }

      &.is-active {
        right: 366px;
        visibility: visible;
        opacity: 1;
      }

      .idf-aa-list__button {
        padding: 12px;

        &:hover {
          color: inherit;
        }
      }
    }

    &__back {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      color: #607487;
      font-weight: 600;
      font-size: 12px;
      transition: color 0.3s;
      gap: 4px;

      &:hover {
        color: $orange;
      }
    }

    .idf-info-card {
      margin-bottom: 10px;
      padding: 10px;
      height: auto;

      &__item {
        margin-bottom: 8px;
      }

      .idf-state {
        top: 10px;
        right: 10px;
        padding: 2px 4px;
        font-size: 11px;
      }
    }
  }

  .idf-node-popup-details {
    margin-top: -100px;
    margin-left: -180px;
  }
}

.idf-node-popup {
  position: absolute;
  display: none;
  overflow-y: auto;
  max-height: 486px;
  width: 360px;
  border-radius: 6px;
  background: transparent;
  background-color: $lightblue;
  color: #c2c8cd;
  font-size: 13px;
  transition: all 0.3s;
  animation: fadeInRight 0.5s;
  scrollbar-color: rgba(#000000, 0.4) $lightblue;
  scrollbar-width: thin;

  &.idf-visible {
    display: block;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 12px;
    background: rgba(#000000, 0.4);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #000000;
  }

  &::-webkit-scrollbar-thumb:active {
    background: #000000;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 12px;
    background: $lightblue;
  }

  &::-webkit-scrollbar-track:hover {
    background: $lightblue;
  }

  &::-webkit-scrollbar-track:active {
    background: $lightblue;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &__event {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 12px;
    border-bottom: 1px dashed $white-1;
    background-color: $lightblue;
    transition: all 0.6s ease;

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border: none;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .idf-cards-select {
      box-shadow: 0 0 20px 34px $lightblue;
      color: $blue;
      letter-spacing: 0.035rem !important;
      font-size: 0.7rem !important;
      line-height: 1 !important;
      transition: transform 0.2s, opacity 0.2s, color 0.2s, background 0.2s, width 0.2s, padding 0.2s, right 0.2s, box-shadow 0.6s !important;
      user-select: none;
    }

    &:hover {
      .idf-node-popup__toggle {
        opacity: 1;
      }

      .idf-cards-select {
        right: 15px !important;
        opacity: 1 !important;
      }
    }

    &--selected {
      z-index: 30;
      display: block !important;
      border: none;
      border-radius: 6px;
      background-color: $blue;

      .idf-cards-select {
        background: $orange !important;
        box-shadow: 0 0 20px 34px $blue;
        color: #fff;

        &:hover {
          background: color.adjust($orange, $lightness: 10%) !important;
          color: #fff;
        }
      }
    }

    &--hidden {
      visibility: hidden;
      padding: 0 12px;
      height: 0;
      border-width: 0;
      background-color: transparent;
      opacity: 0;
      transform: translateY(-40px);
    }

    .idf-list-details .idf-list-detail {
      label,
      span {
        color: #fff;
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__title {
    color: #fff;
    font-weight: 400;
    font-size: 13px;

    &:is(a) {
      color: #fff;
      font-weight: 400;
      transition: color 0.3s;

      &:hover {
        color: $orange;
      }
    }

    a {
      color: #fff;
      font-weight: 400;
      transition: color 0.3s;

      &:hover {
        color: $orange;
      }
    }
  }

  &__big {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0 15px;
    color: #fff;
    text-align: right;
    font-weight: bold;
    font-size: 14px;

    label {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      cursor: default;
    }

    p {
      margin: 0 0 0 6px;

      &:empty {
        margin-left: 0;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    gap: 8px;

    & > * {
      display: inline-block;
      min-width: calc(33% - 8px);
    }

    .idf-node-content-date {
      min-width: calc(50% - 16px);
    }

    label,
    p,
    span {
      margin: 0;
      font-size: 13px;
      line-height: 1.5;
    }

    .idf-list-detail {
      display: inline-flex;
      gap: 6px;
    }

    &--noMargin {
      margin: 0;
    }
  }

  &__footer {
    .idf-white {
      color: #37bcff;
      transition: color 0.3s;

      &:hover {
        color: color.adjust(#37bcff, $lightness: 15%);
      }
    }
  }

  &__date {}

  &__toggle {
    font-size: 12px;
    opacity: 0;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;

    a {
      transition: all 0.3s;
    }

    &.is-active,
    &:hover {
      color: $orange;
    }
  }

  &__details {
    display: none;
    margin: 12px -12px -12px;
    padding: 16px 12px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: $darkblue;
    line-height: 1.5;
  }

  &__details-content {
    position: relative;
    letter-spacing: normal;

    & > div {
      margin-bottom: 10px;
      letter-spacing: normal;
    }

    .idf-bold-text {
      display: block;
      margin-bottom: 5px;
      color: #fff;
      letter-spacing: normal;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  &__item {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    display: block;
    color: #f4f4f4;
    font-size: 13px;
  }

  &__values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    & > span {
      display: inline-block;
    }

    strong {
      color: #fff;
    }
  }

  .simplebar-content-wrapper {
    border-radius: 6px;
  }
}

.idf-node-popup-selected {
  position: fixed;
  top: 30px;
  right: 30px;
}

@use "../../utilities/index" as *;

.idf-clu-report {
  .idf-claim-payment {
    $columns: 1fr 16% 15%;
    @include fixTable($columns);
  }

  .idf-details-item {
    margin-bottom: 10px;
  }
}

@use "../../utilities/index" as *;

.idf-case-mass-close {
  paper-textarea {
    margin-bottom: 25px;
  }

  .idf-rule-item {
    margin-top: 17px;
  }

  paper-input {
    margin-top: -4px;
    margin-right: 20px;
  }

  vaadin-combo-box {
    margin-top: 8px;
  }

  paper-button {
    margin: 30px 0;
  }
}

@use "../../utilities/index" as *;

.idf-dashboard {
  &--view {
    main {
      gap: 0;
    }

    .col-lg-12,
    .col-lg-3,
    .col-lg-4,
    .col-lg-6,
    .col-lg-8,
    .col-lg-9 {
      margin-bottom: 30px;
    }

    .idf-simple {
      padding: 10px;

      .idf-btn.idf-small {
        top: 10px;
        right: 36px;
      }
    }

    .idf-widget-base__edit {
      display: none !important;
    }

    .idf-widget-base {
      position: relative;
      height: 100%;

      & > div {
        height: 100%;

        .idf-widget-card {
          height: 100%;

          &.idf-stats {
            min-height: 25vh;
          }
        }
      }

      &__settings {
        position: absolute;
        top: 12px;
        right: 6px;
        z-index: 4;
        padding: 2px 4px;
        border: none;
        border-radius: 4px;
        background: none;
        background-color: #fff;
        color: #a2b3c1;
        line-height: 1;
        transition: color 0.3s, background-color 0.3s;

        svg {
          vertical-align: middle;
        }

        &:Hover {
          background-color: $orange;
          color: #fff;
        }

        &.is-active {
          background-color: #faede7;
          color: $orange;
        }
      }

      .idf-widget-options {
        top: 15px;
        bottom: auto;
        z-index: 200;
        height: auto;

        &__button {
          strong {
            font-size: 0.8rem;
          }
        }

        &__dropdown {
          top: 15px;
        }
      }
    }

    .idf-dashboard-item {
      padding: 0;

      & > .idf-bs-row {
        margin: 0 !important;
        height: 100%;
      }
    }

    .idf-dashboard-col {
      &--border {
        border: none;
      }
    }

    .idf-dashboard-row__tool {
      display: none !important;
    }

    .idf-dashboard-row {
      margin: 0 !important;
      border: none;
      background: #f3f3f4;

      & > .idf-dashboard-col {
        margin-bottom: 0;
      }
    }

    .idf-widget-card {
      .idf-btn.idf-small {
        margin-right: 0;
      }
    }

    .idf-table {
      .idf-header {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      .cellListOddItem {
        .idf-row {
          background: rgba($bluegray, .5);
        }
      }

      .idf-row {
        border-radius: 0;

        .idf-c-4 {
          display: none;
        }
      }

      .idf-slot {
        & > div {
          & > div {
            &:last-child {
              .idf-row {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
              }
            }
          }
        }
      }
    }

    .idf-top-connected-summary {
      padding: 5px;
      height: 100%;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0 4px 25px rgba(0,0,0,.08);

      header {
        padding: 12px 5px 14px;
        color: #2f4050;

        label {
          margin: 0;
        }
      }

      .idf-table .idf-flex-table .idf-c-1 {
        width: 60px;
      }
    }

    .idf-last-triggered-rules header {
      margin: 0;
      padding: 12px 5px 14px;
    }

    .idf-impact-calculator-wrapper {
      .idf-impact-calculator {
        padding: 0;
      }
    }

    .idf-impact-calculator-wrapper {
      .idf-impact-calculator {
        padding: 12px;
        border-radius: 6px;
      }

      .idf-stat-box {
        padding: 12px;
        width: 100%;

        &__bg {
          padding: 18px 12px;
        }

        &__title {
          margin-bottom: 12px;
          font-size: 1rem;
        }

        &__number {
          font-size: 2rem;
          line-height: 30px;

          small {
            font-size: 50%;
          }
        }

        &__list {
          margin: 12px 0 0;

          li {
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &__item {
          width: calc(100% - 85px);
        }

        &__result {
          width: 70px;
          font-size: 0.9rem;
        }
      }
    }

    .idf-case-widget__top {
      .bi {
        display: none;
      }
    }

    [data-tooltip]:after {
      width: 110px;
      text-align: center;
      white-space: normal;
      line-height: 1.2;
    }
  }

  &--exportPDF {
    background: #fff;

    .idf-last-triggered-rules header {
      background: #fff !important;
    }

    .idf-table-widget,
    .idf-widget-card {
      box-shadow: none !important;
    }

    .idf-widget-base__settings,
    .idf-widget-options {
      opacity: 0 !important;
    }

    .idf-top-connected-summary {
      background: #fff;
      box-shadow: none;
    }

    .idf-stats__period {
      opacity: 0;
    }

    .idf-bubble-chart {
      svg {
        margin-left: -8%;
      }
    }
  }

  .idf-top-connected-summary .idf-table .idf-flex-table {
    .idf-c-3 {
      min-width: 60px;
      width: auto;
    }

    .idf-c-4 {
      display: none !important;
    }
  }

  .idf-last-triggered-rules {
    header {
      padding-top: 8px;
    }

    .idf-flex-table {
      .idf-c-7 {
        display: none !important;
      }
    }
  }

  .idf-simple {
    .idf-title {
      margin-bottom: 15px;
    }

    footer {
      justify-content: flex-start;

      .idf-stat-display {
        margin-right: 14px;
        margin-bottom: 14px;
      }
    }
  }
}

.idf-excel-table {
  overflow-x: auto;
  padding-bottom: 8px;

  &__table {
    min-width: 860px;
    max-width: 100%;
    width: 100%;
    border: 1px solid $bluegray;
  }

  td,
  th {
    padding: 0.75rem;
    border: 1px solid $bluegray;
    vertical-align: top;
  }

  thead {
    td,
    th {
      border-bottom: 2px solid $bluegray;
      background: rgba($bluegray, .3);
      font-weight: bold;
    }
  }
}

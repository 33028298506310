@use "../../utilities/index" as *;

.idf-doc-review-list {
  $columns: 1fr, 15%, 10%, 12%, 10%, 10%, 11%, 10%, 9%, 9%;
  @include bigTable($columns);

  .idf-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@use "../../utilities/index" as *;

.idf-rule-prev {
  .idf-actions {
    position: absolute;
    right: 15px;
    margin-top: -10px;
  }

  section {
    margin-bottom: 10px;
    .idf-bold-text.idf-darkblue {
      padding-top: 30px;
    }
  }

  .idf-run-preview-form {
    .idf-name {
      flex: 1;
      margin-right: 30px;
    }

    .idf-btn {
      align-self: flex-end;
      margin-bottom: 2px;
    }

    .idf-checkbox {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .idf-run-prev-history {
    $columns: 1fr 24% 13% 28% 45px;
    @include fixTable($columns);

    .idf-flex-table {
      align-items: center;
      -ms-flex-align: center;
    }

    .idf-row {
      margin-top: 5px;
      background: $gray;
    }

    .idf-flex-row {
      flex-direction: column;
      align-content: flex-start;
      justify-content: center;
      -ms-flex-align: flex-start;

      p {
        margin-bottom: 7px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .idf-SUBMITTED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $lightblue;
    }

    .idf-RUNNING i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $blue;

      &:before {
        position: absolute;
        display: table;
        border-radius: 50%;
        background-color: $blue;
        content: "";
        animation: pointPulse 900ms ease-in-out infinite;
      }
    }

    .idf-FINISHED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $green;
    }

    .idf-FAILED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $red;
    }

    .idf-CANCELED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $flatblue;
    }
  }
}

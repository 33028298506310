@use "../../utilities/index" as *;

.idf-vin-view {
  .idf-details-left {
    width: 100%!important;
    border-right: none!important;

    .idf-details-panel > div:last-of-type div {
      flex-direction: column;

      span {
        margin-top: 0.5rem;
      }
    }

    .idf-title {
      margin-bottom: 0;
    }
  }

  .idf-tables {
    padding-top: 160px;
  }

  .idf-vin-pictures {
    display: flex;
    margin-top: 30px;

    .idf-photo-data {
      flex: 1;
      margin-left: 20px;
      padding: 1em;
      max-width: 300px;
      border: 1px solid $darkgray;
      border-radius: 4px;
      background: white;
    }
  }

  .idf-submission-history {
    $columns: 15% 1fr 30% 20%;
    @include mediumTable($columns);
  }

  .idf-galleria {
    margin-bottom: 10px;
    width: 100%;
    height: 350px;
  }
}

@use "utilities/index" as *;

.idf-mvr-data {
  p.idf-bold-text.idf-darkblue {
    margin-top: 1.5rem;
    padding-bottom: 5px;
    border-bottom: 1px solid $bluegray;
  }

  .idf-identification {
    display: flex;

    .idf-list-details {
      flex: 1;
    }

    .idf-name-and-address .idf-list-detail {
      flex-direction: column;

      label,
      span {
        line-height: 1.3;
      }
    }
  }

  .idf-licenses-list {
    & > div {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .idf-dl-card {
      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 9px;
      }

      .idf-dl-icon {
        display: inline-block;
        margin-top: -2px;
        margin-right: 0.1rem;
        width: 1em;
        height: 1em;
        background: no-repeat center;
        background-image: url("../img/green-dl.svg");
        background-size: contain;
        vertical-align: middle;

        &.idf-invalid {
          background-image: url("../img/gray-dl.svg");
        }
      }
    }
  }

  .idf-violations-list {
    .cellListEvenItem,
    .cellListOddItem {
      margin-bottom: 1.2rem;

      .idf-viol-description {
        margin-bottom: 0.8rem;
        line-height: 1.3;
      }

      ul {
        @include reset-list(block);

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .idf-violation-title {
          margin-bottom: 2px;
        }
      }
    }
  }

  .idf-mesages-list {
    .cellListEvenItem,
    .cellListOddItem {
      margin-bottom: 10px;

      .idf-mvr-descriptions {
        line-height: 1.35;
      }
    }
  }
}

@use "../utilities/index" as *;

.idf-filter-input {
  position: relative;
  z-index: 4;
  color: $lightblue;

  &:hover{
    z-index: 40;
  }

  &__form {
    position: relative;
  }

  &__icon {
    position: absolute;
    right: 4px;
    bottom: 6px;
    z-index: 10;

    &:Hover {
      .idf-filter-input__dropdown {
        display: block;
      }

      .idf-filter-input__button {
        background-color: rgba($lightblue, .1);

        &.is-active {
          background-color: rgba($blue, .1);
          color: $blue;
        }
      }
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    outline: transparent;
    border: none;
    border-radius: 4px;
    background: none;
    color: $lightblue;
    vertical-align: middle;
    line-height: 1;
    transition: background-color 0.3s;

    svg {
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }

    div {
      font-weight: bold;
      font-size: 14px;
    }

    &:hover {
      background-color: rgba($lightblue, .1);
    }

    &.is-active {
      background-color: rgba($blue, .1);
      color: $blue;

      svg {
        display: none;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 32px;
    right: -4px;
    display: none;
    margin: 0;
    width: 80px;
    border: 1px solid $bluegray;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);
    list-style-type: none;
    animation: fadeIn 0.5s;
    z-index: 9999;

    &:before {
      position: absolute;
      top: -26px;
      right: -12px;
      width: 100%;
      height: 52px;
      background: transparent;
      content: '';
    }
  }

  &__item {
    position: relative;

    input {
      position: absolute;
      z-index: -4;
      opacity: 0;
    }

    label {
      display: block;
      margin: 0;
      padding: 6px;
      text-align: center;
      font-weight: 700;
      cursor: pointer;
      transition: background-color 0.3s;

      &.is-active,
      &:hover {
        background-color: rgba($lightblue, .1);
      }
    }

    &--small {
      label {
        font-size: 0.7rem;
      }
    }
  }

  &__extra {
    position: relative;
    display: none;
    padding-top: 20px;

    &:before {
      position: absolute;
      top: -2px;
      left: 12px;
      width: 20px;
      height: 50px;
      border: 1px solid $bluegray;
      border-top: none;
      border-right: none;
      border-bottom-left-radius: 12px;
      content: '';
    }
  }

  &__badge {
    position: absolute;
    top: 8px;
    left: 0;
    padding: 4px 8px;
    border-radius: 32px;
    background: $lightblue;
    color: #fff;
    font-size: 0.6rem;
  }

  &__close {
    position: absolute;
    top: 6px;
    left: 60px;
    padding: 2px;
    outline: transparent;
    border: 0;
    background: none;
    color: $lightblue;
    transition: color 0.3s;

    &:hover {
      color: $orange;
    }
  }

  paper-input.idf-input {
    --paper-input-container-input_-_padding: 9px 30px 9px 9px !important;
  }
}

.idf-filter-input {
  .idf-filter-input__extra {
    paper-input.idf-input {
      --paper-input-container-input_-_padding: 6px 30px 6px 7px !important;
      margin-left: auto;
      width: calc(100% - 26px) !important;
    }
  }
}

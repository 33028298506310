@use "../../utilities/index" as *;

.idf-flex-row {
  .idf-icon-excluded {
    display: none;
  }
}

.idf-advanced-analytics,
.idf-advanced-analytics-summary,
.idf-advanced-analytics-timeline {
  .idf-action i,
  .idf-flags i,
  .idf-name i,
  .idf-rank i {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    transition: all 0.5s;
  }

  .idf-advanced-analytics-timeline-scrollable {
    height: calc(100vh - 122px);
  }

  .idf-top-down {
    transform: rotate(180deg);
  }

  .idf-plus {
    background-image: url("../img/add.svg");
  }

  .idf-top-down,
  .idf-top-up {
    background-image: url("../img/arrow-up.svg");
  }

  i.idf-contextual-menu-icon {
    display: block;
    width: 2em;
    height: 2rem;
    background-image: url("../img/contextual-menu.svg");
    background-size: 90%;
    cursor: pointer;
    transition: all 0.3s;

    &:before {
      z-index: 1;
    }

    &:hover {
      cursor: pointer;

      &:before {
        opacity: 0.4;
      }
    }
  }

  .idf-action {
    text-align: right;
  }

  i.idf-red-flag {
    margin-left: 0.5rem;
    background-image: url("../img/red-flag.svg");
    background-position-y: 1px;
    background-size: 90%;
  }

  .idf-rank {
    font-weight: $bold;

    i {
      margin: 0 0.3em -2px;
      opacity: 0.35;
    }

    span {
      opacity: 0;
      transition: all 0.5s;
    }
  }

  .idf-row {
    position: relative;

    &:hover {
      .idf-rank {
        i {
          opacity: 1;
        }

        span {
          opacity: 0.4;
        }
      }
    }

    .idf-icon-excluded {
      display: none;
    }

    &--excluded {
      .idf-c-2,
      .idf-c-3 {
        opacity: 0.6;
      }

      .idf-icon-excluded {
        display: inline-block;
        width: 14px;
        height: 14px;
        color: #ad6141;
      }
    }
  }

  .idf-contextual-menu {
    top: 0 !important;
    bottom: auto !important;
    animation: fadeIn 0.5s;

    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 12px;
      padding-left: 12px;
      gap: 8px;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 6;
        width: 100%;
        height: 100%;
        background: transparent;
        content: "";
      }

      span,
      svg {
        z-index: 0;
        pointer-events: none;
        user-select: none;
      }

      svg {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }

    &__action {
      &--include {
        display: none !important;
      }

      &--exclude {}
    }

    &--hasExclude {
      .idf-contextual-menu__action {
        &--include {
          display: flex !important;
        }

        &--exclude {
          display: none !important;
        }
      }
    }
  }

  .idf-tab-content {
    display: none;

    &.idf-active {
      display: block;
      animation: fadeIn 0.5s;
    }
  }

  .idf-table {
    .idf-flex-table {
      @for $i from 1 through 13 {
        .idf-c-#{$i} {
          overflow: visible;
        }
      }
    }

    .idf-action {
      justify-content: flex-end;
    }
  }

  .idf-tabs-menu {
    --paper-tabs-selection-bar-color: #ce4d16;
    box-shadow: inset 0 -1px 0 0 #dfe6ec;

    .idf-active {
      --paper-tab-content_-_color: #ce4d16;
      color: $orange;
    }
  }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
}

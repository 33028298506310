.idf-consent {
  paper-dialog {
    z-index: 1000;
    padding: 15px 0;
    border-radius: 4px;
  }

  .idf-actions {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 5px;
  }

  h3.idf-important {
    font-size: 1.1rem;
  }
}

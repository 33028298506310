@use "sass:color";
@use "../../utilities/index" as *;

.idf-ai-page {
  display: flex;
  justify-content: flex-start;
  padding: 30px;
  width: calc(100% - 220px);
  height: calc(100% - 51px);
  background-image: url("../img/ai-bg.jpg");
  gap: 120px;

  &__main {
    width: 800px;
  }
}

.idf-ai-aside {
  width: 320px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 12px;
    background-color: #fff;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(to right, #CE4D16, #FFA800);
    background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size: 1rem;
    gap: 6px;
  }

  &__toggle {
    border: none;
    background: none;
    color: $flatlightblue;
    transition: all 0.3s;

    &:hover {
      color: $darkblue;
    }
  }

  &__search-bar {
    position: relative;
    margin-bottom: 15px;

    paper-icon-button {
      position: absolute;
      top: 4px;
      right: 4px;
      padding: 4px;
      width: 28px;
      height: 28px;
      line-height: 1;
      opacity: 0.4;
      transition: opacity 0.3s;

      &:Hover {
        opacity: 1;
      }
    }
  }

  &__body {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 12px;
    background-color: #fff;
  }

  &__new-chat {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 100%;
    height: auto;
    border-radius: 12px;
    background: $blue !important;
    font-size: 0.9rem !important;
    line-height: 1;
    gap: 8px;

    svg {
      position: relative;
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: color.adjust($blue, $lightness: -10%) !important;
    }
  }
}

.idf-ai-entry {
  margin: 0 auto;
  width: 600px;
  color: $lightblue;
  text-align: center;

  &__title {
    display: block;
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 2rem;
  }

  &__text {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 300px;
    color: $lightblue;
    font-size: 0.9rem;
  }
}

.idf-ai-folders {
  margin-bottom: 30px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    &-title {
      color: $lightblue;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  &__nav {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  &__title {
    color: $lightblue;
    font-weight: 600;
  }

  &__list {
    @extend .scrollbar-slim;
    overflow-y: auto;
    margin: 0;
    padding-right: 6px;
    max-height: calc(100vh - 700px);
    list-style-type: none;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 12px;
    border-left: 4px solid #f7f8f9;
    border-radius: 12px;
    background-color: #f7f8f9;
    cursor: pointer;
    transition: all 0.3s;
    gap: 12px;

    &-left {
      display: inline-flex;
      align-items: center;
      gap: 8px;

      .bi {
        font-size: 1rem;
      }
    }

    &.active,
    &:hover {
      border-color: #ebf0f5;
      background-color: #ebf0f5;
    }
  }

  &__open-settings {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 6px;
    background: none;
    color: $flatlightblue;
    font-size: 1rem;
    line-height: 1;
    transition: all 0.3s;

    &.is-active,
    &:hover {
      background-color: $bluegray;
      color: $darkblue;
    }
  }

  &__button {
    padding: 0;
    width: 24px;
    height: 24px;
    outline: transparent;
    border: none;
    border-radius: 6px;
    background: none;
    background-color: $gray;
    color: $lightblue;
    transition: all 0.3s;

    &:hover {
      background-color: $bluegray;
      color: $darkblue;
    }

    &--toggle {
      .bi {
        transition: all 0.3s;
      }

      &.is-active {
        .bi {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.idf-ai-chatbox {
  margin-top: 60px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 6px;
    border-radius: 12px;
    border: 1px solid $bluegray;

    &-left {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
    }

    .idf-ai-aside__toggle {
      width: 32px;
      height: 32px;
      background-color: rgba($bluegray, .5);
      border-radius: 8px;

      &:hover {
        background-color: $bluegray;
      }
    }
  }

  &__actions {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    .idf-ai-aside__new-chat {
      padding: 10px;
      font-size: 0.7rem !important;
      background-color: rgba($blue, .1) !important;
      color: $blue !important;
      gap: 4px;

      &:Hover {
        background-color: $blue !important;
        color: #fff !important;
      }
    }
  }

  &__save {
    background: none;
    border: none;
    outline: transparent;
    color: $flatlightblue;
    transition: color 0.3s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    &.is-active,
    &:hover {
      color: $darkblue;
    }
  }

  &__bottom {
    border: 1px solid $bluegray;
    border-radius: 16px;
    background-color: #fff;
  }

  &__textarea {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;

    .bi {
      position: relative;
      top: 2px;
      width: 24px;
      background: linear-gradient(to right, #CE4D16, #FFA800);
      background-clip: text;
      color: transparent;
      font-size: 1rem;
    }

    paper-textarea {
      --paper-input-container-underline_-_display: none;
      --paper-input-container-underline-focus_-_display: none;
      --iron-autogrow-textarea_-_color: #425a70;
      --paper-input-container_-_padding: 0;
      --paper-font-caption_-_line-height: 0 !important;
      width: calc(100% - 24px);
    }
  }

  &__input {
    --paper-input-container_-_padding: 16px;
    --paper-font-caption_-_line-height: 0;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px;

    &-left {}

    &-right {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }
  }

  &__upload {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    outline: transparent;
    border: none;
    border-radius: 6px;
    background: none;
    color: $flatlightblue;
    font-size: 1.1rem;
    transition: all 0.3s;

    &:Hover {
      color: $darkblue;
    }
  }

  &__send {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255,168,0,1) 0%, rgba(206,77,22,1) 100%);
    background-size: 100%;
    transition: all 0.3s;

    &:Hover {
      background-position: 50%;
      background-size: 450%;
    }
  }

  .idf-chatbox__history {
    margin-bottom: 30px;
    height: 50vh;
    border: 1px solid $bluegray;
    border-radius: 16px;
  }
}

.idf-ai-recent-chats {
  &__list {
    @extend .scrollbar-slim;
    overflow-y: auto;
    margin: 0;
    padding-right: 6px;
    max-height: 224px;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 12px;
    background-color: #F7F8F9;
    cursor: pointer;
    transition: all 0.3s;

    .bi {
      color: $lightblue;
      font-size: 0.9rem;
    }

    &:hover {
      background-color: #ebf0f5;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 22px);
    color: $lightblue;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  &__date {
    width: 70px;
    color: $flatlightblue;
    text-align: right;
    font-size: 0.7rem;
  }

  &__title {
    display: block;
    padding-right: 10px;
    width: calc(100% - 70px);
    font-weight: 600;
    font-size: 0.8rem;
  }

  &__text {
    display: block;
    font-size: 0.8rem;
    line-height: 1.3;
    opacity: 0.7;
  }
}

.idf-ai-context-menu {
  position: fixed;
  top: 30%;
  left: 30%;
  z-index: 999;
  display: none;
  width: 220px;
  border: 1px solid $bluegray;
  border-radius: 8px;
  background-color: #fff;

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 10px;
    width: 100%;
    outline: transparent;
    border: none;
    background: none;
    color: $lightblue;
    font-weight: 600;
    font-size: 0.8rem;
    transition: all 0.3s;
    gap: 8px;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:hover {
      background-color: #ebf0f5;
    }

    &--remove {
      color: $red;

      &:hover {
        background-color: rgba($red, .1);
      }
    }
  }

  &__label {
    margin: 0;
    padding: 12px 8px;
    border-top: 1px solid $bluegray;
    color: $flatlightblue;
    font-size: 0.8rem;
    cursor: default;
    user-select: none;
    gap: 8px;
  }

  &__colors {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding-right: 8px;
    padding-bottom: 12px;
    padding-left: 8px;
    list-style: none;
    gap: 10px;
  }

  &__color {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 18px;
    height: 18px;
    outline: transparent;
    border: none;
    border-radius: 50%;
    color: inherit;
    transition: all 0.3s;

    &:before {
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: -1;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border-radius: 50%;
      background-color: currentColor;
      content: '';
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      &:before {
        opacity: 0.4;
      }
    }
  }
}
// force paper-input padding

.idf-ai-page {
  .idf-ai-aside {
    .idf-ai-aside__search-bar {
      paper-input {
        --paper-input-container-input_-_border: 1px solid #DFE6EC !important;
        --paper-input-container-input_-_border-radius: 6px !important;
        --paper-input-container-input_-_background: #fff !important;
        --paper-input-container-input_-_padding: 9px 32px 9px 9px !important;
      }
    }
  }
}

@use "../utilities/index" as *;

// excess height to improve interactive area / accessibility
$height: 1.5rem;
$thumb-height: 0.9rem;
$track-height: 0.3rem; // colours
$upper-color: $darkgray;
$lower-color: $darkgray;
$thumb-color: $lightblue;
$thumb-hover-color: $darkblue;
$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

[type='range'] {
  display: block;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: $height;
  cursor: pointer;
  appearance: none;

  &:focus {
    outline: none;
  }
}
// Webkit

[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background;
}

[type='range']::-webkit-slider-thumb {
  position: relative;
  top: 50%;
  margin-top: calc(#{-$thumb-height}/2);
  width: $thumb-height;
  height: $thumb-height;
  border-radius: 100%;
  background: $thumb-color;
  transition: background-color 150ms;
  appearance: none;
}
gulp// Firefox

[type='range']::-moz-range-track,
[type='range']::-moz-range-progress {
  width: 100%;
  height: $height;
  background: $upper-background;
}

[type='range']::-moz-range-progress {
  background: $lower-background;
}

[type='range']::-moz-range-thumb {
  margin: 0;
  width: $thumb-height;
  height: $thumb-height;
  border-radius: 100%;
  background: $thumb-color;
  transition: background-color 150ms;
  appearance: none;
}
// Internet Exploder

[type='range']::-ms-track {
  width: 100%;
  height: $height;
  border: 1px solid;
  background: transparent;
  // color needed to hide track marks
  color: transparent;
}

[type='range']::-ms-fill-lower {
  background: $lower-background;
}

[type='range']::-ms-fill-upper {
  background: $upper-background;
}

[type='range']::-ms-thumb {
  // IE Edge thinks it can support -webkit prefixes
  top: 0;
  margin: 0;
  width: $thumb-height;
  height: $thumb-height;
  border-radius: 100%;
  background: $thumb-color;
  box-shadow: none;
  transition: background-color 150ms;
  appearance: none;
}

[type='range']:hover,
[type='range']:focus {
  &::-webkit-slider-thumb {
    background-color: $thumb-hover-color;
  }

  &::-moz-range-thumb {
    background-color: $thumb-hover-color;
  }

  &::-ms-thumb {
    background-color: $thumb-hover-color;
  }
}

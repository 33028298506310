@use "sass:color";
@use "../utilities/index" as *;

.idf-chatbox-btn {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 999;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,168,0,1) 0%, rgba(206,77,22,1) 100%);
  background-size: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s;
  user-select: none;
  outline: transparent;

  &.is-active,
  &:hover {
    box-shadow: 0 0 0 4px rgba($orange, .4);
    background-size: 450%;
    background-position: 50%;
  }
}

.idf-chatbox {
  position: fixed;
  right: -600px;
  bottom: 70px;
  z-index: 999;
  width: 400px;
  border: 1px solid $bluegray;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 6px 16px -6px $black-1;
  font-size: 0.9rem;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.is-active {
    right: 15px;
    opacity: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid $bluegray;

    &-right {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(to right, #CE4D16, #FFA800);
    background-clip: text;
    color: transparent;
    font-weight: bold;
    gap: 6px;
  }

  &__toggle {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    border: none;
    background: none;
    color: $flatlightblue;
    font-size: 0.8rem;
    transition: color 0.3s;
    gap: 6px;

    .bi {
      font-size: 0.7rem;
    }

    &:hover {
      color: $darkblue;
    }
  }

  &__status {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12px;
    color: $lightblue;
    transition: all 0.3s;
    user-select: none;
    gap: 6px;

    &.is-hidden {
      height: 0;
      opacity: 0;
    }
  }

  &__loader {
    display: inline-block;
    width: 36px;
    user-select: none;
  }

  &__close {
    padding: 0;
    border: none;
    background: none;
    font-size: 1.1rem;
    transition: color 0.3s;

    &:hover {
      color: $red;
    }
  }

  &__history {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 12px;
    max-height: 500px;
    background-color: #eaeef2;
    gap: 12px;
  }

  &__answer {
    padding-right: 90px;
    text-align: left;
  }

  &__links {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 6px;
  }

  &__link {
    font-size: 0.7rem;
    font-weight: 600;
    padding: 4px 14px;
    border-radius: 32px;
    border: 1px solid $blue;
    color: $blue;
    transition: all 0.3s;
    background-color: #fff;

    &:hover {
      text-decoration: none;
      background-color: $blue;
      color: #fff;
    }
  }

  &__message {
    padding-left: 90px;
    text-align: right;

    .idf-chatbox__text {
      background-color: $blue;
      color: #fff;
    }
  }

  &__text {
    display: inline-block;
    padding: 12px;
    border-radius: 16px;
    background-color: #fff;
    color: $lightblue;
    font-size: 0.85rem;
  }

  &__message-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    border-top: 1px solid $bluegray;
    transition: all 0.3s;
  }

  &__input {
    --paper-input-container_-_padding: 12px 0;
    --paper-input-container-underline_-_display: none;
    --paper-input-container-underline-focus_-_display: none;
    --paper-input-container-shared-input-style_-_font-size: 0.9rem;
    width: calc(100% - 46px);
  }

  &__send {
    padding: 0;
    border: none;
    background: none;

    svg {
      path {
        transition: fill 0.3s;
        fill: $flatblue;
      }
    }

    &:hover {
      svg {
        path {
          fill: $darkblue;
        }
      }
    }
  }
}

@use "../../utilities/index" as *;

.idf-custom-report-edit {
  height: inherit;

  section {
    display: flex;
    height: inherit;
  }

  aside {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    min-width: 260px;
    max-width: 350px;
    width: 20vw;
    border: 1px solid $bluegray;
    border-radius: 4px;
    background: white;

    header {
      @include boldtext();
      padding: 13px 20px;
      border-bottom: 1px solid $bluegray;
      color: $darkblue;
    }

    .idf-report-settings {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0 20px;
      gap: 15px;

      .simplebar-content {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        gap: 15px;
      }
    }

    .param-editor {
      min-height: 150px;
    }

    .ace_editor {
      border: 1px solid $bluegray;
      border-radius: 4px;
    }
    //schedule
    .idf-card .idf-separator:first-of-type {
      margin-top: 0;
    }

    .idf-scheduler .idf-card .idf-row {
      flex-direction: column;

      & > * {
        width: 100%;
      }
    }
  }

  main {
    flex: 1;
    border: 1px solid $bluegray;
    border-radius: 4px;
    background: white;

    .ace_editor {
      width: 100%;
      height: 100%;
    }

    &.idf-test-wrapp {
      overflow: auto;
    }

    .idf-classic-table {
      max-width: 100%;
      width: 100%;
      border: none;
      border-collapse: collapse;
      border-radius: 5px;
      background-color: white;
      white-space: nowrap;
      font-weight: normal;
      font-size: 12px;
    }

    .idf-classic-table td,
    .idf-classic-table th {
      padding: 8px;
      text-align: center;
    }

    .idf-classic-table td {
      border-right: 1px solid #f8f8f8;
      font-size: 12px;
    }

    .idf-classic-table thead {
      border-radius: 4px;
      background: $lightblue;
      color: #ffffff;
    }

    .idf-classic-table tr:nth-child(even) {
      background: #F8F8F8;
    }
  }

  .ace_editor {
    background-color: #fcfcfd;
  }
}

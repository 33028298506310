/**
 * @author eg on 6/10/2023
*/
@use "../utilities/index" as *;

.mobile-vehicle {
  .carousel-control {
    background-position: center;
    background-size: contain;
  }

  .carousel-control.right {
    background-image: url("../../img/carousel-arrow-right.svg");
  }

  .carousel-control.left {
    background-image: url("../../img/carousel-arrow-left.svg");
  }
}
/* Styles for new markup */

.idf-policy-card-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .idf-policy-card {
    margin: 10px;
    width: calc(25% - 20px);
  }
}

.idf-policy-card {
  position: relative;
  padding: 15px 15px 20px;
  height: 100%;
  border: 1px solid #DFE6EC;
  border-radius: 4px;
  background: #fff;
  color: #425a70;

  &__badge {
    position: absolute;
    top: 22px;
    left: 22px;
    z-index: 5;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    letter-spacing: 1.5px;
    font-weight: bold;
    font-size: 0.7rem;

    &--yellow {
      background: #f8961e;
    }

    &--blue {
      background: #0878CF;
    }
  }

  &__buttons {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 5;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    padding: 4px 8px;
    height: 28px;
    border: none;
    border-radius: 4px;
    background: rgba(#ffffff, 0.5);
    color: #0878CF;
    font-size: 0.7rem;
    transition: color 0.3s, background 0.3s;
    backdrop-filter: blur(8px);

    .bi {
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background: #0878CF;
      color: #fff;
    }
  }

  &__img {
    position: relative;
    margin: 0 0 25px;

    img {
      width: 100%;
      height: 250px;
      border-radius: 4px;
      object-fit: cover;
    }

    .thumbnail {
      padding: 0;
      border: none;
      box-shadow: none;
    }
  }

  &__view {
    position: absolute;
    right: 0;
    bottom: 48px;
    left: 0;
    z-index: 4;
    width: 100%;
    color: #fff;
    text-align: center;

    strong {
      display: inline-block;
      padding: 6px 10px;
      border-radius: 32px;
      background: rgba(#000000, 0.3);
      backdrop-filter: blur(8px);
    }
  }

  &__date {
    position: absolute;
    right: 4px;
    bottom: -27px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #425a70;
    color: #fff;
    text-align: center;
    line-height: 1.2;

    strong {
      display: block;
      font-weight: bold;
      font-size: 1rem;
    }

    small {
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  &__content {
    position: relative;
  }

  &__title {
    display: block;
    margin-bottom: 6px;
    padding-right: 70px;
    color: #607487;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__model {
    color: #607487;
    font-weight: 400;
    font-size: 0.9rem;
  }

  &__info {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    margin-left: -6px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    width: 100%;
    color: #607487;
    font-weight: 500;
    font-size: 0.9rem;

    strong {
      font-weight: 600;
    }

    .bi {
      margin-right: 6px;
    }
  }

  &__type {
    position: absolute;
    top: 15px;
    right: 0;
    color: #CE4D16;
    font-size: 1.2rem;
  }

  &__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    color: #51a351;

    svg {
      width: 34px;
      height: 34px;
    }
  }

  .carousel {
    margin-bottom: 0;
  }

  .carousel-control {
    top: 50%;
    margin-top: 0;
    width: 24px;
    height: 24px;
    border: none;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }

  .carousel-indicators {
    top: auto;
    right: 0;
    bottom: -14px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    li {
      float: none;
      margin: 0 2px;
      background: #BFCDD9;

      &.active {
        background: #CE4D16;
      }
    }
  }

  .carousel-control.right {
    background-image: url("../img/carousel-arrow-right.svg");
  }

  .carousel-control.left {
    background-image: url("../img/carousel-arrow-left.svg");
  }

  .idf-view-more {
    position: absolute;
    bottom: 15px;
    left: 15px;
    line-height: 1.5;

    &:hover {
      color: #CE4D16;
    }
  }

  .carousel-caption {
    background: none;

    h4 {
      display: inline-block;
      margin: 0;
      padding: 6px 8px;
      border-radius: 32px;
      background: rgba(#000000, 0.4);
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 1.5;
      backdrop-filter: blur(8px);
    }
  }
}

@use "../../utilities/index" as *;

.idf-get-data-details {
  .idf-details-item {
    margin-top: 15px;

    &.idf-error header {
      background: $red;
    }
  }

  .idf-scroll-slot {
    margin-right: -15px;
    padding-right: 16px;
    height: calc(100% - 13px);
  }

  .idf-list-details:not(:nth-child(1)) {
    padding-top: 15px;
    border-top: 1px solid #dfe6ec;
  }
}

@use "utilities/index" as *;

body {
  @extend .scrollbar;
  padding: 0 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,.1);
}

::-webkit-scrollbar-thumb {
  border: none;
  border-color: transparent;
  border-radius: 6px;
  background: $black-4;

  &:hover {
    background: rgba(0,0,0,.6);
  }
}
@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: rgba(0,0,0,.6) rgba(0,0,0,0.1);
  }
}
@media only screen and (max-width: 850px) {
  //mobile container width
  .container > table {
    width: calc(100% - 20px);
  }

  .mainContent {
    max-width: calc(100vw - 20px);
    width: 100%;
  }

  .nav.pull-left {
    width: 100%;

    .dropDownPosLeft150 {
      float: right;
    }
  }

  .navbar-fixed-top {
    width: 100vw;
  }
}
//temp fix for notification badge

.notifNo {
  position: relative;

  .btn-size {
    width: 30px;
  }

  .NotificationWidget_indicator {
    top: -4px;
    right: 0;
    left: auto;
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    line-height: 17px;
  }
}
//New logo

.idf-logo-wrapp a {
  display: flex!important;
  align-items: center;

  img {
    width: 100%;
    height: 18px;
  }
}

.idf-main-layout {
  position: relative;
  @media (max-width: 1000px) {
    .idf-global-search-icon {
      display: inline-block;
    }

    .idf-top-bar .idf-menu-button {
      position: absolute;
      right: 0;
    }

    .idf-main-menu {
      width: 0;

      .idf-menu-list {
        overflow: hidden;
      }

      &:hover {
        width: 200px;
      }

      &:before {
        position: absolute;
        top: 15vh;
        right: -13px;
        z-index: 2;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: white;
        background-image: url("../img/down-w.svg");
        background-position: center;
        background-size: 40%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 9px -3px #0000007d;
        content: '';
        transform: rotate(-90deg);
      }
    }

    .idf-main-content .idf-content-panel,
    .idf-main-content .idf-content-panel-legacy {
      max-width: calc(100vw - 5px);
    }

    .idf-top-right {
      padding-right: 20px;
    }

    .notifNo .btn-size {
      width: 24px;
    }

    .idf-main-menu.idf-closed {
      width: 220px;
    }

    .idf-main-menu:before {
      display: none !important;
    }

    .idf-profile-widget .idf-role {
      display: none;
    }

    .idf-global-search-component {
      position: fixed;
      top: 80px;
      right: 0;
      left: 0;
      z-index: 990;
      display: none;
      margin: 0 auto;

      .idf-global-search-top {
        margin: 0 auto;
        width: calc(100% - 30px);

        .idf-search-wrapper {
          width: 100%;
        }

        .idf-results-wrapper {
          width: 100% !important;
        }

        &.idf-active paper-input {
          width: 100% !important;
        }
      }
    }

    .idf-notification-widget {
      width: 44px;
    }
  }

  //responsive menu
  .idf-layout {}

  .idf-main-content {}

  .idf-logo {
    width: auto;
    height: 20px;
    cursor: pointer;
    -webkit-mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
    mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
    -webkit-mask-size: 800%;
    mask-size: 800%;
    -webkit-mask-position: 0;
    mask-position: 0;

    &:hover {
      opacity: 1;
      transition: mask-position 2s ease,-webkit-mask-position 2s ease;
      -webkit-mask-position: 120%;
      mask-position: 120%;
    }
  }

  .idf-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 4px 18px;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);

    &__button {
      overflow: hidden;
      width: 40px;
      height: 40px;
      white-space: nowrap;

      .idf-menu-button {
        margin: 0;
        opacity: 1;
        transition: margin-left 0.3s ease, color 0.3s ease, opacity 0.3s ease;

        &:hover {
          color: $orange;
        }

        &--expand {
          opacity: 0;
        }
      }

      &.is-active {
        .idf-menu-button {
          &--shrink {
            margin-left: -40px;
            opacity: 0;
          }

          &--expand {
            margin-left: -3px;
            opacity: 1;
          }
        }
      }
    }

    .idf-top-right {
      display: flex;
      margin-left: auto;
    }

    .idf-profile-widget-content {
      display: flex;
      align-items: center;
    }
  }

  .idf-logo-wrapper {
    display: flex;
    align-items: center;
    width: 161px;
    height: 100%;
  }

  .idf-global-search-component {
    margin-left: 5px;

    .idf-input {
      transition: all 0.3s;
    }
  }

  .idf-profile-widget {
    position: relative;
    display: flex;
    margin-left: 20px;
    cursor: pointer;

    .idf-role {
      color: $darkblue;
      font-size: 14px;
      user-select: none;
    }

    img {
      margin-left: 6px;
      border-radius: 50%;
    }

    .idf-contextual-menu {
      top: 100%;
      right: 0;
      min-width: 175px;
      transform: none;

      .idf-separator {
        display: block;
        margin-top: 3px;
        margin-bottom: 3px;
        padding-bottom: 0;
      }
    }
  }

  .idf-main-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    padding-top: 50px;
    width: 220px;
    height: 100%;
    background: $darkblue;
    transition: width 0.1s ease;

    &.idf-closed {
      width: 60px;
      @media(min-width:1000px) {
        .idf-menu-category__title {
          span {
            width: 0;
          }
        }

        .idf-menu-item {
          span {
            opacity: 0;
          }

          &:after {
            right: 145px;
            width: 18px;
          }
        }
      }

      .idf-menu-list {
        overflow: hidden;
      }

      &:hover {
        width: 220px;
        @media(min-width:1000px) {
          .idf-menu-category__title {
            span {
              width: 140px;
            }
          }

          .idf-menu-item {
            span {
              opacity: 1;
            }

            &:after {
              right: -320px;
            }
          }
        }
      }
    }

    .idf-submenu {
      margin-bottom: 16px;
      padding-left: 5px;
    }
    //sc
    & > div {
      padding: 8px;
      width: 100%;
      height: 100%;
    }

    .idf-menu-list {
      padding: 4px;
    }

    .idf-menu-category {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      opacity: 0.8;
      cursor: pointer;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;

        &:after {
          opacity: 1;
        }
      }

      &::after {
        width: 8px;
        height: 6px;
        background-image: url("../img/down-w.svg");
        background-repeat: no-repeat;
        content: '';
        opacity: 0.5;
        transition: transform 0.3s, opacity 0.3s;
        transform: rotate(-90deg);
        transform-origin: center;
      }

      &__title {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        span {
          display: inline-block;
          overflow: hidden;
          width: 140px;
          transition: all 0.1s ease;
        }

        img {
          margin-right: 8px;
          width: 18px;
        }
      }

      &.idf-closed {
        &:after {
          transform: rotate(0deg);
        }
      }
    }

    .idf-menu-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      min-width: 180px;
      border-radius: 4px;
      color: rgba($bluegray, .8);
      text-decoration: none;
      letter-spacing: 0.01em;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      span {
        transition: opacity 0.3s;
      }

      &.idf-no-icon::before {
        margin-right: 6px;
        width: 4px;
        height: 4px;
        border: 2px solid currentColor;
        border-radius: 50%;
        content: '';
        opacity: 0.3;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.2);
        color: white;
      }

      &.idf-active {
        background: $bluegray;
        color: #ce4800;
        font-weight: 600;
      }

      &:after {
        position: absolute;
        right: -320px;
        width: 0;
        height: 100%;
        background: linear-gradient(to left, #2f4050, transparent);
        content: '';
        transition: all 0.1s ease;
        transform-origin: right;
      }
    }
  }

  .idf-global-search-icon {
    display: none;
    outline: transparent;
    border: none;
    background: none;
    color: $darkblue;
    transition: color 0.3s;

    &:Hover {
      color: #ce4800;
    }
  }

  .mobile-search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 980;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(#000, .2);
    backdrop-filter:blur(8px);
  }

  .idf-main {
    margin-left: auto;
    padding: 115px 15px 15px;
    width: calc(100% - 220px);
    transition: width 0.3s;

    &--wide {
      width: calc(100% - 60px);
    }

    &--noTitle {
      padding-top: 80px;

      .idf-top-bg {
        display: none;
      }

      .idf-content-title {
        display: none;
      }
    }
  }

  .idf-top-bg {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 700;
    width: 100%;
    height: 54px;
    border-bottom: 1px solid $bluegray;
    background: #f3f3f4;
  }

  .idf-content-panel {}

  .idf-content-panel-legacy {}

  .idf-content-title {
    position: fixed;
    position: fixed;
    top: 0;
    top: 53px;
    left: 0;
    left: 236px;
    z-index: 989;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 250px);
    transition: left 0.3s;
    gap: 20px;

    &--wide {
      left: 76px;
    }

    .buttonsAction {
      position: relative !important;
      right: auto !important;
      display: inline-flex;
      margin-top: 0 !important;
      padding-left: 12px;
      gap: 12px;

      img {
        margin-top: 0 !important;
      }
    }

    .panelIconsPermissions {
      display: flex;
      gap: 12px;
    }
  }

  .idf-main-title {
    margin: 15px 0;
    color: $darkblue;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
  }

  .comboBoxInput,
  .gwt-DateBox,
  .gwt-TextBox {
    min-height: 25px;
  }
}
//legacy components

.idf-main .panelContent {
  float: none !important;
  margin: 0 !important;
  width: 100% !important;

  table {
    .sectionPanelHeader {
      display: flex;
      align-items: center;
      padding: 12px;
      gap: 6px;

      .filterButtons {
        margin-left: 12px;
      }

      & > div {
        margin-left: auto !important;
      }

      .sectionPanelTitle {
        margin-top: 0 !important;
        margin-left: 0 !important;
      }
    }
  }
}

.notifNo {
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: $bluegray;
  }
}

vaadin-combo-box-overlay {
  z-index: 9999999;
}

.idf-help-icon {
  font-size: 70px;
  z-index: 98;
  right: 70px !important;
  width: 42px !important;
  height: 42px !important;
}

@use "sass:color";
@use "../../utilities/index" as *;

.idf-form-edit {
  //Form Generic settings
  .idf-form-general {
    display: flex;
    margin-bottom: 30px;
    padding: 20px;

    .idf-col {
      margin-right: 20px;
      max-width: 400px;
      width: 50%;

      & + .idf-col {
        padding-left: 20px;
        border-left: 1px solid $bluegray;
      }

      .idf-step-wizard-toggle {
        paper-toggle-button {
          display: inline-flex;
        }
      }
    }

    paper-textarea {
      margin-top: 15px;
      padding-top: 15px;
    }

    paper-input {
      padding-top: 4px;
    }

    .idf-form-rule {
      label {
        font-weight: 600;
        cursor: unset;
      }
    }

    .idf-empty {
      position: relative;
      justify-content: start;
      padding: 0;
    }

    .idf-assigned-list {
      ul {
        margin: 7px 0 0;
        max-height: 120px;
      }

      li {
        display: block;
        margin-bottom: 4px;

        &:before {
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 3px;
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background: $blue;
          content: '';
        }
      }
    }
  }

  .idf-form-builder {
    display: flex;
    padding: 0;

    .idf-builder-wrapp {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: flex-start;
      max-width: 100%;

      .idf-form-fields {
        padding: 0;
        background: transparent;
      }
    }

    .idf-side {
      // min-height: 810px;
      position: sticky;
      top: 106px;
      width: 488px;
    }

    .idf-side header {
      padding: 10px 20px;
      border-bottom: 1px solid $bluegray;
    }

    .idf-form-viewer {
      flex: 1;
      min-height: 810px;
      width: calc(100% - 488px);
      border-right: 1px solid $bluegray;
      // border-left: 1px solid $bluegray;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background: #ebebeb;
    }
  }

  .idf-tab-content {
    display: none;

    &.idf-active {
      display: block;
    }
  }

  .idf-error-message {
    display: none;
    margin-top: 7px;
    color: $red;
    font-size: 12px;
  }

  .idf-invalid {
    & > .idf-error-message {
      display: block;
    }
  }

  .idf-empty {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 20px;
    height: 100%;
    color: $lightblue;
    opacity: 0.7;

    h4 {
      @include bigboldtext();
      margin-bottom: 5px;
      margin-bottom: 16px;
      color: $darkblue;
      line-height: 1.4;
    }

    span {
      line-height: 1.4;
    }
  }

  .idf-row {
    align-items: center;
    margin-bottom: 5px;

    label {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .idf-phone-confirmation {
    .idf-form-item-wrapper {
      margin-bottom: 10px;
      padding: 23px 24px 30px;
    }
  }

  .idf-group {
    .idf-form-item-wrapper {
      margin-bottom: 10px;
      padding: 23px 24px 30px;
    }
  }

  .idf-form-fields {
    padding: 15px 0 15px 10px;
    background: white;
  }

  .idf-field-settings .idf-set-specific {
    .idf-input-row,
    .idf-row {
      margin-bottom: 10px;
    }

    vaadin-combo-box {
      width: 100%;
    }
  }

  .idf-set-specific {
    paper-dropdown-menu {
      img {
        margin-right: 6px;
      }
    }
  }

  .idf-set-tab {
    padding: 0;

    .idf-row {
      flex-wrap: wrap;

      label {
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }

  gold-phone-input,
  paper-input {
    &:hover {
      cursor: text;
    }
  }

  .idf-text {
    paper-input,
    paper-textarea {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_z-index: 1 !important;
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-phone {
    gold-phone-input {
      --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 23px !important;
      --paper-input-container-input-invalid_-_border: 1px solid #AA2E2E;
      --gold-phone-input-country-code_-_top: 0;
      --paper-input-error_-_letter-spacing: 0.9px;
      min-width: 360px;
      max-width: 360px;
      @media(max-width: 1500px) {
        --gold-phone-input-country-code_-_top: 1px;
      }
      @media(max-width: 576px) {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 23px !important;
      }
    }

    .idf-phone-input {
      &--digit-2 {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 30px !important;
        @media(max-width: 576px) {
          --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 30px !important;
        }
      }

      &--digit-3 {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 38px !important;
        @media(max-width: 576px) {
          --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 38px !important;
        }
      }
    }
  }

  .idf-number {
    paper-input {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_letter-spacing: 0.9px;
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-timed-input {
    paper-input {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_letter-spacing: 0.9px;
      margin-top: 15px;
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-dropdown {
    vaadin-combo-box {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-date {
    vaadin-date-picker {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-slider {
    paper-slider {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-condition-builder {
    .idf-rule-item {
      width: 100%;

      &__inputs {
        padding-right: 50px;
        width: calc(100% - 50px);
        gap: 4px;

        & > * {
          margin-bottom: 0;
          margin-left: 0;
          width: 100%;
        }

        .idf-criteria-value {
          margin-bottom: 0;

          vaadin-combo-box {
            margin-bottom: 0;
          }
        }
      }

      &__delete {
        top: 4px;
        right: 4px;
        width: 36px;
        height: 36px;
      }
    }
  }
  // make first if/else badges transparent
  #tct .idf-criteria-row:first-child .idf-criteria-badge {
    opacity: 0;
    cursor: default;
    pointer-events: none;
    user-select: none;
  }

  #ect .idf-criteria-row:first-child .idf-criteria-badge {
    opacity: 0;
    cursor: default;
    pointer-events: none;
    user-select: none;
  }

  .idf-set-specific {
    paper-dropdown-menu {
      --paper-menu-button-content_-_--paper-font-subhead_-_font-size: 13px;
    }
  }

  .idf-fade {
    opacity: 1;
    transition: all 0.3s;

    &--hidden {
      opacity: 0;
    }
  }
}

.idf-section-wrapper {
  position: relative;
  margin: 15px auto;
  width: 100%;
  border-bottom: 1px solid $bluegray;

  &__title {
    position: absolute;
    top: -6px;
    left: 15px;
    padding: 0 10px;
    background: #fff;
    color: $lightblue;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 0.9rem;
  }
}

.idf-steps-wrapper {
  position: sticky;
  top: 0;
  z-index: 660;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  // border: 1px solid $lightblue;
  border-radius: 4px;
  background-color: $darkblue;

  .idf-steps {
    --paper-tabs-selection-bar-color: #d25c32;
    display: flex;
    flex: 1;
    height: 40px;

    paper-icon-button {
      &.not-visible {
        --paper-icon-button_-_display: none;
      }
    }
  }

  paper-tab {
    .idf-remove {
      display: inline-block;
      margin-left: 2px;
      opacity: 0.7;
      transition: opacity 0.3s ease;
      transform: rotate(45deg);

      &:after {
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: transparent;
        content: "+";
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 16px;
        transition: background 0.3s ease;
      }

      &:hover::after {
        background: rgba($red, .5);
      }
    }

    &.idf-active {
      --paper-tab-content_-_color: #fff;
    }
  }

  .idf-add-wrapper {
    --paper-font-subhead_-_font-size: 13.5px;
    --paper-item-min-height: 36px;
    --paper-listbox-color: $darkblue;
    --paper-font-subhead_-_font-family: 'Open Sans';
    position: relative;

    &:hover {
      paper-listbox {
        display: block!important;
        height: auto;
      }

      .idf-btn:hover {
        background-color: transparent;
      }
    }

    .idf-btn.idf-link {
      color: #fff;

      &:Hover {
        color: $bluegray;
      }
    }

    paper-listbox {
      @include box-shadow();
      position: absolute;
      right: 0;
      padding: 6px;
      width: 163px;
      border-radius: 2px;
    }

    paper-item {
      padding: 0 10px;
      border-radius: 4px;
    }

    paper-item:hover {
      background: $gray;
      cursor: pointer;
    }
  }
}
//Form WYSIWYG

.idf-form-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  background: #ebebeb;

  .idf-form-fields {
    max-width: 100%;
    width: 100%;
  }

  .idf-pages-wrapper {
    width: 100%;
  }

  .idf-form-repeating-group,
  .idf-form-simple-group {
    .idf-add-wrapper {
      position: relative;
      z-index: 1;
      margin-top: -126px;
      margin-bottom: 56px;
      margin-left: 22px;
      width: calc(100% - 44px);
    }

    & > .idf-form-item-wrapper {
      margin-bottom: 20px;
      padding-bottom: 116px;
    }
  }

  .idf-add-wrapper {
    @include border-radius;
    margin-top: 12px;
    margin-right: auto;
    max-width: 100%;
    width: 100%;
    border: 1px solid $bluegray;
    background: #fff;
    transition: all 0.3s ease-out;
    @media(max-width:1600px) {
      .idf-add-item {
        width: 49%;
      }
    }

    paper-listbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 15px !important;
      border-radius: 6px;
    }

    paper-item {
      font-family: inherit;
    }

    .idf-add-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px 20px;
      width: 100%;
      border-bottom: 1px solid transparent;
      transition: all 0.3s ease-out;
      user-select: none;

      &:hover {
        background: rgba(#000000, 0.02);
      }
    }

    i {
      display: block;
      margin-right: 12px;
      width: 31px;
      height: 31px;
      background: url("/assets/img/form/add.svg");
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.3s ease-out;
    }

    .idf-small {
      margin-top: 4px;
      margin-bottom: 0;
      font-size: 12px;
      line-height: 1.4;
      opacity: 0.7;
    }

    &:hover {
      background: white;
      cursor: pointer;
    }

    &.idf-active {
      background: white;

      .idf-add-btn {
        border-bottom: 1px solid $bluegray;

        i {
          transform: rotate(45deg);
        }
      }
    }

    .idf-add-item,
    .idf-add-list {
      padding: 5px 10px;
    }

    .idf-add-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 4px;
      padding: 10px;
      width: 32%;
      border-radius: 3px;
      transition: all 0.3s ease-out;

      &:hover {
        background: $darkgray;
      }

      span {
        width: calc(100% - 43px);
      }

      .idf-important {
        font-size: 0.9rem;
      }

      &[data-tooltip] {
        // hide tooltip on active element
        &:after,
        &:before {
          display: none;
        }
        //fix focus background remove triangle
        &:before {
          display: none !important;
          background: none !important;
        }
      }

      &[disabled] {
        * {
          opacity: 0.4;
          filter: grayscale(100%);
          cursor: default;
          user-select: none;
        }
        // show tooltip on disabled element only
        &:after {
          display: block;
        }
      }
    }

    i.idf-headline {
      background-image: url("/assets/img/form/headline.svg");
    }

    i.idf-multiple {
      background-image: url("/assets/img/form/multiple.svg");
    }

    i.idf-multiple-advanced {
      background-image: url("/assets/img/form/multiple.svg");
    }

    i.idf-text {
      background-image: url("/assets/img/form/text.svg");
    }

    i.idf-upload {
      background-image: url("/assets/img/form/upload.svg");
    }

    i.idf-number {
      background-image: url("/assets/img/form/number.svg");
    }

    i.idf-timed {
      background-image: url("/assets/img/form/timed-input.svg");
    }

    i.idf-date {
      background-image: url("/assets/img/form/date.svg");
    }

    i.idf-signature {
      background-image: url("/assets/img/form/signature.svg");
    }

    i.idf-slider {
      background-image: url("/assets/img/form/slider.svg");
    }

    i.idf-calculated {
      background-image: url("/assets/img/form/calculated.svg");
    }

    i.idf-manifest {
      background-image: url("/assets/img/form/manifest.svg");
    }

    i.idf-confirmation {
      background-image: url("/assets/img/form/phone-confirmation.svg");
    }

    i.idf-phone {
      background-image: url("/assets/img/form/phone.svg");
    }

    i.idf-simple-text {
      background-image: url("/assets/img/form/simple-text.svg");
    }

    i.idf-group,
    i.idf-repeating {
      background-image: url("/assets/img/form/group.svg");
    }

    i.idf-pdf-viewer {
      background-image: url("/assets/img/form/pdf.svg");
    }

    i.idf-table {
      background-image: url("/assets/img/form/table.svg");
    }
  }

  .idf-pdf-viewer iframe {
    width: 100%;
  }
}
//right

.idf-field-settings {
  position: relative;
  display: flex;
  flex-direction: column;

  header {
    position: sticky;
    top: 40px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    background: #fff;
  }

  section {
    margin-bottom: 20px;
    padding: 0 20px;

    & > *:nth-child(1) {
      padding-top: 10px;
    }

    &:first-child {
      padding-top: 15px;
    }
  }

  .idf-red {
    padding: 0;
    min-width: auto;
    color: $red;
    transition: color 0.3s;

    &:Hover {
      color: color.adjust($red, $lightness: 20%);
    }
  }

  label {
    color: $darkblue;
    font-weight: 600;
    cursor: default;
  }

  .idf-set-validation {
    .idf-input-row {
      margin-bottom: 8px;
    }

    & > div > div {
      label {
        color: #676a6c;
        font-weight: 400;
        font-size: 0.8rem;
      }
    }
  }

  .idf-micro {
    margin-left: auto;
  }

  .idf-set-help paper-textarea,
  .idf-set-label paper-textarea {
    margin-top: -10px;
  }

  .idf-set-validation paper-checkbox {
    display: block;
    margin-bottom: 7px;
  }

  .idf-input-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    span {
      display: block;
      margin-bottom: 5px;
      width: 100%;
    }
  }

  .idf-form-choice {
    margin-right: -10px;

    paper-input {
      margin-right: 2px;
      width: calc(100% - 38px)!important;
    }

    paper-icon-button {
      padding: 10px;
      width: 38px!important;
      height: 38px!important;
      opacity: 0.55;
      transition: opacity 0.3s ease-in;

      &:hover {
        opacity: 1;
      }
    }
  }

  .idf-link {
    display: block;
    margin-top: 6px;
  }

  .idf-field-settings-content {
    position: relative;
    height: 100%;

    .idf-form-props-item {
      max-height: 80vh;
      height: 100%;
      border: 1px solid transparent;
      transition: border-color 0.35s ease;

      &.idf-invalid {
        border: 1px solid $red;
      }
    }
  }

  .idf-set-visibility .idf-row {
    align-items: center;
    justify-content: space-between;
  }

  .idf-visibility-condition {
    width: 14px;
    height: 14px;
    background-image: url("/assets/img/form/conditional.svg");
    background-position: center;
  }

  .idf-condition-builder {
    header {
      padding: 2px 10px !important;
      border-bottom: none!important;
      border-radius: 3px;
      background: $lightblue;
      color: $gray;

      span {
        display: flex;
        align-items: center;
      }

      vaadin-combo-box {
        --lumo-size-m: 28px;
        width: 65px;
      }
    }

    .idf-criteria-row {
      // @include border-radius;
      position: relative;
      margin-top: 6px;
      // padding: 5px;
      // border: 1px dashed $blue;
      paper-icon-button {
        position: absolute;
        top: 4px;
        right: 4px;
      }

      multiselect-combo-box,
      vaadin-combo-box {
        width: 100%;
      }

      vaadin-date-picker {
        width: 100%;
      }

      .idf-match-type {
        display: block;
        margin-bottom: 4px;
        line-height: 21px;
      }
    }
  }

  .idf-error-message {
    &:last-child {
      display: none;
      padding-bottom: 20px;
      padding-left: 20px;
    }
  }
}

.idf-form-builder-wizard {
  &.idf-invalid {
    & > .idf-error-message {
      display: block;
      padding-top: 7px;
    }

    .idf-tab-content.idf-active > .idf-card {
      border-color: $red;
    }
  }
}

.idf-form-item-wrapper {
  @include border-radius;
  position: relative;
  margin-bottom: 10px;
  padding: 23px 24px 30px;
  background: #fff;
  box-shadow: 0 2px 19px -16px rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: background 0.3s ease-in;

  label {
    margin: 0;
    cursor: default;
  }

  &.idf-active {
    background: white;
    box-shadow: 0 2px 19px -16px rgba(0, 0, 0, 0.9), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    & > .idf-active-state {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &:hover {
    background: white;
  }

  & > span {
    span {
      line-height: 1.5 !important;
    }
  }

  .idf-active-state {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    border-radius: 2px;
    background: $blue;
    opacity: 0;
    transition: all 0.2s ease-in;
    transform: translateX(4px);
  }

  .idf-item-id {
    position: absolute;
    right: 8px;
    bottom: 9px;
    display: flex;
    font-weight: 600;
    opacity: 0.6;

    label,
    span {
      margin: 0;
      color: $darkblue;
      font: inherit;
    }
  }

  .idf-conditional {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 14px;
    height: 14px;
    background-image: url("/assets/img/form/conditional.svg");
    background-position: center;
  }

  //item type
  .idf-headline label {
    color: #232323;
    letter-spacing: -0.4px;
    font-weight: bold;
    font-size: 27px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    line-height: 1.3;
  }

  paper-radio-group {
    --paper-radio-group-item-padding: 13px 0 0;
    display: flex;
    flex-direction: column;
  }

  .idf-signature-wrapp {
    position: relative;
    display: block;

    canvas {
      display: block;
      margin-top: 12px;
      width: 100%;
      height: 150px;
      border-radius: 4px;
      background: $gray;
      cursor: crosshair;
    }

    paper-icon-button {
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0.5;
      transition: opacity 0.3s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  paper-slider {
    margin-top: 10px;
    border-radius: 3px;
    background: #f3f5f7;
  }

  vaadin-upload {
    margin-top: 12px;
  }
}

.idf-form-group {
  & > label {
    margin-bottom: 8px;
  }
}

.idf-group {
  .idf-form-item-wrapper {
    box-shadow: 0 2px 19px -16px rgba(0,0,0,.9), 0 1px 3px 0 rgba(0,0,0,.1);
  }
}

.idf-phone-confirmation {
  .idf-form-item-wrapper {
    box-shadow: 0 2px 19px -16px rgba(0,0,0,.9), 0 1px 3px 0 rgba(0,0,0,.1);
  }
}

.idf-response-builder-wrapper {
  --lumo-border-radius: 32px;
  margin-top: 30px;
  margin-bottom: 27px;

  .idf-darkblue.idf-important {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    paper-toggle-button {
      margin-left: 10px;
    }
  }

  paper-toggle-button {
    --paper-toggle-button-label-color: #{$darkblue};
    --primary-color: #{$lightblue};
    display: inline-flex;
    font-family: 'Open Sans';
  }
  //quick response
  .idf-card {
    .idf-match-type,
    paper-icon-button {
      display: none;
    }
  }

  .idf-response-builder {
    position: relative;
    border: 1px solid $bluegray;
    border-radius: 5px;
    background: white;

    & > .idf-error-message {
      padding: 0 15px 15px 0;
    }

    &.idf-invalid {
      border-color: $red;

      .idf-error-message {
        position: absolute;
        margin-top: 7px;
      }

      .idf-response-builder {
        border-color: $bluegray;
      }
    }
    //inner
    .idf-response-builder {
      margin: 10px auto;
      width: calc(100% - 40px);
    }
  }

  .idf-if {
    @include border-radius;
    border-bottom: 1px solid $bluegray;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: $lightblue;
    color: $gray;

    .idf-rule-item__inputs {
      border-color: rgba(#fff, .4);
      background: rgba(#fff, .1);
    }

    & > header {
      padding: 3px 10px;
      border-radius: 3px 3px 0 0;
      background: $darkblue;

      vaadin-combo-box {
        margin-right: 0;
        width: 80px;
      }
    }

    & > div {
      padding: 4px 10px;
    }

    .idf-iff-wrapp {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;

      #ict {
        margin-bottom: 8px;
        width: 100%;
      }
    }

    .idf-match-type {
      display: flex;
      justify-content: center;
      min-width: 35px!important;
    }

    .idf-criteria-content {
      display: flex;
      align-items: center;
      //subcategory
      vaadin-combo-box:nth-child(1) {
        --vaadin-text-field-default-width: 200px;
        max-width: 200px;
        width: 100%;
      }
      //usualy compare
      vaadin-combo-box:nth-child(2) {
        max-width: 140px;
        width: 100%;
      }

      multiselect-combo-box {
        min-width: 200px;
      }

      .idf-criteria-value {
        width: 100%;
      }
    }

    vaadin-combo-box {
      margin-right: 5px;
      max-height: 38px;
    }

    paper-input {
      max-width: 200px;
      max-height: 38px;
      width: 100%;
    }

    vaadin-date-picker {
      max-width: 200px;
      width: 100%;
    }
  }

  .idf-then {
    margin-top: 7px;

    .idf-row {
      align-items: center;
      padding-right: 7px;
      width: 100%;
    }

    .idf-criteria-row {
      margin-bottom: 7px;
    }

    .idf-all-caps-label {
      display: flex;
      justify-content: center;
      min-width: 65px;
    }

    .idf-row-wrap {
      padding: 10px;
      width: 100%;
      border: 1px solid $bluegray;
      border-radius: 3px;

      & > span {
        padding: 0 10px;
        font-weight: 600;
      }
    }
  }

  .idf-else {
    margin-top: 7px;
    margin-bottom: 7px;

    .idf-row {
      align-items: center;
      padding-right: 7px;
      width: 100%;
    }

    .idf-criteria-row {
      margin-bottom: 7px;
    }

    .idf-all-caps-label {
      display: flex;
      justify-content: center;
      min-width: 65px;
    }

    .idf-row-wrap {
      padding: 10px;
      width: 100%;
      border: 1px solid $bluegray;
      border-radius: 3px;
    }

    .idf-btn {
      padding: 0 10px !important;
    }
  }

  .idf-else,
  .idf-then {
    .idf-match-type {
      display: none;
    }

    .idf-criteria-row + .idf-criteria-row .idf-match-type {
      display: block;
    }
  }

  .idf-criteria-content vaadin-combo-box:nth-child(1) {
    width: 195px;
  }

  .idf-criteria-row {
    .idf-match-type {
      justify-content: flex-start;
      margin: 5px 0;
    }

    .idf-criteria,
    .idf-criteria-content {
      display: flex;
      align-items: center;
    }

    .idf-criteria-content > *:not(div) {
      margin-right: 5px;
    }
  }

  .idf-rule-item {
    @media(max-width: 1500px) {
      &__delete {
        width: 36px;
        height: 36px;
      }

      &__inputs {
        .idf-criteria-value {
          paper-input {
            --paper-input-container-input_-_padding: 9px 10px !important;
            max-height: 36px;
          }
        }
      }
    }
    @media(max-width: 1200px) {
      &__delete {
        width: 31px;
        height: 31px;
      }

      &__inputs {
        .idf-criteria-value {
          paper-input {
            --paper-input-container-input_-_padding: 7.5px 10px !important;
            top: -1px;
            max-height: 31px;
          }
        }
      }
    }
    &__inputs {
      border-radius: 32px;

      & > * {
        margin-left: 4px;
      }

      vaadin-combo-box {
        width: auto;

        &:nth-of-type(1) {
          min-width: 215px;
        }
      }

      .idf-criteria-value {
        vaadin-combo-box {
          min-width: 130px;
          width: auto;
        }

        paper-input {
          --paper-input-container-input_-_border-radius: 32px !important;
          --paper-input-container-input_-_padding: 10px !important;
        }
      }
    }

    &__delete {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
}

.idf-form-builder-wizard .idf-pages-wrapper > .idf-row {
  justify-content: space-between;

  paper-input {
    width: calc(100% - 160px);
  }

  .idf-btn {
    --paper-font-caption_-_line-height: 0;
    top: -2px;
    align-self: flex-end;
    padding: 0;
    width: 150px;
    text-align: center;
    line-height: 36px;
  }
}

.idf-tree-container {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  background: white;
}
//Text / Label / RichText Style

.idf-form-item-wrapper {
  label {
    display: block;
    margin-bottom: 12px;
    color: #333333;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0.5em 0 0.2em;
      font-family: Georgia, 'Times New Roman', Times, serif;
      line-height: 1.3!important;
    }

    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 38px;
    }

    h3 {
      font-size: 30px;
    }

    h4 {
      font-size: 24px;
    }
  }
}

.idf-richtext-editor {
  min-height: 75px!important;
  color: #333333;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;

  p {
    color: #333333;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0.5em 0 0.2em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    line-height: 1.3!important;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }
}
//Fullscreen Workaround - Hide some of the elements

.idf-richtext-body-fullscreen {
  .idf-form-viewer,
  .idf-response-builder-wrapper,
  .idf-steps-wrapper,
  .idf-top-actions {
    opacity: 0;
    pointer-events: none;
  }

  .idf-form-edit .idf-form-builder .idf-side header {
    z-index: -9;
  }
}
@media(max-width:1400px) {
  .idf-form-edit {
    .idf-form-builder {
      .idf-side {
        width: 400px;
      }

      .idf-form-viewer {
        width: calc(100% - 400px);
      }
    }
  }
}
@include sm {
  .idf-form-viewer .idf-add-wrapper .idf-add-item {
    width: 100%;
  }
}

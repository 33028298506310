@use "../../utilities/index" as *;

.idf-uw-review-edit {
  section {
    padding-top: 30px;
  }

  .idf-uw-review-general {
    //make 2 columns for smaller screens
    @media screen and (max-width: 1200px) {
      .idf-item-data {
        max-width: 620px!important;

        & > * {
          width: calc(50% - 10px);
          //reset the previous one
          &:nth-child(3n) {
            margin-right: 20px;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .idf-item-data,
    .idf-row {
      & > * {
        margin-right: 20px;
        margin-bottom: 15px;
        max-width: 300px;
        width: 100%;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .idf-row {
      @media screen and (max-width: 1020px) {
        paper-input,
        vaadin-combo-box:nth-of-type(2) {
          flex: 1; //let the button take the left space
        }
      }
    }

    .idf-row paper-button {
      align-self: flex-end;
      margin-bottom: 19px;
      width: auto;
    }

    .idf-item-data {
      display: flex;
      flex-wrap: wrap;
      padding-top: 10px;
      padding-bottom: 7.5px;
      max-width: 940px;
      border-top: 1px solid $bluegray;
    }

    .idf-person .idf-item-data,
    .idf-phone .idf-item-data,
    .idf-vin .idf-item-data {
      max-width: 620px;

      & > * {
        width: calc(50% - 10px);
        //reset the previous one
        &:nth-child(3n) {
          margin-right: 20px;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  .idf-addl {
    $columns: 14% 10% 12% 17% 14% 8% 85px;
    @include mediumTable($columns);
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .idf-table-content .idf-row {
    margin-top: 5px;
    background: white;
  }
}

@use "../../utilities/index" as *;

.idf-case-view {
  .idf-top-details {
    flex-direction: column;
    margin-top: 34px;

    .idf-title {
      margin-left: 0!important;
    }

    .idf-details-panel {
      display: flex;
      padding: 20px;

      .idf-list-details {
        padding-right: 45px;
      }
    }

    paper-tabs {
      --paper-tab-content_-_color: #{$darkblue};
      position: absolute;
      right: -1px;
      bottom: 100%;
      left: -1px;
      height: 35px;
      background: #f3f3f4;
    }

    paper-tab {
      flex: none;
      padding: 7px 13px;
      border: 1px solid transparent;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      transition: all 0.2s ease-in;
    }

    paper-tab:hover {
      border: 1px solid $flatblue;
      border-bottom: none;
    }

    paper-tab.idf-active {
      border: 1px solid $flatblue;
      border-bottom: none;
      background: $bluegray;
    }

    .idf-status-completed {
      @include allcapslabel;
      color: $green;
    }

    .idf-status-assigned,
    .idf-status-in-process,
    .idf-status-re-assigned {
      @include allcapslabel;
      color: $accent;
    }
  }

  .idf-case-content {
    padding-top: 142px;

    section {
      margin-top: 35px;
    }

    .idf-rules-list {
      &.idf-multiple > div {
        column-count: 2;

        & > div {
          width: 100%;
          break-inside: avoid;
        }
      }

      &:not(.idf-multiple) .idf-col-2 {
        display: flex;

        section {
          width: 50%;

          &+section {
            padding-left: 30px;
          }
        }
      }

      section {
        margin-top: 23px;
      }

      .idf-list-details {
        margin-top: 18px;

        .idf-list-detail {
          display: block;
          margin-top: 4px;

          & > * {
            line-height: 1.33;
          }
        }
      }
    }
    //risk rules cell
    .idf-details-item {
      margin-bottom: 15px;
    }

    .idf-is-resolved .idf-details-summary {
      background: $green;
    }

    .idf-details-summary {
      padding: 5px 18px;
    }

    .idf-details-content {
      background: white;

      .idf-row {
        align-items: flex-start;
        justify-content: space-between;

        .idf-rule-description {
          flex: 1;
          line-height: 1.35;
        }

        .idf-btn {
          margin-top: -8px;
        }
      }
    }
  }
}

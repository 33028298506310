@use "../utilities/index" as *; //animation move
$from: 10px;
$to: 3px; //animation timeing
$out: all 0.1s ease 0.0s;
$in: transform 0.1s $ease 0.35s, opacity 0.2s ease 0.35s;

[data-tooltip] {
  position: relative;
  overflow: visible;

  &:after {
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 999;
    display: inline-block;
    margin-bottom: 9px;
    padding: 6px 8px;
    border-radius: 4px;
    background: $darkblue;
    color: white;
    content: attr(data-tooltip);
    text-transform: initial;
    white-space: pre;
    letter-spacing: 0.9px;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    opacity: 0;
    transition: $out;
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translate(-50%, $from);
    transform-origin: top;
    pointer-events: none;
    user-select: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
  }

  &:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: $darkblue;
    content: "";
    opacity: 0;
    transition: $out;
    transform: translate(-50%, $from);
    transform-origin: top;
    pointer-events: none;
    user-select: none;
  }

  &:hover {
    &:after,
    &:before {
      opacity: 1;
      transition: $in;
      transform: translate(-50%, $to);
      pointer-events: none;
    }
  }
  //position
  &[data-tooltip-position="up-left"] {
    &:after {
      left: 0;
      transform: translate(0, $from);
      transform-origin: top;
    }

    &:before {
      left: 5px;
      transform: translate(0, $from);
      transform-origin: top;
    }

    &:hover {
      &:after,
      &:before {
        transform: translate(0, $to);
      }
    }
  }

  &[data-tooltip-position="up-right"] {
    &:after {
      right: 0;
      left: auto;
      transform: translate(0, $from);
      transform-origin: top;
    }

    &:before {
      right: 5px;
      left: auto;
      transform: translate(0, $from);
      transform-origin: top;
    }

    &:hover {
      &:after,
      &:before {
        transform: translate(0, $to);
      }
    }
  }

  &[data-tooltip-position="down-left"] {
    &:after {
      top: 100%;
      right: auto;
      bottom: auto;
      left: 0;
      margin-top: 9px;
      margin-bottom: 0;
      transform: translate(0, -$from);
    }

    &:before {
      top: 100%;
      left: 5px;
      width: 0;
      height: 0;
      border-top-color: transparent;
      border-bottom-color: $darkblue;
      transform: translate(0, -$from);
    }

    &:hover {
      &:after,
      &:before {
        transform: translate(0, -$to);
      }
    }
  }

  &[data-tooltip-position="down-right"] {
    &:after {
      top: 100%;
      right: 0;
      bottom: auto;
      left: auto;
      margin-top: 9px;
      margin-bottom: 0;
      transform: translate(0, -$from);
    }

    &:before {
      top: 100%;
      right: 5px;
      left: auto;
      border-top-color: transparent;
      border-bottom-color: $darkblue;
      transform: translate(0, -$from);
    }

    &:hover {
      &:after,
      &:before {
        transform: translate(0, -$to);
      }
    }
  }

  &[data-tooltip-position="right"] {
    &:after {
      top: 50%;
      right: auto;
      bottom: auto;
      left: 100%;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 12px;
      transform: translate(-$from , -50%);
    }

    &:before {
      top: 50%;
      left: 100%;
      margin-left: 3px;
      border-top-color: transparent;
      border-right-color: $darkblue;
      border-bottom-color: transparent;
      transform: translate(-$from, -50%);
    }

    &:hover {
      &:after,
      &:before {
        transform: translate(-$to, -50%);
      }
    }
  }
  //size
  &[data-tooltip-size="big"] {
    &:after {
      font-size: 13px;
    }
  }
}

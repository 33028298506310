@use "sass:color";
@use "../../utilities/index" as *;

.idf-rule-history {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  padding-top: 6px;
  padding-bottom: 30px;


  .idf-history-wrapp {
    position: sticky;
    top: 82px;
    width: 200px;
    height: calc(100vh - 90px);
    border-radius: 6px;

    &__title {
      margin-top: 0;
      font-size: 0.8rem;
      color: $flatlightblue;
      font-weight: 600;
      margin-bottom: 12px;
      display: block;
      text-align: center;
      opacity: 0;
    }

    .idf-wh {
      display: none;
    }

    &__body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      margin-top: 0;
      //SIMPLEBAR SCROLL DIV
      & > div {
        height: calc(100% - 30px);
      }

      .idf-workflow {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;
        padding-left: 12px;

        &:before {
          content: '';
          position: absolute;
          left: 6px;
          height: 100%;
          width: 1px;
          background-color: $bluegray;
        }
      }
    }

    &__list {
      position: relative;
    }
  }

  .idf-before-compare {
    height: 100%;
    border: 1px solid $darkgray;
    border-right: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: white;
  }

  .idf-after-compare {
    height: 100%;
    border: 1px solid $darkgray;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: white;
  }

  .idf-is-changed,
  .idf-is-removed {
    position: relative;
    padding: 4px 6px;

    .idf-rule-generator__content {
      border-color: transparent !important;
    }
  }

  p,
  span {
    &.idf-is-changed,
    &.idf-is-removed {
      display: inline-block !important;
    }
  }

  .idf-is-changed:before,
  .idf-is-removed:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border-radius: 6px;
    background: #ffefef;
    content: '';
  }

  .idf-is-changed:before {
    background: #ace7d4;
  }

  .idf-is-changed {
    .idf-dc-item {
      padding: 10px;
      background: #ace7d4;
      color: $flatlightblue;

      .idf-tag {
        background-color: #518f7b;
      }
    }
  }

  .idf-is-removed {
    .idf-dc-item {
      padding: 10px;
      background: #ffefef;
      color: $flatlightblue;

      .idf-tag {
        background-color: #a37b7b;
      }
    }
  }

  .idf-check-list {
    .idf-is-changed:before,
    .idf-is-removed:before {
      right: 20px;
    }
  }

  .idf-criteria-content {
    flex-wrap: wrap;
  }

  .idf-workflow-history-item {
    position: relative;
    display: block;
    padding-left: 6px;
    margin: 0;
    border: none;

    &:before {
      content: '';
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid $bluegray;
      transition: all .3s;
    }

    .idf-text-wrapp {
      display: block;
      padding: 8px;
      outline: transparent;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s;
    }

    .idf-avatar {
      display: none;
    }

    .idf-bold-text {
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 6px;
      color: $darkblue;
    }

    .idf-link {
      display: inline-block;
      margin-top: 6px;
      font-weight: 600;
    }

    &.idf-set-border,
    &:hover {
      .idf-text-wrapp {
        background: $bluegray;
      }

      &:before{
        background-color: $blue;
        border-color: $blue;
      }
    }

    .idf-darkblue {
      margin: 0;
      line-height: 1.5;
      color: $flatlightblue;
      display: block;
    }
  }

  .idf-rule-builder {
    .idf-rule-item {
      flex-wrap: nowrap;
      gap: inherit !important;
    }

    .idf-rule-generator {
      &__content {
        background: transparent;
      }
    }

    .idf-criteria-badge:after {
      display: none;
    }

    .idf-criteria-row {
      margin: 8px 0 !important;

      .idf-criteria {
        width: calc(100% - 50px) !important;

        .idf-criteria-content {
          display: inline-flex;

          .idf-criteria-value {
            display: inline-flex;
            max-width: 180px;
            width: auto !important;
          }
        }
      }
    }

    paper-input,
    paper-textarea,
    vaadin-combo-box {
      width: auto !important;
    }

    .idf-criteria-value__change {
      display: none;
    }
  }

  .idf-rule-compare {
    display: flex;
    flex: 1;

    &__title {
      color: $flatlightblue;
      font-weight: 600;
      display: block;
      margin-bottom: 12px;
      text-align: center;
      font-size: 0.8rem;
    }

    &__column {
      width: 50%;

      .simplebar-wrapper {
        margin: 0 !important;
      }
    }
  }

  .idf-list-details {
    .idf-list-detail {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      flex-wrap: wrap;

      label {
        display: block;
        width: 120px;
      }

      span {
        line-height: 1.5;
      }
    }

    &--general {
      .idf-list-detail {
        label {
          width: 90px;
        }
      }
    }

    &--settings {
      .idf-list-detail {
        margin-bottom: 12px;

        &:nth-of-type(1) {
          label {
            width: 100%;
          }
        }
      }
    }

    &--output {
      .idf-list-detail {
        label {
          width: 90px;

          &:After {
            content: ':';
          }
        }
      }
    }
  }

  .idf-rule-view {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 15px;
      border-bottom: 1px solid $bluegray;
      min-height: 43px;

      .idf-darkblue {
        margin: 0 !important;
        width: calc(100% - 60px);
      }
    }

    &__body {
      padding: 15px;
    }

    .idf-check-list {
      justify-content: space-between;
      padding-right: 0;

      paper-checkbox {
        flex: auto;
        width: 48%;
        max-width: 48%;
        margin-bottom: 10px;
      }
    }

    hr {
      display: block;
      width: 100%;
    }

    .idf-darkblue.idf-important {
      margin-top: 0;
      margin-bottom: 15px;
    }

    p{
      line-height: 1.5;
    }
  }
}

.idf-carfax-data{
    section{
        margin-top:25px;
    }
    .idf-row{
        padding-bottom: 11px;
    }
    .idf-ico{
        width: 18px;
        height: 18px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url("../img/carfax/danger.svg");
        margin-right:8px;
    }
    .idf-report-owner{
        background-image: url("../img/carfax/owner.svg");
    }
    .idf-report-odometer{
        background-image: url("../img/carfax/odometer.svg");
    }
    .idf-report-service{
        background-image: url("../img/carfax/service.svg");
    }
    .idf-report-value{
        background-image: url("../img/carfax/value.svg");
    }
}
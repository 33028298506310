@use "../../utilities/index" as *;

.idf-form-search {
  //Table Columns
  $columns: 1fr, 30%, 35%, 37px;
  @include bigTable($columns);
  //focus row
  .idf-row {
    box-shadow: inset 0 0 0 0 $flatblue;
    transition: box-shadow 0.2s ease;
  }

  .idf-focus .idf-row,
  .idf-focus.idf-row {
    box-shadow: inset 0 0 0 1.5px $flatblue;
  }

  .idf-disabled {
    opacity: 0.52;
  }
}

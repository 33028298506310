@use "../../utilities/index" as *;

.idf-content-panel .idf-risk-rules-search {
  .idf-rules-filter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;

    .idf-filters-wrapper {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      & > * {
        margin-right: 20px;
        min-width: 20px;
        max-width: 100%;
        width: calc(20% - 20px);
      }
    }

    .idf-row {
      align-self: flex-start;
      margin-top: 12px;
    }
  }

  .idf-risk-rules {
    transition: height 0.3s ease;
    $columns: 75px 1fr 15% 11.5% 10% 10% 10% 10% 75px;
    @include bigTable($columns);
    @include filterVisible();
    //remove description first
    @media only screen and (max-width: 1500px) {
      .idf-c-3 {
        display: none;
      }
    }
    //Remove Update Date
    @media only screen and (max-width: 1200px) {
      .idf-update-date {
        display: none;
      }
    }

    .idf-focus .idf-flex-table.idf-row {
      border: 2px solid $blue !important;
      transition: border-color 0.4s ease-in-out;
    }

    .idf-disabled {
      opacity: 0.52;
    }

    .idf-flex-table {
      &.idf-row {
        min-height: 67px;
        border: 2px solid transparent;

        &:hover {
          border: 2px solid $flatblue;
          cursor: pointer;
          transition: border-color 0.4s ease-in-out;
        }
      }

      .idf-action {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-end;
        padding-right: 5px !important;
        -ms-flex-align: center;
      }
    }
  }

  .idf-table .idf-flex-table.idf-header {
    @extend .sticky-table-header;
  }
}

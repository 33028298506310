@use "../../utilities/index" as *;

.idf-advanced-analytics {
  $columns: 5% 1fr 6% 7% 7% 7% 7% 7% 7% 7% 7% 6.5% 60px;
  @include bigTable($columns);

  .idf-top-actions-wrapper {
    paper-dropdown-menu,
    paper-listbox {
      min-width: 180px;
    }
  }

  .idf-aa-filter {
    margin-bottom: 25px;

    .idf-filters-wrapper {
      display: block;

      .idf-filter-input {
        margin-bottom: 8px;
      }
    }

    .idf-row {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;

      .idf-btn {
        margin: 0 0 4px;
        padding-right: 6px;
        padding-left: 6px;
        width: 48%;
        text-align: center;
      }
    }
  }

  .idf-row {
    margin-top: 4px;
    background: white!important;

    &--excluded {
      @for $i from 2 through 12 {
        .idf-c-#{$i} {
          opacity: 0.6;
        }
      }
    }
  }

  .idf-header {
    @extend .sticky-table-header;
    line-height: 2em;
  }

  .idf-table-content {
    height: calc(100vh - 140px) !important;

    .idf-all-caps-label.idf-link {
      margin-left: auto;
      padding: 0.8em;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .idf-row:hover .idf-all-caps-label.idf-link {
      opacity: 1;
    }

    .idf-slot {
      height: 100%;

      .idf-contextual-menu-icon {
        margin-right: 8px;
      }

      .simplebar-content > div > div:last-of-type {
        .idf-contextual-menu {
          top: auto;
          bottom: 0.7em;
        }
      }
    }

    .idf-c-1 {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      width: 100px;
      gap: 6px;

      i {
        margin: 0;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }

  .idf-row-details {
    position: relative;
    overflow: hidden;
    padding: 0 1.4em;
    max-height: 0;
    border: 2px solid transparent;
    opacity: 0;
    transition: all 0.2s ease;

    footer {
      @include allcapslabel();
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0.65em 1em;
      background: $blue;
      color: white;

      .idf-close {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        transition: opacity 0.3s;
        gap: 8px;

        &:hover {
          opacity: 0.7;
        }

        svg {
          top: -1px;
          vertical-align: middle;
        }
      }
    }

    .idf-identification {
      .idf-list-details {
        margin-right: 45px;
      }
    }
  }

  .idf-contextual-menu {
    top: 0.7em!important;
    bottom: auto!important;
  }

  .idf-exteded {
    .idf-flex-table {
      border-bottom: solid 1px $blue;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background: $blue !important;
      color: white;
    }

    .idf-row-details {
      display: block;
      padding: 1.3em 1.4em 2em;
      max-height: 100%;
      border: 2px solid $blue;
      border-top: none;
      border-bottom: none;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background: white;
      opacity: 1;
      transition: all 0.2s ease;
      grid-column-start: 1;
      grid-column-end: span 13;
    }

    .idf-flex-table {
      grid-template-rows: auto auto;
    }

    .idf-plus {
      background-image: url("../img/add-w.svg");
    }

    .idf-top-down,
    .idf-top-up {
      background-image: url("../img/arrow-up-w.svg");
    }

    i.idf-contextual-menu-icon {
      background-image: url("../img/contextual-menu-w.svg");
    }

    .idf-rank {
      i {
        opacity: 1;
      }

      span {
        opacity: 0.4;
      }
    }

    .idf-link {
      color: #fff;
      opacity: 1 !important;
      transition: color 0.3s !important;

      &:hover {
        color: #7cc5ff;
      }
    }

    .idf-contextual-menu-icon {
      filter: brightness(0%) invert(1);
    }
  }

  .idf-contextual-menu {
    top: 0.7em!important;
    bottom: auto!important;
  }

  .idf-tooltip {
    top: -0.4em;
    left: 2em;
    background-color: $red;

    &:after {
      border-color: transparent $red transparent transparent;
    }
  }

  .idf-sort-title {
    position: relative;
    padding-left: 18px !important;
    cursor: pointer;

    &:Hover {
      opacity: 0.8;
    }

    &:before {
      position: absolute;
      top: calc(50% - 4px);
      left: 6px;
      width: 0;
      height: 0;
      border-width: 0 3px 5px 3px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      content: '';
      opacity: 0.2;
      transform: translateY(-50%);
    }

    &:after {
      position: absolute;
      top: calc(50% + 4px);
      left: 6px;
      width: 0;
      height: 0;
      border-width: 5px 3px 0 3px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
      content: '';
      opacity: 0.2;
      transform: translateY(-50%);
    }
  }

  .sort-asc {
    &:before {
      border-color: transparent transparent #fff transparent;
      opacity: 1;
    }
  }

  .sort-desc {
    &:after {
      border-color: #fff transparent transparent transparent;
      opacity: 1;
    }
  }

  .idf-contextual-menu-icon {
    transition: opacity 0.3s;

    &:before {
      display: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .idf-advanced-analytics-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .idf-advanced-analytics {
      width: 100%;
    }

    .simplebar-content {
      & > div {
        padding-bottom: 40px;
      }
    }
  }

  .idf-aside-filters {
    overflow: hidden;
    width: 0;
    opacity: 0;
    transition: all 0.3s;

    &.idf-active {
      overflow: visible;
      margin-right: 15px;
      width: 285px;
      opacity: 1;
    }
  }
}

.idf-carrier_map-add {
  paper-button,
  paper-textarea {
    margin-top: 16px;
  }

  vaadin-combo-box {
    margin-bottom: 30px;
  }
}

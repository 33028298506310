@use "../../utilities/index" as *;

.idf-doc-review {
  display: flex;
  overflow: hidden;

  .idf-doc-view {
    margin-right: 20px;
    width: 100%;
    background: white;

    iframe {
      width: 100%;
      height: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
    }
  }

  .idf-review-sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-width: 6100px;
    width: 48vw;
    height: inherit;

    .idf-separator {
      margin: 15px;
      width: calc(100% - 30px)!important;
    }
  }

  .idf-failed-rules-list,
  .idf-list-details {
    padding: 0 15px;
  }

  .idf-failed-rules-list {
    .idf-row {
      align-items: center;
      color: $blue;
      cursor: pointer;

      .idf-link {
        margin-left: 20px;
        opacity: 0;
      }

      &:hover {
        .idf-link {
          opacity: 1;
        }
      }
    }
  }

  .idf-input {
    max-width: 70%;
  }

  .idf-review-form {
    display: flex;
    flex-direction: column;
    padding: 25px 10px 20px 15px;
    border-bottom: 1px solid $bluegray;
    // gap:10px;
    .idf-row {
      align-items: center;
      margin-top: 15px;
      margin-bottom: 15px;
      gap: 10px;

      & > *:nth-child(1) {
        width: 45%;
      }

      & > *:nth-child(2) {
        width: 25%;
      }

      & > *:nth-child(3) {
        width: 21%;
      }

      paper-icon-button {
        margin-top: 10px;
        margin-left: -5px;
        padding: 3px;
        width: 26px;
        height: 26px;
        color: #F3F5F7;
        color: $flatblue;
      }

      paper-icon-button:hover {
        color: $blue;
      }
    }

    .idf-btn {
      align-self: flex-start;
      margin: 20px 0 0;
    }
  }

  .idf-list-details.idf-aside-list > * {
    width: calc(33.33% - 40px);
  }

  .idf-review-info {
    padding: 0 15px;
  }

  .idf-section {
    position: relative;
  }

  .idf-collapse {
    position: absolute;
    top: -3px;
    left: 15px;
    width: calc(100% - 30px);
    height: 20px;
    background-position: right;
    background-size: 14px;
    background-repeat: no-repeat;
  }
}

@use "../../utilities/index" as *;

.idf-request-changes {
  .idf-action-row {
    justify-content: end;
  }

  .idf-separator {
    margin-top: 0;
  }

  .idf-details-item {
    margin-top: 10px;
  }

  .idf-details-content {
    padding-top: 0!important;
  }

  .idf-rich-text-editor-wrapp {
    margin: 10px 0;
  }

  .idf-add-wrapp .idf-btn {
    margin: 15px 0 0;
  }
}

@use "utilities/index" as *;
@use "bootstrap-grid";
@use "components/index";
@use "graph";
@use "image-analysis";
@use "timeline";
@use "mvr";
@use "webservice-search";
@use "search-list-view";
@use "search-details-view";
@use "pages";
@use "polymer";
@use "main-frame";

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.idf-content-panel {
  @include bodytext();
  border: none !important;
  background: transparent !important;

  button,
  input,
  select,
  textarea {
    font-family: "open sans", "Helvetica Neue", sans-serif;
  }

  .idf-page-wrapper {
    height: inherit;
  }

  .idf-container {
    max-width: $container;

    .idf-top-actions-wrapper {
      max-width: $container;
    }

    &--xl {
      max-width: 100%;
      width: 1660px;
    }

    .idf-top-actions-wrapper {
      max-width: $container;
    }
  }
}
@include md {
  html {
    font-size: 15px !important;
  }
}
@include sm {
  html {
    font-size: 13px !important;
  }
}
// temp fix for old inputs

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
select,
textarea {
  min-height: 36px;
}

.editRow {
  position: relative;

  td,
  th,
  tr {
    position: relative;
  }

  .inputErrorIndicator {
    top: 6px !important;
  }
}

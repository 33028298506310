@use "../../utilities/index" as *;

.idf-resolution-list {
  .idf-details-content {
    .idf-row {
      margin-bottom: 13px;

      vaadin-combo-box {
        margin-right: 3%;
        width: 67%;
      }

      vaadin-date-picker {
        width: 33%;
      }
    }

    .idf-3-col {
      margin-top: 13px;

      vaadin-combo-box {
        margin-right: 0;
        margin-left: 3%;
        width: 33%;
      }

      paper-input {
        width: 33%;
      }
    }
  }

  .idf-slot {
    margin: 0!important !important;
    padding: 0 0 15px!important !important;
  }
}

.idf-resolution {
  .idf-btn {
    margin: 15px 0;
  }
}

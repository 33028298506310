@use "../../utilities/index" as *; //Card View
@mixin policyCardView($responsive) {
  .idf-address,
  .idf-city,
  .idf-header,
  .idf-hide-in-cards,
  .idf-score,
  .idf-source,
  .idf-state,
  .idf-zip {
    display: none;
  }

  .simplebar-content > div {
    width: 100%;
  }

  .simplebar-content > div > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
    
    @if $responsive == true {
      @media only screen and (min-width: 1650px) {
        & > div {
          width: calc(20% - 20px);
        }
      }
      @media only screen and (max-width: 1650px) {
        & > div {
          width: calc(25% - 20px);
        }
      }
      @media only screen and (max-width: 1400px) and (min-width: 1100px) {
        & > div {
          width: calc(33.33% - 20px);
        }
      }
      @media only screen and (max-width: 1100px) {
        & > div {
          width: calc(50% - 20px);
        }
      }
      @media only screen and (max-width: 630px) {
        & > div {
          width: calc(100% - 20px);
        }
      }
    }

    & > div {
      margin: 10px;
      padding-bottom: 36px;
      width: calc(50% - 20px);
    }
  }

  .idf-table-content {
    height: 100%!important; //cards Container

    .idf-row {
      position: relative;
      display: block;
      margin-top: 0;
      height: calc(100% + 36px);

      &:hover {
        background: white;
      }

      .idf-only-for-card {
        display: block;
      }

      .idf-list-details {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 20px;

        span {
          margin: 0 0.3rem 0 0;
        }
      }

      i.idf-contextual-menu-icon {
        margin-right: -4px;
        height: 30px;
      }

      .idf-view-more {
        @include allcapslabel();
        color: $blue;
        line-height: 30px;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      .idf-flex-row {
        padding: 0;
        border: none;
      }

      .idf-no,
      .idf-type {
        display: inline-block;
        margin-right: 7px;
        width: auto;
        color: $darkblue;
      }

      .idf-card-address,
      .idf-holder,
      .idf-holder,
      .idf-transaction-date,
      .idf-type {
        padding-left: $cardpadding;
      }

      .idf-holder,
      .idf-type {
        padding-top: $cardpadding;
        width: auto;
        font-weight: $bold;
      }

      .idf-holder {
        padding-top: $cardpadding * 0.8;
      }

      .idf-transaction-date {
        padding-top: 4px;
      }

      .idf-card-address {
        margin-top: 2px;
        padding-bottom: $cardpadding * 0.8;
        line-height: 1.4;
      }

      .idf-issue-date,
      .idf-status {
        position: absolute;
        top: $cardpadding;
        right: $cardpadding;
        display: block!important;
        width: auto;
      }

      .idf-issue-date {
        top: 36px;
      }

      .idf-action {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 2px $cardpadding!important !important;
        width: 100%;
        border: 1px solid $bluegray;
        border-radius: 4px;
        background: $gray;
      }
    }
  }
}

.idf-policy-search {
  //Table Columns
  $columns: 4%, 4.5%, 8%, 10.5%, 14%, 1fr, 10.5%, 5%, 6%, 6%, 80px, 7%, 37px;
  @include bigTable($columns);
  @media only screen and (max-width: 1530px) {
    .idf-c-4 {
      display: none;
    }
  }
  @media only screen and (max-width: 960px) {
    //only cards
    @include policyCardView(false);
  }
  @media only screen and (max-width: 630px) {
    //only cards
    @include policyCardView(false);

    .simplebar-content > div > div > div {
      width: calc(100% - 20px);
    }
  }

  .idf-policy {
    .idf-card-address,
    .idf-list-details {
      display: none;
    }

    .idf-table-content {
      .idf-type {
        text-transform: capitalize;
      }
    }
  }
  //Card
  .idf-card-view {
    @include policyCardView(true);
  }
}

@use "sass:color";

@keyframes pointPulse {
	0% {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.8;
	}

	85% {
		top: -6px;
		left: -6px;
		width: calc(100% + 12px);
		height: calc(100% + 12px);
		opacity: 0.1;
	}

	100% {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
@keyframes shine {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(200%);
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translateX(60px);
	}

	to {
		opacity: 1;
		transform: translateX(0%);
	}
}
@keyframes fadeOutRight {
	from {
		opacity: 1;
		transform: translateX(0px);
	}

	to {
		opacity: 0;
		transform: translateX(-60px);
	}
}
@keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.idf-shine {
	position: relative;
	overflow: hidden;
	background: transparent;

	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		background-image: linear-gradient(90deg, transparent, color.adjust(#fff, $lightness: 10%), transparent);
		content: '';
		animation: shine 2500ms linear infinite;
	}
}

.fadeInRight {
	animation: fadeInRight 0.5s ease;
}

.fadeOutRight {
	animation: fadeOutRight 0.5s ease;
}

.blink {
	animation: blink 1s step-start 0s infinite;
}

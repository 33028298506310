@use "../../utilities/index" as *;

.idf-address-details {
  .idf-map .idf-slot {
    height: 400px;
  }

  .idf-policies-small {
    $columns: 12% 30% 40% 22% 37px;
    @include fixTable($columns);
  }
}

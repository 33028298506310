@use "../../utilities/index" as *;

.idf-assign {
  .idf-card {
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0,0,0, .06);
  }

  .idf-assign-to {
    display: block;
    margin-bottom: 20px;
    color: $darkblue;
    font-weight: $bold;
    font-size: 0.9rem;
  }

  .idf-assign-info {
    margin-bottom: 10px;

    paper-button,
    vaadin-combo-box {
      margin-left: 15px;
    }

    paper-button {
      align-self: flex-end;
      margin-bottom: 3px;
    }
  }

  .idf-filters {
    margin-top: 25px;
    margin-bottom: 15px;

    paper-input {
      margin-right: 15px;
      max-width: 285px;
    }

    paper-button {
      align-self: flex-end;
      margin-bottom: 3px;
      margin-left: 15px;
    }

    vaadin-combo-box {
      flex: 1;
    }
  }

  .idf-team-search {
    $columns: 1fr 25% 230px;
    @include fixTable($columns);
  }
}

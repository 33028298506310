@use "../../utilities/index" as *;

.idf-form-details {
  padding-left: 8px;
  //Form Name
  .idf-action-row p {
    flex: 1;
  }

  .idf-assigned-list {
    margin: 18px 0 25px;

    .idf-all-caps-label {
      margin-bottom: 10px;
    }

    li {
      display: block;
      margin-bottom: 4px;

      &:before {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 3px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: $blue;
        content: '';
      }
    }

    .idf-warning {
      display: inline-block;
    }
  }

  .idf-form-viewer {
    padding: 0;
    background: transparent;
  }

  .idf-form-item-wrapper {
    margin: 0;
    padding: 10px 0;
    background: white;
    cursor: auto;

    label {
      margin-bottom: 4px;
    }

    &:hover {
      border-color: transparent !important;

      .idf-active-state {
        opacity: 0 !important;
      }
    }
  }

  .idf-phone-confirmation {
    .idf-form-item-wrapper {
      margin-bottom: 10px;
      padding: 23px 24px 30px;
    }
  }

  .idf-group {
    padding: 24px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 2px 19px -16px rgba(0,0,0,.9), 0 1px 3px 0 rgba(0,0,0,.1);

    .idf-form-item-wrapper {
      margin-bottom: 10px;
      box-shadow: none;
    }
  }

  .idf-form-fields {
    background: white;
  }

  .idf-conditional,
  .idf-item-id {
    display: none;
  }

  .idf-tab-content {
    display: none;

    &.idf-active {
      display: block;
    }
  }

  .idf-add-wrapper {
    display: none;
  }

  .idf-form-repeating-group > .idf-form-item-wrapper,
  .idf-form-simple-group > .idf-form-item-wrapper {
    padding-bottom: 10px;
  }

  .idf-error-message {
    display: none;
    margin-top: 7px;
    color: $red;
    font-size: 12px;
  }

  .idf-form-group {
    .idf-item-content {
      paper-tabs {
        display: flex !important;
      }
    }
  }

  .iron-selected {
    --paper-tab-content_-_color: #0878CF;
  }

  .idf-text {
    paper-input,
    paper-textarea {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_z-index: 1;
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-phone {
    gold-phone-input {
      --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 23px !important;
      --paper-input-container-input-invalid_-_border: 1px solid #AA2E2E;
      --gold-phone-input-country-code_-_top: 0;
      --paper-input-error_-_letter-spacing: 0.9px;
      min-width: 360px;
      max-width: 360px;
      @media(max-width: 1500px) {
        --gold-phone-input-country-code_-_top: 1px;
      }
      @media(max-width: 576px) {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 23px !important;
      }
    }

    .idf-phone-input {
      &--digit-2 {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 30px !important;
        @media(max-width: 576px) {
          --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 30px !important;
        }
      }

      &--digit-3 {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 38px !important;
        @media(max-width: 576px) {
          --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 38px !important;
        }
      }
    }
  }

  .idf-number {
    paper-input {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_letter-spacing: 0.9px;
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-timed-input {
    paper-input {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_letter-spacing: 0.9px;
      margin-top: 15px;
      min-width: 360px;
      max-width: 360px;
    }

    flip-clock {
      --digits-size: 30px;
    }
  }

  .idf-dropdown {
    vaadin-combo-box {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-date {
    vaadin-date-picker {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-slider {
    paper-slider {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-steps-wrapper {
    top: 50px;
    box-shadow: 0 -6px 0 4px #fff, 0 8px 18px 12px #fff;

    .idf-steps {
      --paper-tab_-_padding: 0 10px;
    }
  }

  .idf-action-row {
    position: sticky;
    top: 20px;
    z-index: 990;
    background: #fff;
    box-shadow: 0 -20px 0 20px #fff;
  }

  .idf-fade {
    opacity: 1;
    transition: all 0.3s;

    &--hidden {
      opacity: 0;
    }
  }
}

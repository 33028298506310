@use "../utilities/index" as *;

.idf-tabs {
  --paper-tabs-selection-bar-color: #2eaa50;
  display: grid;
  grid-template-rows: max-content;

  .idf-tabs-menu {
    border-bottom: 1px solid $darkgray;

    paper-tab {
      @include boldtext();
      color: $darkblue;
    }

    paper-tab:after {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 0;
      height: 2px;
      background: $lightblue;
      content: '';
      transition: width 0.7s $ease;
      -webkit-transition: width 0.7s $ease;
    }

    paper-tab:hover:after {
      left: 0;
      width: 100%;
      background: $lightblue;
    }

    paper-tab.idf-active:after {
      left: 0;
      width: 100%;
      background: $green;
    }
  }

  .idf-tabs-wrapper {
    position: relative;
    height: 100%;
  }

  .idf-tabs-holder {
    position: absolute;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  [data-simplebar].idf-tab-content {
    overflow: auto;
    height: 100%;
  }

  .idf-tab-content {
    position: absolute;
    padding-top: 1rem;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    transform: translateX(100%);
    pointer-events: none;
  }

  .idf-tab-content.idf-active {
    opacity: 1;
    transition: all 0.25s ease;
    transform: translateX(0);
    pointer-events: auto;
  }
}

@use "../utilities/index" as *;

.report {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .idf-report-checkbox{
        display:flex;
        flex-direction: column;
    }
}

.report .checkbox_ {
    margin: 10px;
}

.report .notes {
    margin: 10px;
    margin-top: 10px;
    padding: 5px 8px;
    width: 400px;
    border-width: medium 1px 1px medium;
    border-style: none solid solid none;
    border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
    border-radius: 6px 6px6px 6px;
    border-image: none;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #2196f3;
    font-weight: 500;
    font-size: 16px;
    font-family: monospace;
    transition: background-color 0.2s ease 0s;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
}

.report .notes:focus {
    outline-width: 0;
    background: none repeat scroll 0 0 #ffffff;
}

.report footer {
    padding: 16px 16px 16px 0;
    text-align: right;
}

.next {
    display: inline-block;
    padding: 7px;
    width: 90px;
    border: none;
    border-radius: 4px;
    background-color: #2196f3;
    color: #fff;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.5s;
}


///* FIX FOR VEHICLE PICTURES REPORT FORM *///
.item.next{
    padding:0!important;
}



.next span,
.back span {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: 0.5s;
}

.next span:after {
    position: absolute;
    top: 0;
    right: -20px;
    content: "\00bb";
    opacity: 0;
    transition: 0.5s;
}

.next:hover span {
    padding-right: 20px;
}

.next:hover span:after {
    right: 0;
    opacity: 1;
}

.next:disabled {
    background-color: lightgray;
    color: graytext;
}

.back {
    display: inline-block;
    padding: 7px;
    width: 90px;
    border: none;
    border-radius: 4px;
    background-color: #e7e7e7;
    color: black;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.5s;
}

.back span:after {
    position: absolute;
    top: 0;
    left: -20px;
    content: "\00ab";
    opacity: 0;
    transition: 0.5s;
}

.back:hover span {
    padding-left: 20px;
}

.back:hover span:after {
    left: 0;
    opacity: 1;
}

.report .update-date,
.report .updated-by {
    margin-left: 10px;
}

.report .updated-by label,
.report .updated-by span,
.report .update-date label,
.report .update-date span {
    float: left;
}

.report .updated-by label,
.report .update-date label {
    margin-right: 10px;
    font-size: small;
}

.report .updated-by span,
.report .update-date span {
    font-weight: 600;
}


.idf-isolate {
    div.idf-report{
        padding: 0;
        width: 100%;
        label.checkbox_{
            margin: 0 0 1rem;
            color:$bodytext;
            font-size: .9rem;
            line-height: 1.3rem;

            span.checkmark{
                width: 1.3rem;
                height: 1.3rem;
                border-radius: 0;
                background-color: $darkgray;
            }
            input:checked ~ .checkmark{
                border-radius: 0;
                background-color: $lightblue;
            }
            span.checkmark:after{
                top: .5rem;
                left: .6rem;
                display: block;
                width: 0px;
                height: 0px;
                border: solid white;
                border-width: 0 2px 2px 0;
                opacity: 0;
                transition: all .15s ease, width .3s ease-in-out, height .3s ease-in-out;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            input:checked ~ .checkmark:after{
                top: .2rem;
                left: .5rem;
                width: 4px;
                height: 9px;
                opacity: 1;
                transition: all .15s ease;
            }
            .com-candorgrc-idfusion-gwt-client-v2-ui-Checkbox_CheckboxUiBinderImpl_GenCss_style-title{
                padding: 0 .7rem;
            }
        }
        textarea.notes{
            @include bodytext();
            margin: 0;
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            padding: .6rem;
            width: 100%;
            border: none;
            border-radius: 0;
            background: $darkgray!important;
            box-shadow: none;
            line-height: 1.5;
            resize: vertical;

            &:focus{
                background: $darkgray!important;
            }
        }
        .updated-by, .update-date{
            margin-bottom: 3px;
            margin-left: 0;
            label{
                margin: 0 0.5rem 0 0;

            }
        }
    }
}

@use "../../utilities/index" as *;

.contextual-toolbar:focus {
  outline: 0;
}

.contextual-toolbar * {
  user-select: none;
  vertical-align: middle;
  color: #666666;
}

.contextual-toolbar {
  position: absolute;
  display: block;
  opacity: 0;
  user-select: none;
  background-color: #f7f7f7;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition: opacity 0.2s ease-out, width 0.2s ease-out;
}

#node-ui {
  display: inline-block;
}

.picker-container {
  position: absolute;
  background-color: #f7f7f7;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  opacity: 0;
  display: none;
  transition: opacity 0.2s ease-out;
  padding: 5px;
  width: 128px;
  text-align: center;
}

.idf-graph-tools .has-tooltip:hover > .idf-tooltip {
  opacity: 1;
  transform: translateY(7px) translateX(0px);
  transition: all 0.3s $ease;
  display: inline-block;
}
.has-dropdown.idf-dropdown-menu--open:hover > .idf-tooltip {
  display: none;
}
.idf-graph-tools .idf-tooltip {
  position: absolute;
  left: 65px;
  transform: translateY(7px) translateX(-20px);
 // border: 1px solid $darkgray;
  background-color: $lightblue;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.04);
  width: auto;
  white-space: nowrap;
  padding: 5px 16px;
  margin: 0;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s $ease, opacity .1s ease;
  font-size: 13px;
  color: white;
  letter-spacing: 0.3px;
  &:after{
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent $lightblue transparent transparent;
  }
}

.idf-graph-tools {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid $darkgray;
  border-radius: 4px;
  margin: 30px;
  @include box-shadow;
}

.idf-graph-tools i {
  width: 35px;
  height: 45px;
  display: block;
  background-position: center;
  background-size: 55%;
  background-repeat: no-repeat;
  transition: all .3s $ease;
}

.idf-graph-tools button {
  -webkit-appearance: none;
  border: none;
  background: transparent;
  border-bottom: 1px solid $darkgray;
  display: block;
  transition: all 0.2s ease;
  position: relative;
  padding: 0 6px;
}

.idf-graph-tools button:hover,
.idf-graph-tools button.idf-dropdown-item--active {
  background: rgba(0, 0, 0, 0.03);
}

.idf-graph-tools button:active {
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.idf-gt-zoom-in {
  background-image: url("../img/graph/tools/zoom-in.svg");
}

.idf-gt-zoom-out {
  background-image: url("../img/graph/tools/zoom-out.svg");
}

.idf-gt-fit-content {
  background-image: url("../img/graph/tools/fit-content.svg");
}

.idf-gt-snapshoot {
  background-image: url("../img/graph/tools/take-graph-shapshoot.svg");
}

.idf-gt-tag{
  background-image: url("../img/graph/tools/tag.svg");
}
.idf-btn.idf-active .idf-gt-tag{
  background-image: url("../img/graph/tools/tag-active.svg");
}

.idf-graph-tools .selection > button .idf-icon {
  background-image: url("../img/graph/tools/change-bulk-selection-model.svg");
}

.idf-graph-tools .centrality > button .idf-icon {
  background-image: url("../img/graph/tools/change-graph-layout-algorithm.svg");
}

.idf-graph-tools .context-actions > button .idf-icon {
  background-image: url("../img/graph/tools/contextual-menu.svg");
}

.idf-dropdown-menu {
  display: none;
  position: absolute;
  left: 52px;
  transform: translateY(-49px);
  border: 1px solid $darkgray;
  background-color: $gray;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
}

.idf-dropdown-menu.idf-dropdown-menu--open {
  display: block;
}

.idf-graph-tools .idf-dropdown .fa-angle-down {
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  transform: rotate(-45deg);
  opacity: 0.2;
  bottom: -2px;
}

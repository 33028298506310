@use "../../utilities/index" as *;
$dashGap: 20px;

.idf-dashboard {
  display: flex;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;

    aside {
      margin: 30px 0 0;
      width: 100%;
    }

    .idf-stat {
      min-height: 20vw!important;
    }
  }
  @media only screen and (max-width: 650px) {
    .idf-col-2,
    .idf-col-3 {
      width: 100%;
    }

    .idf-stat {
      min-height: 37vw!important;
    }
  }
  // height: 100%;
  &--center {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  main {
    display: flex;
    flex: 1;
    flex-flow: wrap;
    align-content: flex-start;
    align-items: flex-start;
    gap: $dashGap;
  }

  aside {
    overflow: auto!important;
    margin-left: $dashGap;
    padding: 0 20px;
    width: 34vw;
    height: calc(100% - 100px);
    background: white;

    .idf-top-connected-summary {
      margin-top: 20px;
    }
  }

  .idf-col-3 {
    width: calc(1/3*100% - (1 - 1/3) * #{$dashGap});
  }

  .idf-col-2 {
    width: calc(50% - (1/2 * #{$dashGap}));
  }

  .idf-col-1 {
    width: 100%;
  }

  &--empty {
    .idf-dashboard-toolbox:after {
      right: 9px;
    }
  }
}

.idf-dashboard-search {
  //Table Columns
  $columns: 30% 45% 15% 15% 37px;
  @include bigTable($columns);
}

.idf-slot{
  height: 100%;
}

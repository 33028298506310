@use "sass:color";
@use "../../utilities/index" as *;

.idf-global-search.idf-main {
  .idf-recent-list {
    margin-right: 20px;
    padding: 30px;
    width: 100%;
  }

  .idf-scroll-wrapper {
    margin-top: 0;
    height: 100%;
  }

  .idf-search-filters vaadin-combo-box {
    margin-top: 20px;
  }
  //result item
  .idf-search-item {
    $iconSize: 24px;
    $iconSpace: 70px;
    $sidePadding: calc($iconSpace / 2) - calc($iconSize / 2);
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-bottom: 6px;
    padding: 0;
    height: 60px;
    border-bottom: 1px solid $gray;
    cursor: pointer;

    &:before {
      position: absolute;
      top: calc(50% - #{$iconSize} / 2);
      left: 0;
      width: $iconSize;
      height: $iconSize;
      background-position: center;
      background-size: $iconSize;
      background-repeat: no-repeat;
      content: '';
    }
    //Icon fo every Type
    &.idf-policy:before {
      background-image: url("../img/graph/node-type/policy.svg");
    }

    &.idf-address:before {
      background-image: url("../img/graph/node-type/address.svg");
    }

    &.idf-person:before {
      background-image: url("../img/graph/node-type/person.svg");
    }

    &.idf-vehicle:before {
      background-image: url("../img/graph/node-type/vehicle.svg");
    }

    .idf-content {
      @include bigboldtext();
      margin-left: 35px;
    }

    .idf-sub {
      @include bodytext();
      display: block;
      margin-top: 6px;
    }

    .idf-right {
      position: absolute;
      top: 18px;
      right: 0;
      transition: opacity 0.2s ease-out;
    }

    .idf-hover-msg {
      position: absolute;
      right: 0;
      opacity: 0;
      transition: opacity 0.2s ease-out;
      pointer-events: none;
    }

    &:hover {
      .idf-hover-msg {
        opacity: 1;
        pointer-events: all;
      }

      .idf-right {
        opacity: 0;
        transition-delay: 0.0s;
      }
    }
    //Item Title
    .idf-important {
      font-size: 1.1rem;
      line-height: 1.3;
    }
  }

  .idf-search-filters vaadin-combo-box {
    width: 100%;
  }
}

.idf-saved-search-list {
  .idf-separator {
    margin-top: 45px!important;
    margin-bottom: 8px!important;
  }

  .idf-saved-item {
    position: relative;
    display: flex;
    align-content: center;
    margin-bottom: 2px;
    padding: 5px 0;
    background: transparent;
    color: color.adjust($darkblue, $lightness: 2%);
    font-weight: 600;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.2s ease-out;

    &:before {
      display: inline-block;
      margin-right: 3px;
      width: 15px;
      height: 13px;
      background-image: url("../img/magnifier.svg");
      background-position: center;
      background-size: 13px;
      background-repeat: no-repeat;
      content: '';
      opacity: 0.7;
    }

    &:hover {
      color: $darkblue;
      opacity: 1;
    }
  }
}

@use "utilities/index" as *; // Extra css

.graphComponent {
  position: relative;
  height: calc(100% - 214px);
  transition: all 0.15s ease;
}

.idf-graph-wrapper {
  overflow: hidden;
  height: inherit!important;
  border: 1px solid $darkgray;
  background-color: white;
}

.graphComponent--extended {
  height: calc(100% - 42px) !important;
}

#timelineComponent {
  padding-top: 10px;
  height: 180px !important;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}

.demo-toggle-button.labeled:disabled + label {
  opacity: 0.5;
  cursor: default;
}

.demo-toggle-button.labeled:hover + label {
  background: none;
}

.time-frame-rect {
  fill: lightskyblue;
  stroke: skyblue;
  stroke-width: 3;
  stroke-opacity: 0.3;
  fill-opacity: 0.3;
}

label {
  margin: 3px 2px 0 0;
  vertical-align: middle;
}

div.row {
  clear: both;
  width: 200px;
}

.customCursor {
  cursor: pointer !important;
}

.yfiles-canvascomponent,
.yfiles-svgpanel {
  position: relative;
}

.yfiles-canvascomponent {
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-touch-callout: none;
}

.yfiles-canvascomponent .yfiles-svgpanel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.yfiles-resize-sensor .yfiles-resize-sensor-expand {
  animation-name: yfiles-dom-sensor-inserted;
  animation-duration: 0.001s;
}
@keyframes yfiles-dom-sensor-inserted {
  from {
    opacity: 0.99;
  }

  to {
    opacity: 1;
  }
}
/* Layout the scrollbars */
.yfiles-canvascomponent .yfiles-scrollbar div {
  overflow: hidden;
}

.yfiles-canvascomponent .yfiles-scrollbar-content {
  cursor: default;
}

.yfiles-canvascomponent .yfiles-scrollbar > div {
  position: absolute;
}

.yfiles-canvascomponent .yfiles-scrollbar .yfiles-scrollbar-range-content,
.yfiles-canvascomponent .yfiles-scrollbar .yfiles-scrollbar-slider-content {
  /* set maximum size in both dimension and override for specific elements if required */
  width: 100%;
  height: 100%;
}

.yfiles-canvascomponent .yfiles-scrollbar .yfiles-button {
  position: absolute;
  width: 15px;
  height: 15px;
  border: none;
  border-radius: 0;
  color: #000;
  vertical-align: middle;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
}

.yfiles-canvascomponent .yfiles-scrollbar .yfiles-button-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.yfiles-canvascomponent .yfiles-scrollbar .yfiles-button-right {
  top: 0;
  right: 0;
  bottom: 0;
}

.yfiles-canvascomponent .yfiles-scrollbar .yfiles-button-up {
  top: 0;
  right: 0;
  left: 0;
}

.yfiles-canvascomponent .yfiles-scrollbar .yfiles-button-down {
  right: 0;
  bottom: 0;
  left: 0;
}
/* Misc styling */

.yfiles-canvascomponent,
.yfiles-canvascomponent-svg {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.yfiles-canvascomponent-svg {
  display: block;
  width: 100%;
  height: 100%;
}

.yfiles-dropshadow-image {
  pointer-events: none;
}

.yfiles-canvascomponent .yfiles-labeleditbox-container {
  padding: 2px;
  border: 1px solid black;
  background-color: white;
}

.yfiles-labeleditbox-container-enter {
  opacity: 0;
}

.yfiles-labeleditbox-container-enter-to {
  opacity: 1;
}

.yfiles-labeleditbox-container-entering {
  transition: opacity 0.1s ease-in;
}

.yfiles-labeleditbox-container-leave {
  opacity: 1;
}

.yfiles-labeleditbox-container-leave-to {
  opacity: 0;
}

.yfiles-labeleditbox-container-leaving {
  transition: opacity 0.1s ease-out;
}

.yfiles-canvascomponent .yfiles-labeleditbox-container:focus {
  outline: none;
}

.yfiles-canvascomponent .yfiles-labeleditbox {
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: 0 none;
  background-color: transparent;
  text-decoration: none;
  text-transform: none;
  white-space: pre;
  word-spacing: 0;
  letter-spacing: normal;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  font-size: 10pt;
  font-family: sans-serif;
  line-height: normal;
  /* box model is crucial for measuring */
  resize: none;
}

.yfiles-collapsebutton {
  cursor: pointer;
}

.yfiles-overlaypanel {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.yfiles-canvascomponent ::-webkit-scrollbar {
  -webkit-appearance: none;
}

.yfiles-canvascomponent,
.yfiles-canvascomponent .yfiles-labeleditbox-container,
.yfiles-tooltip {
  line-height: 1;
}
/* NEW GRAPH CSS - GENERAL */

#nodePopupContent:focus,
.idf-graph-scene-tree summary:focus,
.idf-graph-tools button:focus {
  outline: none;
}

.idf-graph-tools details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}
@keyframes sweep {
  0% {
    margin-left: -10px;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

summary {
  outline: none;
}

@use "../../utilities/index" as *;

.idf-rule-view {
  //general info
  .idf-row {
    align-items: flex-start;
    justify-content: space-between;

    .idf-actions {
      margin-top: -10px;
      margin-bottom: 5px;
    }
  }

  .idf-darkblue.idf-important {
    margin-top: 35px;
  }

  .idf-rule-data {
    margin-bottom: 20px;

    .idf-darkblue.idf-important {
      margin-top: 5px;
    }

    p {
      word-wrap: break-word;
    }
  }

  .idf-check-list {
    display: flex;
    flex-wrap: wrap;
    padding-right: 50px;
    height: auto;

    paper-checkbox {
      flex: 50%;
      margin-bottom: 15px;
      width: 50%;
      cursor: default;
      pointer-events: none;
    }
  }

  .idf-list-details {
    margin-bottom: 15px;

    .idf-list-detail {
      display: block;
      margin-bottom: 12px;

      label {
        display: inline;
      }
    }
  }

  section > .idf-bold-text.idf-darkblue {
    margin-top: 35px;
    margin-bottom: 7px;
    border-bottom: 1px solid $bluegray;
    line-height: 1.6;
  }

  .idf-card.idf-readonly {
    padding: 0;
    border: none;

    .idf-dc-list {
      & > p {
        display: none;
      }
    }
  }

  .idf-phone-confirmation {
    .idf-form-item-wrapper {
      margin-bottom: 10px;
      padding: 23px 24px 30px;
    }
  }

  .idf-group {
    .idf-form-item-wrapper {
      margin-bottom: 10px;
      padding: 23px 24px 30px;
    }
  }

  .idf-form-fields {
    padding: 15px 0 15px 10px;
    background: white;
  }
}

@use "../utilities/index" as *;

.idf-workflow-history-item {
  display: flex;
  align-items: center;
  margin: 15px 0;

  p {
    margin: 6px;
    line-height: 1rem;
  }

  .idf-remarks {
    font-style: italic;
  }

  &.idf-set-border {
    border: 2px solid $lightblue;
    border-radius: 6px;
  }
}

@use "../../utilities/index" as *;

.idf-passive-monitor {
  .idf-all-caps-label {
    margin-bottom: 10px;
  }

  .idf-card-content {
    display: flex;
    flex-flow: wrap;
    gap: 7px 0;

    & > * {
      margin-right: 20px;
      margin-bottom: 9px;
      min-width: 20px;
    }

    .idf-input-small {
      max-width: 314px;
      width: 100%;
    }
  }

  .idf-filters {
    footer {
      margin-top: 11px;
      font-size: 0.8rem;
    }
  }

  .idf-flex-table.idf-header {
    margin-top: 15px;
  }

  .idf-table-content {
    padding-bottom: 50px;
  }
}

@use "../utilities/index" as *;

iron-overlay-backdrop {
  z-index: 999;
}

.idf-modal {
  right: 0 !important;
  left: 0 !important;
  z-index: 1001 !important;
  margin: 0 auto;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-width: calc(100% - 30px);
  width: 1200px !important;
  border-radius: 4px;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;

  &--wide {
    width: 1360px !important;
  }

  &--wider {
    width: 1560px !important;
  }

  &--fixed {
    position: fixed;
    top: 100px !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    padding: 15px;
    border-bottom: 1px solid $darkgray;
  }

  &__header-left {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    color: $darkblue;
    font-weight: 600;

    &--big {
      display: block;
      margin-bottom: 30px;
      font-size: 1.2rem;
    }
  }

  &__close {
    width: 28px;
    height: 28px;
    border: none;
    border-radius: 50%;
    background: none;
    color: $darkblue;
    text-align: center;
    font-size: 22px;
    line-height: 1;
    transition: all 0.3s;

    .bi {
      position: relative;
      top: -1px;
      vertical-align: middle;
    }

    &:hover {
      color: $orange;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    padding: 3px 15px 0;
    border-bottom: 1px solid $darkgray;

    .idf-dashboard-toolbox-list__button {
      position: relative;
      bottom: -1px;
      z-index: 2;
      padding: 20px 0;
      width: auto;
      transition: all 0.3s;

      &.is-active,
      &:hover {
        border-color: $orange;
      }
    }

    paper-input {
      --paper-font-caption_-_line-height: 0 !important;
      width: auto !important;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__body {
    display: block;
    overflow-y: auto;
    margin-top: 15px;
    padding: 15px;
    max-height: 70vh;

    &--aside {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 200px;
        height: calc(100% - 59px);
        background: #f3f3f4;
        content: '';
      }
    }

    &--noPadding {
      margin: 0;
      padding: 0;
    }
  }

  &__settings {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  &__aside {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0 15px 15px;
    width: 140px;
    border-bottom-left-radius: 4px;
    background: #F3F3F4;

    &--wide {
      position: sticky;
      top: 0;
      padding: 0;
      width: 200px;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 200px;
        height: 15px;
        background: #f3f3f4;
        content: '';
      }

      .idf-input {
        --paper-input-container-input_-_background: #fff !important;
        margin-bottom: 15px;
        width: calc(100% - 24px) !important;
      }

      .idf-modal__navigation {
        flex-wrap: wrap;
        padding-left: 10px;

        & > div {
          margin-right: 0;
          width: 100%;
        }

        .idf-dashboard-toolbox-list__button {
          @extend .idf-modal__button;
          padding: 10px 5px !important;
          background: none;
          transition: all 0.3s !important;

          &.is-active {
            background: #fff;
          }
        }
      }
    }
  }

  &__content {
    margin: 0;
    padding: 25px 15px;
    width: calc(100% - 140px);

    &--narrow {
      width: calc(100% - 200px);
    }

    &--full {
      width: 100%;
    }

    &--noPadding {
      padding: 0;
    }
  }

  &__button {
    position: relative;
    margin-bottom: 12px;
    padding: 14px 5px;
    width: 100%;
    border: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background: none;
    color: $darkblue;
    text-align: center;
    transition: all 0.3s;

    &:before {
      position: absolute;
      top: -12px;
      right: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #F3F3F4;
      box-shadow: 4px 4px 0 0 transparent;
      content: '';
      transition: all 0.3s;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: -12px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #F3F3F4;
      box-shadow: 4px -4px 0 0 transparent;
      content: '';
      transition: all 0.3s;
    }

    svg {
      margin-bottom: 4px;
      width: 24px;
      height: 24px;
    }

    strong {
      display: block;
      font-weight: 600;
    }

    &:hover {
      color: $orange;
    }

    &.is-active {
      background: #fff;
      color: $orange;

      &:before {
        box-shadow: 4px 4px 0 0 #fff;
      }

      &:after {
        box-shadow: 4px -4px 0 0 #fff;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 15px;

    &--noPadding {
      padding: 0;
    }

    .idf-btn {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.modalPanelFixed {
  z-index: 999;
}

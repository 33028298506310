@use "../../utilities/index" as *;

.idf-written-premium {
  $columns: 19% 25% 18% 24% 24% 24%;
  @include fixTable($columns);

  .idf-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 25px;
  }
}

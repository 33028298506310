.idf-timed-input {
  margin-top: 15px;

  .idf-btn {
    margin-top: 20px;
    margin-left: 0;

    &[disabled] {
      opacity: 0.3;
    }
  }

  flip-clock {
    --digits-size:40px;
    --clock-display: flex;
    --clock-align: center;
    --clock-justify: flex-start;
    --digits-opacity: 0;
    --box-shadow: none;
    line-height: 1.5;
  }
}

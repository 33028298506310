@use "../../utilities/index" as *;

.idf-risk-detection-search {
  $columns: 1fr 300px 300px 300px 75px;
  @include bigTable($columns);

  .idf-analyzer-list > div > div:nth-child(1),
  .idf-analyzer-list > div > div:nth-child(2) {
    .idf-contextual-menu {
      top: 0.7em!important;
      bottom: auto!important;
    }
  }
}

@use "../../utilities/index" as *;

.idf-aside-content .idf-history-policy {
  height: inherit;

  .idf-top-details .idf-title-wrapper .idf-title {
    margin: 0;
  }

  .idf-drivers-data {
    $columns: 35% 30% 22% 22%;
    @include mediumTable($columns);
  }

  .idf-vehicles-data {
    $columns: 35% 30% 22% 22%;
    @include mediumTable($columns);
  }

  .idf-drivers-data .idf-c-3 {
    display: block!important;
  }
}

/**
* Author: ercangvn
criteriaTest
*/
@use "utilities/index" as *;

.idf-rule-generator {
  &__header {
    display: inline-flex;
    align-items: center;
    padding: 4px 15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $darkblue;
    color: #fff;

    vaadin-combo-box {
      --lumo-contrast-10pct: rgba(0,0,0, .05);
      margin: 0 8px;
    }
  }

  &__top {
    padding: 15px;
    border: 1px solid $bluegray;
    border-top-right-radius: 6px;
    background-color: rgba($darkblue, .04);
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 8px 0;

    &.has-after {
      .idf-rule-generator__add {
        display: none;
      }

      .idf-criteria-badge {
        &:before {
          height: calc(100% + 4px);
        }

        &:after {
          border-bottom-color: transparent;
        }
      }
    }

    &.has-before {
      .idf-criteria-badge {
        &:before {
          top: -4px;
          height: calc(100% + 4px);
        }

        &:after {
          border-top-color: transparent;
        }
      }
    }
  }

  &__add {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    border-radius: 32px;
    background: none;
    color: rgba($darkblue, .7);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: rgba($orange, .1);
      color: $orange;
    }
  }

  &__list {
    padding: 15px;
    border: 1px solid $bluegray;
    border-top: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #fff;
  }
}

.idf-rule-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &__badge {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__inputs {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 8px;
    padding: 4px;
    border: 1px solid $bluegray;
    border-radius: 4px;

    vaadin-combo-box {
      &:first-child {
        min-width: 195px;
      }
    }

    & > * {
      margin-left: 4px;

      &:first-child {
        margin-left: 0 !important;
      }
    }
  }

  &__delete {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: $bluegray;
    color: rgba($darkblue, .7);
    cursor: pointer;
    transition: all 0.3s;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      background-color: $orange;
      color: #fff;
    }
  }

  .idf-criteria-value:empty {
    display: none;
  }
}

.idf-criteria-badge {
  padding: 4px 0;
  width: 40px;
  border-radius: 32px;
  background-color: $darkblue;
  color: #fff;
  font-weight: bold;
  font-size: 10px;

  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    width: 3px;
    height: 100%;
    background-color: $darkblue;
    content: '';
    transform: translateX(-50%);
  }

  &:after {
    position: absolute;
    top: 0;
    left: calc(50% + 4px);
    z-index: 1;
    width: 6px;
    height: calc(100% - 6px);
    border-top: 3px solid $darkblue;
    border-bottom: 3px solid $darkblue;
    background-color: transparent;
    content: '';
    transform: translateX(-50%);
  }

  &__label {
    position: relative;
    z-index: 4;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 1;
  }

  &--if {
    background-color: $blue;

    &:before {
      background-color: $blue;
    }

    &:after {
      border-color: $blue;
    }
  }

  &--or {
    background-color: $orange;

    &:before {
      background-color: $orange;
    }

    &:after {
      border-color: $orange;
    }
  }

  &--then {
    background-color: #8FBCE3;

    &:before {
      background-color: #8FBCE3;
    }

    &:after {
      border-color: #8FBCE3;
    }
  }

  &--else {
    background-color: $teal;

    &:before {
      background-color: $teal;
    }

    &:after {
      border-color: $teal;
    }
  }

  &--self {
    background-color: $legacy;

    &:before {
      background-color: $legacy;
    }

    &:after {
      border-color: $legacy;
    }
  }
}

.idf-aside-panel {
  .idf-rule-generator {
    &__top {
      padding: 10px;
    }

    &__content {
      padding: 10px;
    }
  }

  .idf-criteria {
    align-items: flex-start;
    width: calc(100% - 40px);
  }

  .idf-criteria-content {
    flex-wrap: wrap;
    width: calc(100% - 40px);

    vaadin-combo-box {
      max-width: 160px !important;
    }
  }

  .idf-criteria-value {
    width: auto !important;

    multiselect-combo-box {
      min-width: 180px !important;
    }
  }
}

.idf-readonly {
  .idf-rule-generator__top {
    display: none;
  }

  .idf-rule-generator__content {
    padding: 10px !important;
    border-top: 1px solid #dfe6ec !important;
    border-radius: 6px;
  }

  multiselect-combo-box {
    --lumo-space-s: 13px;
  }

  .idf-rule-builder-main {
    .idf-group-content {
      .idf-group-content {
        width: auto !important;
      }
    }
  }
}

.idf-aside-panel {
  .idf-readonly {
    .idf-criteria-row .idf-criteria {
      width: 100% !important;
    }

    .idf-criteria-content {
      flex-wrap: nowrap;
      width: 100%;
    }

    multiselect-combo-box {
      --lumo-space-s: 8px;
    }

    .idf-rule-generator__content {
      padding-bottom: 6px !important;
    }
  }
}
// html {
// 	.idf-rule-generator {
// 		--lumo-border-radius: 32px;
//
// 		paper-input{
// 			--paper-input-container-input_-_border-radius: 32px !important;
// 		}
// 	}
// }

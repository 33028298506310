@use "../../utilities/index" as *;

.idf-compare-slot{
    width: 0;
    right: 0;
    position: absolute;
    top: 0;
    background: white;
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;


    &.idf-active {
      width: 55%;
      border-left:1px solid $darkgray;
      .idf-clear{
        opacity: 1;
        pointer-events: auto;
      }
    }
    .idf-clear{
      opacity: 0;
      pointer-events: none;
      @include btn-medium-light();
    }
    .idf-graph-compare-list{
      padding: 1rem 1rem 3rem;
      width: 100%;
      min-width: 50px;

      .cellListEvenItem, .cellListOddItem {
        padding-top:2.5rem;
        position:relative;
        transform: translateX(0);
        opacity: 1;
        animation: listadd .2s $ease;

        .idf-remove{
          opacity:0;
          position:absolute;
          top:0;
          pointer-events: none;
          @include btn-samll-dark();
          margin-top:1.5rem;
          margin-left:1rem;
          z-index: -1;
        }

        &:hover{
          .idf-remove{
            z-index: 1;
            margin-top:0rem;
            opacity: 1;
            pointer-events: auto;
          }
        }
      }

      div{
        display: inline-block;
        white-space: nowrap;
      }
      ul{
        @include reset-list(block);
        display: inline-block;
        &.idf-compare-label{
          display:none;
        }
      }
      li {
        @include boldtext();
        padding:.6rem 1rem;
        &:before {
          content: "\00a0";
      }

        &:nth-child(2n-1){
          background-color: $gray;
        }
      }

    }
    .idf-graph-compare-list .cellListEvenItem:nth-child(1) {
      ul.idf-compare-label{
        display: inline-block;

        li{
          font-weight: 500;
          line-height:1
        }
      }
    }

  }
  @keyframes listadd {
    from{
      transform: translateX(10%);
    }
  }

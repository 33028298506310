@use "../../utilities/index" as *;

.idf-impact-calculator-wrapper {

  .idf-impact-calculator {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
    border-radius: 4px;

    &__top {
      margin-bottom: 20px;
      width: 100%;

      .titleLabel {
        float: none;
        margin: 0;
      }
    }

    &__filters {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }

    &__table {
      width: calc(100% - 380px);

      table {
        overflow: hidden;
        width: 100%;
        border-radius: 6px;
        text-align: center;
      }

      td,
      th {
        padding: 12px 8px;
        border: 1px solid rgba(#000, .1);
      }

      thead {
        text-shadow: none !important;

        tr {
          th {
            background: $darkblue;
            color: #fff;
            text-shadow: none !important;

            &:first-child {
              width: 160px;
            }
          }

          &:last-child {
            th {
              background: $lightblue;
            }
          }
        }
      }

      tbody {
        color: $darkblue;

        tr {
          &:nth-of-type(even) {
            td {
              background: rgba($flatblue, .2);

              &:first-child {
                width: 160px;
              }
            }
          }

          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 3px;
              }

              &:last-child {
                border-bottom-right-radius: 3px;
              }
            }
          }
        }
      }
    }
  }

  .idf-stat-box {
    padding: 15px;
    width: 350px;
    border-radius: 6px;
    background: $darkblue;
    color: #fff;

    &__bg {
      padding: 30px 15px;
      border-radius: 6px;
      background: radial-gradient(100% 100% at 50.18% 100%, #FF8A00 0%, #CE4D16 100%);
      text-align: center;
    }

    &__title {
      display: block;
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 1rem;
    }

    &__number {
      display: block;
      font-weight: bold;
      font-size: 4rem;
      line-height: 50px;

      small {
        display: block;
        font-weight: 300;
        font-size: 50%;
      }
    }

    &__list {
      margin: 30px 0 0;
      padding-left: 0;
      list-style-type: none;

      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__item {
      width: calc(100% - 85px);
    }

    &__result {
      width: 70px;
      text-align: right;
      font-weight: bold;
      font-size: 1.1rem;
    }
  }
}

@use "../../utilities/index" as *;

.idf-uw-review-search {
  .idf-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;

    .idf-filters-wrapper {
      display: flex;
      flex: 1;

      & > * {
        margin-right: 20px;
        min-width: 20px;
        max-width: 200px;
        width: 100%;
      }

      @media only screen and (max-width: 1270px) {
        flex-wrap: wrap;
        margin-bottom: -10px;

        & > * {
          margin-bottom: 10px;
          max-width: 100%;
          width: calc(25% - 20px);
        }
      }
    }

    .idf-row {
      align-items: flex-start;
      margin-top: 13px;
    }
  }

  .idf-watchlist {
    transition: height 0.3s ease;
    $columns: 7% 6.8% 1fr 7.5% 8% 10% 9% 10% 10% 9% 75px;
    @include bigTable($columns);
    @include filterVisible(95px, 150px);
    //active item
    .idf-focus .idf-flex-table.idf-row {
      border: 2px solid $blue !important;
      transition: border-color 0.4s ease-in-out;
    }

    .idf-header {
      @extend .sticky-table-header;
    }

    .idf-flex-table {
      @media screen and (max-width: 1550px) {
        .idf-c-9 {
          display: none!important;
        }
      }
      @media screen and (max-width: 1380px) {
        .idf-c-7 {
          display: none!important;
        }
      }

      &.idf-row {
        border: 2px solid transparent;

        &:hover {
          border: 2px solid $flatblue;
          cursor: pointer;
          transition: border-color 0.4s ease-in-out;
        }
      }

      .idf-action {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-end;
        padding-right: 5px !important;
        -ms-flex-align: center;
      }
    }
  }
}

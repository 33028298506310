@use "utilities/index" as *;

.idf-imageanalysis {
  display: grid;
  align-items: stretch;
  overflow: hidden;
  height: inherit;
  grid-template-columns: minmax(0, 9fr) minmax(0, 32fr) minmax(0, 27fr) 4rem;
  -ms-grid-columns: minmax(0, 9fr) minmax(0, 32fr) minmax(0, 27fr) 5rem;
  grid-template-rows: calc(100vh - 121px);
  grid-column-gap: 1.5rem;
  grid-row-gap: 0;
  justify-items: stretch;
  @include lg {
    grid-template-columns: minmax(0, 8fr) minmax(0, 39fr) minmax(0, 23fr) minmax(0, 16fr);
  }
  @include md {
    grid-template-columns: minmax(0, 9fr) minmax(0, 32fr) minmax(0, 27fr) 4rem;
  }
  @media(max-width: 1700px) {
    .idf-vehicle-data-history {
      .idf-icon {
        margin-right: 0;
      }

      .idf-number {
        display: none;
      }

      .idf-date {
        display: none;
      }

      .cellListEvenItem,
      .cellListOddItem {
        border: none;
      }
    }
  }

  .idf-tabs {
    padding: 0 1rem 1rem;

    .idf-tabs-holder {
      position: relative;
    }
  }

  .idf-map {
    margin-top: 2rem;
    height: 300px;
  }
}

.idf-imageanalysis > div {
  overflow: initial;
  padding: 1rem;
  border: 1px solid $bluegray;
  border-radius: 6px;
  background: white;
}

.idf-vehicle-thumb {
  .vehicle-thumbnails > div > div.cellListSelectedItem {
    opacity: 1;
  }

  .vehicle-thumbnails > div > div {
    opacity: 0.35;
    transition: all 0.35s ease;
  }

  .thumbnail {
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;

    img {
      max-width: 100% !important;
    }

    p {
      @include allcapslabel();
      background: $legacy;
      color: white;
      text-align: center;
    }

    p.current {
      background: $blue;
    }
  }
}

.idf-vehicle-pic {
  canvas {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.zoomContainer {
  pointer-events: none;
}

.idf-image-filters {
  .idf-filter {
    padding-bottom: 1.4rem;
    @include lg {
      padding-top: 0.3rem;
    }
    @include md {
      padding-bottom: 0.7rem;
    }

    p {
      color: $darkblue;
      text-transform: capitalize;
    }
  }

  .idf-range-value {
    @include boldtext();
    float: right;
  }
}

.idf-imageanalysis .annotations {
  .cellListEvenItem .cell {
    background: $gray;
  }

  .cell {
    padding-right: 1rem;
  }

  img {
    padding-right: 0.5rem;
    width: 1rem;
  }

  span {
    line-height: 2rem;
  }

  .secondary {
    float: right;
  }
}

.idf-imageanalysis {
    .idf-car-details {
        padding-bottom: 0.3rem;
        label {
            display: inline;
            cursor: default;
            line-height: 1;
            vertical-align: bottom;
        }
        span {
            @include boldtext();
        }
    }
}

.idf-vehicle-data-history {
  .idf-icon {
    display: inline-block;
    margin-right: 0.7rem;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: no-repeat center;
    background-size: 60%;
    vertical-align: middle;

    &.legacy {
      background-color: $legacy;
    }

    &.current {
      background-color: $blue;
    }

    &.Quote {
      background-image: url("../img/graph/node-type/white/quote.svg");
    }

    &.Policy {
      background-image: url("../img/graph/node-type/white/policy.svg");
    }
  }

  .cellListEvenItem,
  .cellListOddItem {
    border-bottom: 1px solid $gray;
    cursor: default;
    transition: all 0.3s ease;

    .vehicle-history {
      position: relative;
      padding: 0.5rem 0;

      &:after {
        position: absolute;
        bottom: 0;
        left: -1rem;
        display: block;
        width: 3px;
        height: 0;
        background: $lightblue;
        content: "";
        transition: height 0.3s ease;
      }

      &.infocus:after,
      &:hover:after {
        top: 0;
        height: 100%;
      }

      &.infocus:after {
        background: $green;
      }

      &:hover {
        opacity: 0.4;
        transition: all 0.3s ease;
      }

      &.navigate:hover {
        opacity: 1;
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }

    span {
      line-height: 2rem;
    }

    .idf-number {
      @include boldtext();
    }

    .idf-date {
      float: right;
    }
  }
}

.idf-damage-list {
  .idf-damage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 7px;
    border-radius: 2px;
    background-color: $gray;

    .idf-number {
      position: relative;
      padding: 2px 4px;
      font-weight: 600;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        background: currentColor;
        content: "";
        opacity: 0.2;
      }
    }
  }

  .cellListOddItem .idf-damage {
    background: white;
  }
}

.damage-btn {
  margin-bottom: 20px;
}

.damage-disable {
  pointer-events: none;
}

@use "../utilities/index" as *;

.idf-recent-search-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;

  .idf-recent-search {
    margin-top: 3px;
    padding: 4px;
    max-height: 315px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(9, 30, 66, 0.2) 0 4px 15px, rgba(9, 30, 66, 0.3) 0 0 2px;
    @media screen and (max-height: 780px) {
      max-height: 300px;
    }

    .idf-recent-search-list {
      display: flex;
      flex-direction: column;
    }

    .idf-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1px;
      padding: 8px 10px;
      border-radius: 3px;
      background-color: transparent;
      color: $darkblue;
      line-height: 0.9;
      transition: background-color 0.3s ease-out;

      .idf-all-caps-label {
        margin-left: 13px;
        opacity: 0.6;

        .idf-hover-msg,
        .idf-key-msg {
          display: none;
        }
      }

      &:hover {
        background-color: $bluegray;
        cursor: pointer;

        .idf-all-caps-label {
          .idf-date,
          .idf-key-msg {
            display: none!important;
          }

          .idf-hover-msg {
            display: block!important;
          }
        }
      }
    }

    .cellListKeyboardSelectedItem .idf-row {
      background-color: $bluegray;

      .idf-all-caps-label {
        .idf-date,
        .idf-hover-msg {
          display: none;
        }

        .idf-key-msg {
          display: block;
        }
      }
    }

    .idf-warning {
      display: flex;
      margin-bottom: 1px;
      padding: 8px 10px;
      border-radius: 3px;
      background-color: $bluegray;
      color: $darkblue;
      line-height: 0.9;
    }
  }
}

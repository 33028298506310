@use "../../utilities/index" as *;

.idf-content-panel .idf-manage-cases-search {
  paper-dropdown-menu {
    vertical-align: bottom;
  }

  .idf-filter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;

    .idf-filters-wrapper {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      gap: 10px 0;

      & > * {
        margin-right: 20px;
        min-width: 20px;
        max-width: 100%;
        width: calc(20% - 20px);
      }
    }

    .idf-row {
      align-self: flex-start;
      margin-top: 12px;
    }
  }

  .idf-manage-cases {
    transition: height 0.3s ease;
    $columns: 30px 1fr 9% 5% 135px 30% 90px 120px 12% 105px 75px;
    @include bigTable($columns);
    @include filterVisible();
    @media only screen and (max-width: 1580px) {
      .idf-c-8 {
        display: none;
      }
    }

    .idf-focus .idf-flex-table.idf-row {
      border: 2px solid $blue !important;
      transition: border-color 0.4s ease-in-out;
    }

    .idf-disabled {
      opacity: 0.52;
    }

    input[type="checkbox"] {
      margin: 0;
    }

    .idf-flex-table {
      &.idf-row {
        border: 2px solid transparent;
      }

      .idf-action {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-end;
        -ms-flex-align: center;
      }
    }

    .idf-header {
      @extend .sticky-table-header;
    }

    .idf-select-actions {
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 0;
      left: 35px;
      z-index: 2;
      display: none;
      align-items: center;
      margin: 0;
      background: $lightblue;

      .idf-select-info {
        margin-right: 20px;
      }

      .idf-btn {
        margin-left: 15px;
      }

      .idf-link {
        margin-left: auto;
        color: white;

        &:hover {
          color: $red;
        }
      }
    }
    //Select Mode
    &.idf-select-mode {
      .idf-select-actions {
        display: flex;
      }

      .idf-flex-table.idf-row:hover {
        border: 2px solid $flatblue;
        cursor: pointer;
        transition: border-color 0.4s ease-in-out;
      }
    }
  }
  //Status
  .idf-c-6 span {
    @include allcapslabel;
    margin-right: 3px;
    line-height: inherit;
  }

  .idf-status-completed {
    color: $green;
  }

  .idf-status-assigned,
  .idf-status-in-process,
  .idf-status-re-assigned {
    color: $accent;
  }
}

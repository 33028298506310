@use "../../utilities/index" as *;

/* Sidebar Details */

.idf-graph-node-details {
    background: white;
    height: 100%;
    position: absolute;
    top:0;
    right: 0;
    z-index: 1;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    width: 45%;
    transition: all 0.3s ease;
    transform: translateX(100%);

    &.idf-active {
      left:auto;
      right:0;
        transform: translateY(0);
        width: 45%;
        box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.2);

        &.idf-compare-active, &.idf-aside-active {
          padding-right:55%;

          .idf-list.idf-risk-rules>div>div{
            transition: all .25s ease;

              &:not(.cellListSelectedItem){
                opacity:.5;
              }
              &:hover{
                opacity:1;
              }
          }

        }
    }

    & > .idf-slot{
      height: inherit;
      display: grid;
      grid-template-rows: max-content;
      position: relative;
    }

    .idf-spinner{
      position: absolute;
      margin: 55% calc(50% - 14px);
    }

    .idf-main-node-tag{
      display: none;
      @include allcapslabel();
      background-color: #5dabf2;
      padding: .2rem .6rem;
      color: white;
      vertical-align: middle;
      margin-left: .4rem;
    }

    .idf-graph-main-node  .idf-main-node-tag{
      display: inline-block
    }

    header {
        padding: 1rem 1.3rem;
        border-bottom: 1px solid $darkgray;

        label {
            text-transform: capitalize;
            @include bigboldtext();
            display: inline-block;
            margin: 0;
            cursor: default;
        }

        img {
            height: 1rem;
            opacity: 0.5;
            margin-left: 0.5rem;
            display: inline-block;
            vertical-align: middle;
            padding: 1px;
        }

        button {
            float: right;
            appearance: none;
            background: white url("../img/graph/clear.svg") no-repeat center;
            border: none;
            height: 1rem;
            width: 0.9rem;
            @include i-scale ();
        }
    }

    .idf-list-details:not(.idf-aside-list) {
      display: grid;
      grid-template-columns: 1fr .8fr;
      grid-column-gap: 1rem;
      grid-row-gap: .25rem;
      justify-items: stretch;
      align-items: stretch;
    }

    .idf-node-details{

      .idf-slot {
        display: grid;
        grid-template-rows: max-content;
        height: 100%;
      }

      --paper-icon-button_-_display: none;

      .idf-details-header {
          display: grid;
          grid-template-columns: 2.5fr .8fr;
          grid-column-gap: 0;
          grid-row-gap: 0;
          justify-items: stretch;
          align-items: flex-start;
          padding:1.3rem;


          .idf-aside-details{
            margin: 0 auto;

            .idf-risk-score{
              background-color: $gray;
              border: 1px solid $darkgray;
              text-align: center;
              padding: 0.2rem 1rem;

              span{
                font-size:5rem;
                color: $lightblue ;
                line-height: 1;
              }

              label{
                @include allcapslabel();
                font-weight: 800;
              }
            }
          }
      }
      .idf-details-header.idf-no-extra-data{
        grid-template-columns: 1.5fr 0.00fr;
      }

      .idf-tabs-menu {
        padding: 0 calc(1.3rem - 12px);
      }

      .idf-tabs .idf-tabs-holder{
        position:inherit;
      }

      .idf-tab-content{
        padding:1.3rem;

        .idf-map{
          margin-top:0;
          height: 20rem;
        }

      }

      .idf-graph-node-list{

        &>div>div{
          position: relative;

          .compare{
            position: absolute;
            right:.8rem;
            top:.8rem;

            label{
              padding:0 1.5rem 0 0;
              @include bodytext();
              color:$lightblue;
              margin:0;

              &::before{
                left: auto;
                right:0;
              }

              &::after{
                left:auto;
                right:.15rem;
              }
            }
          }
        }


      }


    }// details

    .idf-list {

      details{
        background-color: $gray;
        border:1px solid $darkgray;
        margin-bottom:1rem;


        summary{
          @include bodytext();
          padding: .8rem;
          .idf-list-details{
                grid-template-columns: 1fr 0.7fr;
          }

          span{
            cursor: pointer;
          }
          .type{
            font-weight: $bold;
            margin-right:.4rem;
            text-transform: capitalize;
          }
        }

        section{
          background-color: white;
          border-top:1px solid $darkgray;
          padding: .8rem 5rem .8rem .8rem;
        }

      }
    }
    .idf-score-details{
      .idf-list-details{
        background-color: $gray;
        border:1px solid $darkgray;
        margin-bottom:1rem;
        padding: .8rem;
        grid-template-columns: 1fr 0.3fr;

       .idf-list-detail{
         margin-bottom: 0;

         label{
          margin: 0 0 0 .5rem;
         }

       }

       .idf-score-points{
         text-align: right;
         @include allcapslabel();
         color: $lightblue;

       }

      }

    }
    .idf-risk-head.idf-list-details{
      grid-template-columns: 1fr 1fr;
      margin-bottom:.8rem;
    }

    .idf-risk-rules{

      .risk-rule-cell .idf-list-details .idf-list-detail:nth-child(1){
        line-height:1.7
      }

      .idf-status{
        float: right;
        @include allcapslabel();
        color: $lightblue;
      }

      details summary span {
        cursor: default;
    }
      details section.idf-list-details{
        grid-template-columns: 1fr;
      }
      .idf-more{
        @include btn-samll-dark();
        margin-top: .5rem;
      }
    }
    .idf-list > div div:last-child {

      .idf-list-details, details{
        margin-bottom: 0;
      }
  }

  .idf-claim-details.idf-slot {
    height: inherit; //calc(100% - 200px);
    padding: 0 1.3rem;
    position: absolute;
    top: 50px;
    bottom: 0;
    width: 100%;

    .idf-darkblue.idf-important{
      margin-top: 1.3rem;
    }
    .idf-list-details.idf-aside-list {
      display: flex;
      flex-wrap: wrap;
    }
  }

}

.idf-graph-aside-details{
  width: 0;
  right: 0;
  position: absolute;
  top: 0;
  bottom:0;
  transition: all .3s ease;

  button{
    display:none;
  }

  &.idf-active{
    width: 50%;
    z-index: 2;
    border-left:1px solid $darkgray;
    padding-right: 2px;
    &.idf-aside-panel{
      padding-right: 2px;
      top: 108px;
      height: calc(100vh - 123px);
      box-shadow: none;
      .idf-aside-header{
        padding: 4px 5px 4px 25px;
      }
    }

    button{
      display:block;
    }

  }

  .idf-hide{
    @include btn-samll-dark();
    margin: .7rem 2.2rem;
  }

  .idf-slot {
    position: relative;
    height: calc(100% - 3.5rem - 2px);

    section {
      margin-top: 2.5rem;

      header{
        @include bigboldtext();
        margin-bottom:1.2rem;
        margin-top:.2rem;
      }
    }

    .idf-rule-fail-reasons{
      margin-top:0;
    }

    .cellListEvenItem, .cellListOddItem{
      margin-bottom:1rem;
    }

    .idf-list-details .idf-list-detail *{
      line-height: 1.6
    }

    .idf-qa-list{

      .idf-list-detail{
        a{
          font-size: .8rem;
          font-weight: 600;
          letter-spacing: .02rem;
          line-height: 1;
          vertical-align: middle;
        }

      }
    }

    .idf-spinner{
      position: absolute;
      top:0;
      margin: 37% calc(50% - 14px);
    }
  }

}


.idf-details-list-group {

  details {
    background-color: $gray;
    border: 1px solid $darkgray;
    margin-bottom: 1rem;


    summary {
      @include bodytext();
      padding: .8rem;

      .idf-list-details {
        grid-template-columns: 1fr 0.7fr;
      }

      span {
        cursor: pointer;
      }

      .type {
        font-weight: $bold;
        margin-right: .4rem;
        text-transform: capitalize;
      }
    }

    section {
      background-color: white;
      border-top: 1px solid $darkgray;
      padding: .8rem 5rem .8rem .8rem;
    }
  }
}

@keyframes listadd {
  from{
    transform: translateX(10%);
  }
}

@use "../../utilities/index" as *;

.idf-app-prefill {
  padding-top: 15px;

  .idf-steps {
    --paper-tab-content_-_color: #425a70;
    --paper-tab_-_padding: 0;
    --paper-tabs-selection-bar-color: transparent;
    position: sticky;
    top: -1px;
    margin-bottom: 20px;
    padding-right: 65%;
    height: 38px;
    background: white;
    box-shadow: inset 0 -1px 0 0 $flatblue;
  }

  paper-tab {
    border-bottom: none;
    border-radius: 4px 4px 0 0;
  }

  paper-tab.idf-active {
    background: #DFE6EC;
  }

  .idf-app-prefill-deductibles {
    $columns: 1fr 16% 25%;
    @include fixTable($columns);
  }

  .idf-app-prefill-coverage {
    $columns: 40% 60%;
    @include fixTable($columns);
  }

  .idf-details-item {
    margin-bottom: 10px;
  }
}

@use "../../utilities/index" as *;

.idf-vehicle-details.idf-slot {
  .idf-list-details {
    display: flex;
    flex-flow: wrap;
    margin-top: 5px;
    margin-bottom: 15px;

    & > * {
      margin-right: 20px;
      width: calc(50% - 40px);
    }
  }

  .idf-galleria {
    margin-bottom: 10px;
    width: 100%;
    height: 470px;
  }

  .idf-photo-data {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;

    .idf-map {
      margin-top: 12px;
      width: 100%;
      height: 300px;
      border-radius: 4px;
    }
  }

  .idf-tags-list {
    flex: 1;

    .cellListEvenItem,
    .cellListOddItem {
      display: inline;
    }
  }

  .idf-submission-history {
    $columns: 15% 1fr 30% 20%;
    @include fixTable($columns);
  }

  section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .idf-link {
      align-self: flex-end;
    }
  }

  .idf-hide-view-all {
    .idf-link {
      display: none;
    }
  }

  .idf-no-data {
    .idf-header {
      display: none;
    }

    .idf-warning {
      display: block;
      padding: 0.6em 0 !important;
      border-radius: 4px;
      background-color: $flatblue;
      color: $darkblue;
      font-size: 18px;
    }
  }
}
//img tag

.idf-img-tag {
  display: inline-block;

  span {
    position: relative;
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
    padding: 2px 32px 2px 10px;
    border-radius: 3px;
    background: $bluegray;
    color: $lightblue;
    font-weight: $bold;

    &:before {
      position: absolute;
      top: 5px;
      right: 10px;
      width: 14px;
      height: 14px;
      background: transparent url("../img/tag-object.svg") no-repeat scroll 50% 50%;
      content: '';
    }
  }

  &.idf-gv-text span::before {
    background: transparent url("../img/tag-text.svg") no-repeat scroll 50% 50%;
  }
}

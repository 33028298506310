@use "../utilities/index" as *;

/* LOADING INDICATOR */
#loadingIndicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: #ccc;
  opacity: 0;
  transition: all 0.3s ease;
}

#loadingIndicator.idf-loading-active {
  display: block;
  opacity: 0.7;
  transition: all 0.3s ease;
}

#idf-loader-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px -50px;
  width: 100px;
  height: 40px;
}

#idf-loader-animation div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $darkblue;
  opacity: 0;
  transition: opacity 0.3s ease;
  transition-delay: 0.7s;
}

#loadingIndicator.idf-loading-active #idf-loader-animation div {
  opacity: 1;
}

#d1 {
  animation: dotloading 2s linear infinite;
}

#d2 {
  animation: dotloading 2s linear infinite -0.4s;
}

#d3 {
  animation: dotloading 2s linear infinite -0.8s;
}

#d4 {
  animation: dotloading 2s linear infinite -1.2s;
}

#d5 {
  animation: dotloading 2s linear infinite -1.6s;
}
@keyframes dotloading {
  0% {
    top: 0;
    left: 100px;
  }

  80% {
    top: 0;
    left: 0;
  }

  85% {
    top: -20px;
    left: 0;
    width: 20px;
    height: 20px;
  }

  90% {
    width: 40px;
    height: 15px;
  }

  95% {
    top: -20px;
    left: 100px;
    width: 20px;
    height: 20px;
  }

  100% {
    top: 0;
    left: 100px;
  }
}
//new loading
.idf-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 980;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.1);
  animation: fadeIn 0.5s;
  backdrop-filter:blur(2px);

  &__content {
    text-align: center;
  }

  &__text {
    display: block;
    margin-top: 30px;
    color: $darkblue;
    text-align: center;
    font-weight: 400;
    font-size: 1.2rem;
  }

  svg {
    width: 100px;
    height: 100px;

    path,
    rect {
      fill: $orange;
    }
  }
}
@keyframes fadeIn() {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

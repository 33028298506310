/**
 *
 * @author ercan
 *
 */

.idf-form-viewer {
  .idf-form-repeating-group {
    &--tabbed-pane {
      .idf-tabs {
        --paper-tabs-selection-bar-color: #CE4D16;
        display: flex;
      }

      .idf-tab-content {
        .idf-form-item-wrapper {
          margin-bottom: 0;
          padding-bottom: 40px;
          box-shadow: none;

          .idf-active-state {
            border-radius: 0;
            opacity: 0;
            transition: opacity 0.3s;
            transform: none !important;
          }

          &:Hover {
            .idf-active-state {
              opacity: 0.6;
            }
          }

          &.idf-active {
            .idf-active-state {
              opacity: 1;
            }
          }
        }
      }
    }

    &--table {
      .idf-repeating-table {
        &__title {
          overflow: hidden;
          margin-bottom: 2px;
          max-width: 310px;
          color: #fff;
          text-overflow: ellipsis;
          white-space: nowrap;

          p {
            overflow: hidden;
            max-width: 310px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &__text {
          color: rgba(#ffffff, 0.8);
        }

        .idf-form-item-wrapper {
          overflow: hidden;
          padding-bottom: 40px;

          p {
            min-height: 12px;
            white-space: normal;
            line-height: 1.5;
          }

          label {
            display: none;
          }

          .idf-signature-wrapp {
            canvas {
              margin-top: 0;
              width: 340px;
              height: 100px;
            }
          }

          .idf-active-state {
            top: auto;
            left: 0;
            width: 100%;
            height: 4px;
            border-radius: 0;
            opacity: 0;
            transition: opacity 0.3s;
            transform: none !important;
          }

          &:Hover {
            .idf-active-state {
              opacity: 0.6;
            }
          }

          &.idf-active {
            .idf-active-state {
              opacity: 1;
            }
          }
        }

        table {
          width: 100%;

          thead {
            th {
              background: #425a70;
              color: #fff;
            }
          }

          tbody {
            tr {
              vertical-align: top;
            }
          }
        }
      }

      .idf-timed-input {
        margin-top: 0;

        flip-clock {
          --digits-size: 20px;
          line-height: 1;
        }

        .idf-btn {
          padding: 8px 16px !important;
          line-height: 1.5;
        }
      }

      .idf-disabled {
        display: block;
        opacity: 0.3;
        user-select: none;
      }
    }
  }
}

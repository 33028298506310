@use "../../utilities/index" as *;

.idf-rule-table {
  display: flex;
  justify-content: space-between;

  &__content {
    overflow-x: auto;
    width: calc(100% - 150px);

    table {
      overflow: hidden;
      min-width: 1200px;
      width: 100%;
      border-collapse: collapse;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-size: 0.9rem;

      th {
        background: rgba($bluegray, .5);
      }

      td,
      th {
        padding: 10px;
        border: 1px solid $bluegray;
        text-align: left;
      }

      tr {
        &:first-child {
          td,
          th {
            &:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
          }
        }

        &:last-child {
          td,
          th {
            border-right: none;
          }
        }
      }
    }
  }

  &__button {
    width: 150px;

    .idf-add-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      .idf-add-btn {
        padding: 10px !important;
        height: 100%;
      }

      .idf-add {
        width: 24px;
        height: 24px;
        background-size: contain;
      }

      .idf-important {
        font-size: 0.9rem;
      }
    }
  }
}

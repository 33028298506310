@use "../../utilities/index" as *;

.idf-risk-detection-edit {
  .idf-details-item {
    &:after {
      display: block;
      height: 25px;
      background: url("/assets/img/arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
    }

    .idf-details-summary {
      i {
        display: block;
        margin-right: 12px;
        width: 31px;
        height: 31px;
        background: url("/assets/img/rule-square.svg");
      }
    }

    .idf-text,
    .idf-title {
      color: white;
    }

    .idf-title {
      font-weight: $bold;
      line-height: 1.3;
    }

    .idf-text {
      margin-top: 1px;
      font-size: 90%;
      opacity: 0.8;
    }

    .idf-details-summary {
      background: $flatlightblue;
    }

    .idf-details-content {
      background: white;
    }

    h4 {
      display: none;
    }
  }

  .idf-risk-detection-general {
    .idf-details-summary {
      i {
        background: url("/assets/img/component-square.svg");
      }
    }

    .idf-details-content {
      display: flex;
    }

    paper-input {
      max-width: 300px;
    }

    vaadin-combo-box {
      margin: 0 15px;
    }
  }

  .idf-prefilter {
    .idf-details-summary {
      i {
        background: url("/assets/img/filter-square.svg");
      }
    }
  }

  .idf-rule-builder {
    margin-bottom: 0;
  }

  .idf-next-action {
    display: flex;
    justify-content: center;
  }

  .idf-run-section {
    margin-top: 25px;
    $columns: 1fr 11% 10% 12% 10% 10% 240px;
    @include mediumTable($columns);

    .idf-table-content .idf-row {
      margin-top: 4px;
      border-radius: 3px;
      background: white;
    }

    a {
      @include allcapslabel();
      color: $blue;
    }

    .idf-output-action {
      position: relative;
      display: block;
      width: 100%;

      span {
        transition: all 0.2s ease;
      }

      span+span {
        position: absolute;
        right: 0;
        left: 0;
        opacity: 0;
      }

      &:hover {
        // text-decoration: none;
        span {
          opacity: 0;
        }

        span+span {
          opacity: 1;
        }
      }
    }

    .idf-action {
      justify-content: flex-end;
      padding: 0.8em 0.8em!important !important;

      span {
        display: inline-block;
        margin: 2px 8px;
        width: 1px;
        height: 8px;
        background-color: $flatlightblue;
      }
    }
  }

  .idf-output-columns,
  .idf-output-data {
    margin-bottom: 20px;
  }
}

@use "../../utilities/index" as *;

.idf-simulator {
  .idf-simulator-list {
    $columns: 1fr 265px;
    @include fixTable($columns);

    .idf-flex-table {
      align-items: center;
      -ms-flex-align: center;
    }

    .idf-row {
      margin-top: 5px;
      background: $gray;
    }

    .idf-all-caps-label.idf-flatblue {
      margin: 0 4px;
    }

    .idf-flex-table {
      align-items: center;
      -ms-flex-align: center;
    }

    .idf-preview {
      flex-direction: column;
      align-content: flex-start;
      justify-content: center;
      -ms-flex-align: flex-start;

      p {
        margin-bottom: 7px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .idf-actions div {
      display: flex;
      flex-direction: column;
      align-items: center;

      & + div {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid $flatblue;
      }
    }
  }
}

@use "../../utilities/index" as *;

//fullscreen fix
.idf-richtext-fullscreen{
  border-radius: 0 !important;
  box-shadow: none !important;
  left: 0 !important;
  right: 0 !important;
  top: 50px !important;
  bottom: 0 !important;
  width: 100% !important;
  height: calc(100% - 50px) !important;
}

.idf-richtext-button-pane{
   button{
     margin-top: 4px;
     margin-bottom: 4px !important;
     &:hover{
       background: #f4f4f4;
       @include border-radius;
     }
   }
}

.idf-richtext-disable{
  button{
    pointer-events: none;
    opacity: .2;
  }

  .idf-richtext-active{
    opacity: 1;
    pointer-events: all;
  }
}

@use "../../utilities/index" as *;
//Generic report styles
.idf-analyze-report {
  //Section - card
  .idf-card {
    margin-bottom: 20px;
    //Section - card header
    .idf-all-caps-label {
      margin-bottom: 10px;
      color: $darkblue;
    }
    //Section - card content
    .idf-card-content {
      display: flex;
      flex-flow: wrap;
      gap: 7px 0;
    }

    .cellListEvenItem,
    .cellListOddItem,
    .idf-column-group {
      margin-bottom: 10px;

      header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;
        color: $darkblue;
        font-weight: 600;

        paper-checkbox {
          margin: 0 15px!important !important;
        }
      }

      .idf-check-list {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 6px;
      }

      .idf-checkbox {
        margin-bottom: 6px;
      }
    }

    .idf-card-content > paper-checkbox {
      align-self: center;
    }

    .idf-list-selector {
      position: relative;

      .idf-link {
        position: absolute;
        top: -3px;
        right: 0;
        font-weight: 600;
        font-size: 12px;
      }
    }
    //dynamic list
    .idf-slot > div {
      display: flex;
      flex-flow: wrap;
      gap: 5px 0;
    }

    footer {
      margin-top: 11px;
    }
  }

  .idf-filters .idf-card-content > * {
    margin-right: 20px;
    margin-bottom: 9px;
    min-width: 20px;
  }
  //Filters input width
  .idf-input-small {
    max-width: 150px;
    width: 100%;
  }

  .idf-input-medium {
    max-width: 250px;
    width: 100%;
  }

  .idf-input-large {
    max-width: 360px;
    width: 100%;
  }

  .idf-input-xlarge {
    max-width: 480px;
    width: 100%;
  }

  .idf-row-align-center {
    align-items: center;
  }

  .idf-summary-list {
    $columns: 1fr, 25%, 25%, 25%;
    @include mediumTable($columns);
  }

  .idf-policy-analyze-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    &__main {
      width: calc(100% - 480px);
    }
  }

  .idf-aside-filters {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 460px;

    .idf-card {
      &__content {
        gap: 12px;
      }
    }

    .idf-bs-row {
      margin-right: -12px !important;
      margin-left: -12px !important;
    }

    .idf-col {
      margin-bottom: 12px;
      padding-right: 12px;
      padding-left: 12px;
    }

    multiselect-combo-box,
    vaadin-combo-box,
    vaadin-date-picker {
      max-width: 100%;
      width: 100%;
    }
  }

  .idf-policy-analyze-columns {
    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 60px !important;
    }

    .cellListEvenItem,
    .cellListOddItem {
      margin-bottom: 0;
      width: 184px;

      header {
        padding-bottom: 6px;
      }
    }
  }

  .idf-card {
    margin-bottom: 20px;

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      border-bottom: 1px solid $bluegray;
      transition: border 0.3s, border-bottom-color 0.3s;

      &--noBorder {
        border-bottom-width: 0;
        border-bottom-color: transparent;
      }
    }

    &__header-left {
      display: flex;
      justify-content: flex-start;
    }

    &__header-right {
      position: relative;
      display: flex;
      justify-content: flex-end;
    }

    &__header-extra {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding-left: 15px;
      border-left: 1px solid $bluegray;
    }

    &__title {
      display: inline-flex;
      align-items: center;
      padding: 15px;
      font-weight: 600;
      font-size: 0.9rem;
      gap: 4px;
    }

    &__toggle {
      display: inline-flex;
      align-items: center;
      padding: 0 15px;
      border: none;
      border-left: 1px solid $bluegray;
      border-top-right-radius: 3px;
      background-color: #fff;
      color: #94a6b5;
      font-weight: 600;
      font-size: 0.7rem;
      transition: background 0.3s;

      .bi {
        position: relative;
        top: -1px;
        margin-left: 8px;
        vertical-align: middle;
        font-size: 14px;
        transition: transform 0.3s;
      }

      &:hover {
        background-color: $bluegray;
      }

      &--collapsed {
        border-bottom-right-radius: 3px;

        .bi {
          transform: rotate(-180deg);
        }
      }

      &--noPadding {
        padding: 0;
      }
    }
  }

  .idf-big-table {
    tbody {
      tr {
        &:last-child {
          td {
            border: none;

            &:first-child {
              border-bottom-left-radius: 6px;
            }

            &:last-child {
              border-bottom-right-radius: 6px;
            }
          }
        }
      }
    }
  }
}

@use "../../utilities/index" as *;

.idf-statistics {
  display: flex;
  height: 100%;
  gap: 15px;

  .idf-stats-list {
    width: 290px;
    color: $lightblue;

    .idf-card {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.03);
      transition: all 0.23s ease;

      &:hover {
        box-shadow: 0 5px 6px rgba(0, 0, 0, 0.03);
        cursor: pointer;
        transform: translateY(-2px);
      }
    }

    i {
      display: block;
      margin-right: 8px;
      width: 19px;
      height: 19px;
      background-image: url("../img/stats-number.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .idf-map i {
      background-image: url("../img/stats-map.svg");
    }

    .idf-time i {
      background-image: url("../img/stats-time.svg");
    }

    .idf-table-type i {
      background-image: url("../img/stats-table.svg");
    }

    .idf-bar-type i {
      background-image: url("../img/stats-bar.svg");
    }

    .idf-active {
      border-color: $blue;
      background: $gray;
    }
  }

  .idf-view-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .idf-filters {
      display: flex;
      padding: 10px 20px;
      gap: 10px;

      .idf-row {
        width: max-content;
        gap: 10px;
      }

      .idf-whide {
        width: 340px;
      }

      .idf-medium {
        width: 200px;
      }
    }

    .idf-stats-view {
      overflow: hidden;
    }

    #chart,
    #maps,
    .idf-stats-view {
      height: 100%;
    }

    #chart,
    .idf-stats-view {
      position: relative;
    }

    .idf-stats-view,
    .idf-table {
      height: 100%;
    }

    .idf-full-map {
      position: absolute;
      top: 0;
      left: 18px;
    }

    .highcharts-container {
      min-height: 62vh;
    }

    .idf-timeframe {
      position: absolute;
      right: 20px;
      bottom: 25px;
      display: inline-flex;
      overflow: hidden;
      border-radius: 4px;
      background: white;
      box-shadow: 0 3px 13px -5px #18191d30, 0 1px 2px 0 #78879554;

      div {
        padding: 9px 20px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
      }

      div:hover {
        background: $flatblue;
        color: $darkblue;
      }

      .idf-active,
      .idf-active:hover {
        background: $lightblue;
        color: white;
      }
    }

    .highcharts-title {
      font-weight: bold;
      font-size: 26px;
      fill: $darkblue;
    }

    .highcharts-axis-line {
      fill: none;
      stroke: $flatblue;
    }

    .highcharts-grid-line {
      fill: none;
      stroke: $bluegray;
    }

    .highcharts-tooltip-box {
      fill: $bluegray;
      stroke-width: 0;
      fill-opacity: 0.85;
    }

    .highcharts-tooltip-box .highcharts-label-box {
      fill: $bluegray;
      fill-opacity: 0.85;
    }

    .highcharts-root text {
      stroke-width: 0;
    }

    .idf-no-results-wrapp {
      @include bigboldtext();
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .idf-title {
        margin-top: 35px;
      }

      .idf-subtitle {
        color: $flatlightblue;
      }
    }
    //Table View
    .idf-alert-triggered {
      $columns: 1fr 12% 20% 125px;
      @include mediumTable($columns);

      .idf-c-3,
      .idf-rule-data {
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
      }

      .idf-tag {
        align-self: flex-start;
        margin-bottom: 3px;
      }

      .idf-extra {
        font-size: 0.7em;
      }
    }
  }
}

.idf-triggered-rules {
  overflow: hidden;
  height: inherit;
  height: calc(100vh - 130px);
  $columns: 1fr 20% 10% 12% 12% 12% 75px 50px;
  @include bigTable($columns);

  .idf-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;

    .idf-filters-wrapper {
      display: flex;
      flex: 1;
      @media only screen and (max-width: 1270px) {
        flex-wrap: wrap;
        margin-bottom: -10px;

        & > * {
          margin-bottom: 10px;
          max-width: 100%;
          width: calc(25% - 20px);
        }
      }

      & > * {
        margin-right: 20px;
        min-width: 20px;
        max-width: 200px;
        width: 100%;
      }

      multiselect-combo-box {
        min-width: 200px;
        max-width: 100%;
        width: auto !important;
      }
    }

    .idf-row {
      align-items: flex-start;
      margin-top: 13px;
    }
  }

  .idf-row {
    margin-top: 4px;
    background: white!important;
  }

  .idf-header {
    line-height: 2em;
  }

  .idf-table-content {
    height: 100%;
  }
}

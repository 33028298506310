@use "sass:color";
@use "../../utilities/index" as *;

.idf-risk-rule-edit {

  .idf-risk-rule-general {
    display: grid;
    align-items: flex-start;
    padding-top: calc(20px + 7.5px);
    grid-template-columns: minmax(100px, 1.3fr) minmax(100px, 1.3fr) minmax(110px, 17%) minmax(80px, .7fr); //keep inputs fixed on smaller screen
    grid-template-rows: 1fr;
    gap: 0 30px;
    grid-template-areas: "name action status check";

    .idf-rule-name {
      grid-area: name;
    }

    .idf-action-description {
      grid-area: action;
    }

    .idf-status {
      grid-area: status;
    }

    vaadin-combo-box {
      margin-bottom: 20px;
      width: 100%;
    }

    paper-input {
      margin-bottom: 20px;
      width: 100%;
    }

    vaadin-date-picker {
      width: 100%;
    }

    .idf-check-list {
      display: flex;
      flex-direction: column;
      height: auto;
      grid-area: check;

      paper-checkbox {
        margin-bottom: 10px;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .idf-output {
    .idf-of-list {
      display: flex;
      flex-wrap: wrap;

      & > * {
        margin-top: 7.5px;
        margin-right: 20px;
        margin-bottom: 7.5px;
        max-width: 300px;
        width: calc(33.33% - 14px);

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .idf-invalid .idf-of-list {
    padding: 3px;
    border: 1px solid $red;
    border-radius: 3px;
  }

  .idf-split {
    display: flex;
    align-items: flex-start;

    & > div {
      flex: 2;
    }

    h4 {
      margin-top: 0;
    }

    .idf-rule-extra {
      flex: 2;
      margin-right: 30px;

      & > * {
        margin: 0 0 20px;
        width: 100%;

        &:last-of-type {
          margin-bottom: 5px;
        }
      }
    }

    .idf-distribution-channel {
      flex: 3.3;

      .idf-row {
        align-items: flex-end;
        margin-bottom: 20px;

        paper-input {
          margin-left: 30px;
          width: 250%;
        }

        vaadin-combo-box:first-child {
          margin-right: 30px;
          width: 180%;
        }

        vaadin-combo-box:nth-child(2) {
          width: 110px;

          @include ie {
            width: 70%;
          }
        }

        multiselect-combo-box {
          flex: 1;
          margin-right: 30px;
        }
      }

      .idf-dc-list {
        margin-top: 30px;
        margin-bottom: 7.5px;

        & > p {
          border-bottom: 1px solid $gray;
        }
      }
    }
  }
  //common
  .idf-card {
    margin-bottom: 30px;
    padding: 20px;
  }

  .idf-error-message {
    display: none;
    margin-top: 3px;
    color: $red;
    font-size: 12px;
  }

  .idf-invalid .idf-error-message {
    display: block;
  }

  .idf-rule-form {
    display: flex;
    align-items: flex-start;
    padding: 0;
    border-radius: 4px;
    background: transparent;

    .idf-add-form {
      position: sticky;
      top: 0;
      padding: 20px;
      max-width: 435px;
      width: 50%;
      border-top-left-radius: 4px;
      background-color: #fff;
    }

    .idf-form-view {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-left: 1px solid $bluegray;
      border-top-right-radius: 4px;
      background: $gray;

      .idf-no-from {
        padding: 30px;
        color: $blue;
        font-size: 14px;
        opacity: 0.4;
      }

      .idf-edit {
        margin-top: 20px;
      }
    }

    vaadin-combo-box {
      width: 100%;
    }

    paper-button {
      margin: 0;
    }

    .idf-form-viewer {
      padding: 0;
      background: transparent;

      .idf-add-wrapper {
        display: none;
      }

      .idf-form-repeating-group > .idf-form-item-wrapper {
        padding: 0;
      }
    }

    .idf-form-item-wrapper {
      margin: 0;
      padding: 10px 0;
      background: white;
      cursor: auto;

      &:hover {
        border-color: transparent !important;
      }
    }

    .idf-form-simple-group {
      .idf-form-item-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .idf-form-fields {
      padding: 15px;
      max-width: 100%;
      border-radius: 4px;
      background: white;
    }

    .idf-conditional,
    .idf-item-id {
      display: none;
    }
  }

  .idf-tab-content {
    display: none;
  }

  .idf-tab-content.idf-active {
    display: block;
  }

  .idf-phone-confirmation {
    .idf-form-item-wrapper {
      margin-bottom: 10px;
      padding: 23px 24px 30px;
    }
  }

  .idf-group {
    margin: 20px 0;
    padding: 24px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 2px 19px -16px rgba(0,0,0,.9), 0 1px 3px 0 rgba(0,0,0,.1);

    .idf-form-item-wrapper {
      margin-bottom: 10px;
      padding: 10px 0;
      box-shadow: none;
    }
  }

  .idf-form-fields {
    padding: 15px 0 15px 10px;
    background: white;
  }

  .idf-field-settings .idf-set-specific .idf-input-row {
    margin-bottom: 10px;

    vaadin-combo-box {
      width: 100%;
    }
  }

  .idf-text {
    paper-input,
    paper-textarea {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_z-index: 1 !important;
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-phone {
    gold-phone-input {
      --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 23px !important;
      --paper-input-container-input-invalid_-_border: 1px solid #AA2E2E;
      --gold-phone-input-country-code_-_top: 0;
      min-width: 360px;
      max-width: 360px;
      @media(max-width: 1500px) {
        --gold-phone-input-country-code_-_top: 1px;
      }
      @media(max-width: 576px) {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 23px !important;
      }
    }

    .idf-phone-input {
      &--digit-2 {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 30px !important;
        @media(max-width: 576px) {
          --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 30px !important;
        }
      }

      &--digit-3 {
        --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 38px !important;
        @media(max-width: 576px) {
          --paper-input-container-shared-input-style_-_padding: 9px 43px 9px 38px !important;
        }
      }
    }
  }

  .idf-number {
    paper-input {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_letter-spacing: 0.9px;
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-timed-input {
    paper-input {
      --paper-input-error_-_background: transparent !important;
      --paper-input-error_-_padding: 0 !important;
      --paper-input-error_-_bottom: -14px !important;
      --paper-input-error_-_margin-bottom: 0 !important;
      --paper-input-error_-_color: #AA2E2E !important;
      --paper-input-error_-_letter-spacing: 0.9px;
      min-width: 360px;
      max-width: 360px;
    }

    .idf-btn {
      margin-top: 10px;
    }
  }

  .idf-dropdown {
    vaadin-combo-box {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-date {
    vaadin-date-picker {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-slider {
    paper-slider {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .idf-fade {
    opacity: 1;
    transition: all 0.3s;

    &--hidden {
      opacity: 0;
    }
  }
}
//Rule Builder

.idf-rule-builder {
  margin-bottom: 30px;

  &.idf-invalid .idf-rule-builder-main > .idf-group-content {
    border: 1px solid $red;
    border-top: 1px solid $red;

    header {
      margin: 1px;
    }
  }

  .idf-rule-generator {
    &__header {
      --lumo-text-field-size: 26px;
      --lumo-size-m: 26px;

      paper-icon-button {
        margin-left: 12px;
        width: 32px !important;
        height: 32px !important;
        background-color: transparent !important;

        &:hover {
          background-color: rgba(#fff, .1) !important;
        }
      }
    }

    &__top {
      padding: 15px;
      background-color: #fff;
    }

    &__content {
      padding: 15px;
      border: 1px solid $bluegray;
      border-top: none;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      background-color: #fff;

      .idf-rule-generator__header {
        padding-right: 4px;
        background-color: #607487;
      }

      .idf-rule-builder-group,
      .idf-rule-item {
        .idf-criteria-badge {
          &:before {
            top: -4px;
            height: calc(100% + 10px);
          }

          &:after {
            top: -4px;
            height: calc(100% + 7px);
            border-top-width: 0;
            border-bottom-width: 0;
          }
        }

        &:first-child {
          .idf-criteria-badge {
            &:after {
              border-top-width: 3px;
            }
          }
        }

        &:last-child {
          .idf-criteria-badge {
            &:after {
              border-bottom-width: 3px;
            }
          }
        }

        &:only-child {
          .idf-criteria-badge {
            &:after {
              height: calc(100% + 4px);
              border-top-width: 3px;
              border-bottom-width: 3px;
            }
          }
        }
      }
    }

    .idf-group-content {
      .idf-rule-item {
        .idf-criteria-badge {
          &:after {
            border-bottom-width: 0;
          }
        }

        &:only-child {
          .idf-criteria-badge {
            &:after {
              border-bottom-width: 3px;
            }
          }
        }

        &:last-child {
          .idf-criteria-badge {
            &:after {
              border-bottom-width: 3px;
            }
          }
        }
      }
    }

    .idf-btn {
      padding-right: 14px;
      padding-left: 14px;
    }

    paper-icon-button {
      padding: 8px;
      min-width: 38px;
      width: 38px;
      height: 38px;
      border-radius: 4px;
      background-color: rgba($orange, .1);
      color: $orange;
      transition: all 0.3s;
      @include md() {
        min-width: 36px;
        width: 36px;
        height: 36px;
      }

      &:hover {
        background-color: rgba($orange, .3);
      }
    }
  }

  .idf-rule-item {
    flex-wrap: nowrap;

    &__badge {
      margin-right: 10px;

      &.idf-rule-builder-main {
        display: none;
      }
    }
  }

  .idf-rule-builder-group {
    display: flex;
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;

    &.idf-rule-builder-main {
      margin-top: 0;
    }

    .idf-group-content {
      width: 100%;

      header {
        span {
          display: flex;
          align-items: center;
        }

        vaadin-combo-box {
          margin: 0 10px;
          max-width: 70px;
        }

        paper-icon-button.idf-rule-builder-main {
          display: none;
        }
      }

      & > div {
        .idf-add-criteria-row {
          display: flex;

          vaadin-combo-box {
            width: 170px;
          }
        }

        .idf-criteria-row {
          display: flex;
          margin-bottom: 12px;

          .idf-criteria {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            padding: 4px;
            border: 1px solid $bluegray;
            border-radius: 6px;
            gap: 6px;

            .idf-criteria-content {
              display: inline-flex;
              align-items: center;
              gap: 6px;
              //subcategory
              vaadin-combo-box:nth-child(1) {
                max-width: 200px;
                width: 100%;
              }
              //usualy compare
              vaadin-combo-box:nth-child(2) {
                max-width: 180px;
                width: 100%;
              }

              multiselect-combo-box {
                min-width: 240px;
              }

              .idf-criteria-value {
                display: flex;
                width: 100%;

                &:empty {
                  margin: 0;
                }
              }
            }

            vaadin-combo-box {
              max-height: 38px;
            }

            paper-input {
              max-width: 200px;
              max-height: 38px;
              width: 100%;
            }

            paper-icon-button {
              opacity: 0.72;
            }

            vaadin-date-picker {
              max-width: 200px;
              width: 100%;
            }
          }
        }
      }
    }

    .idf-match-type {
      min-width: 35px;
      text-align: center;
      line-height: 44px;

      &.idf-rule-builder-main {
        display: none;
      }
    }
  }

  .idf-error-message {
    display: none;
    margin-top: 3px;
    color: $red;
    font-size: 12px;
  }

  &.idf-invalid .idf-error-message {
    display: block;
  }
  //read Only
  &.idf-readonly {
    --lumo-contrast-10pct: #f3f5f7;

    .idf-add-criteria-row,
    header,
    paper-icon-button {
      display: none !important;
    }

    .idf-group-content {
      width: calc(100% - 35px) !important;

      & > div {
        padding: 3px 11px;
      }
    }

    .idf-rule-builder-main > .idf-group-content {
      width: 100% !important;
      border: none !important;

      & > div {
        padding: 0 0 0 7px;
      }
    }

    .idf-rule-builder-group {
      margin-top: 5px;

      .idf-rule-builder-group {
        width: calc(100% + 10px);
      }
    }

    .idf-criteria-row {
      margin-top: 3px !important;

      .idf-criteria {
        padding: 0 !important;
        width: calc(100% - 35px) !important;
        border: none !important;

        .idf-criteria-content {
          & > *:nth-child(2) {
            max-width: 100px;
          }

          & > * {
            width: auto;
            pointer-events: none;
          }

          .idf-criteria-value {
            max-width: 280px;

            & > * {
              max-width: inherit;
            }

            vaadin-date-picker {
              min-width: 200px;
            }
          }
        }
      }
    }
  }

  .idf-input {
    --paper-input-container-input_-_padding: 10px !important;
    --paper-input-error_-_bottom: -21px;
    --paper-input-error_-_left: 6px !important;
    --paper-input-error_-_padding: 2px 4px;
    --paper-input-error_-_border-radius: 32px;

    &:before {
      display: none !important;
    }
  }
}
//Distribution Channel Item

.idf-dc-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 1px 5px 1px 15px;
  border-radius: 3px;
  background: $lightblue;
  color: $bluegray;
  line-height: 36px;

  .idf-left {
    display: flex;
    align-items: center;
    margin: 2px 0;
    width: 100%;
  }

  .idf-tag {
    border: 1px solid #5b758d;

    &:nth-child(2) {
      flex: none;
    }
  }

  span {
    margin-right: 5px;
  }
}

.idf-field-dropdown {
  position: relative;

  &.is-active {
    display: block;
  }

  &__placeholder {
    width: 240px;
    height: 40px;
  }

  &__wrapper {
    @include box-shadow;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 220;
    padding: 12px;
    width: 100%;
    border: 1px solid $bluegray;
    border-radius: 6px;
    background-color: #fff;
  }

  &__list {
    overflow-y: auto;
    padding-bottom: 8px;
    max-height: 130px;
  }

  &__or {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    height: 24px;
    font-weight: bold;
    font-size: 0.8rem;

    &:before {
      position: absolute;
      top: 12px;
      width: 100%;
      height: 1px;
      background-color: $bluegray;
      content: '';
    }

    span {
      position: relative;
      z-index: 4;
      display: inline-block;
      padding: 0 6px;
      background-color: #fff;
      color: $bluegray;
    }
  }

  &__search {
    position: relative;
    margin-bottom: 8px;
    padding: 6px 6px 2px;
    border: 1px solid $bluegray;
    border-radius: 6px;

    paper-input {
      --paper-input-container-input_-_font-size: 12px;
    }

    .bi {
      position: absolute;
      top: 50%;
      right: 8px;
      cursor: default;
      transform: translateY(-50%);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    padding: 8px;
    min-width: 126px;
    width: 100%;
    outline: transparent;
    border: none;
    border-radius: 6px;
    background: $gray;
    color: $lightblue;
    text-align: left;
    font-weight: 500;
    font-size: 0.8rem;
    transition: all 0.3s;
    gap: 8px;

    &:hover {
      background-color: $bluegray;
    }
  }

  &__close {
    @extend .idf-field-dropdown__item;
    justify-content: center;
    margin-bottom: 0;
    padding: 5px;
    background-color: $orange;
    color: #fff;

    &:hover {
      background-color: color.adjust($orange, $lightness: -15%);
      color: #fff;
    }
  }
}

.idf-criteria-function {
  position: relative;
  margin-top: 12px;
  padding: 24px 12px 12px !important;

  &__type {
    position: absolute;
    top: -16px;
    left: 12px;
    z-index: 12;
    padding: 5px 3px 0;
    border: 1px solid $bluegray;
    border-radius: 32px;
    background-color: #fff;

    vaadin-combo-box {
      max-height: 22px !important;
    }
  }

  &__fields {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    border: 1px solid $bluegray;
    border-radius: 6px;
    gap: 8px;

    .bi {
      color: #c3d2e1;
    }
  }

  &__input {
    position: relative;
    display: inline-flex;

    .idf-field-dropdown__item {
      margin-bottom: 0;
    }
  }
}

.idf-add-criteria-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
  }

  &__right {}
}

.idf-criteria-value {
  &__change {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 4px;
    background-color: $bluegray;
    color: $lightblue;
    line-height: 1;
    transition: color 0.3s, background-color 0.3s;
    @include md() {
      width: 36px;
      height: 36px;
    }

    .bi {
      color: $lightblue;
      transition: color 0.3s;
    }

    &:Hover {
      background-color: $lightblue;
      color: #fff;

      .bi {
        color: #fff;
      }
    }
  }
}

@use "../../utilities/index" as *;

.idf-details-page {
  @media only screen and (max-width: 1160px) {
    .idf-title-wrapper {
      padding: 5px 10px!important !important;

      .idf-row {
        flex-direction: column;
      }
    }

    .idf-details-panel {
      padding: 15px 10px 18px 10px !important;
      height: auto!important;
    }

    .idf-legacy-quote {
      align-self: flex-start!important;
      margin: 0 12px 3px!important !important;
      font-size: 0.75rem;
    }

    .idf-title {
      margin: 5px 12px !important;
    }

    .idf-details-right {
      width: 100px!important;
    }

    .idf-details-left {
      width: calc(100% - 100px)!important;
    }

    .idf-score {
      font-size: 42px!important;
    }
  }
  //Status Color Coding
  .idf-title {
    &.idf-EXPIRED {
      color: $flatblue;
    }

    &.idf-ACTIVE {
      color: $green;
    }

    &.idf-CANCELLED {
      color: $red;
    }
  }

  .idf-legacy-quote {
    align-self: flex-end;
    margin-bottom: 9px;

    span {
      opacity: 0.65;
      transition: opacity 0.4s ease;
    }

    &:hover span {
      opacity: 1;
    }
  }

  .idf-details-agent {
    position: relative;
    width: 100%;

    .idf-agent-wrapp {
      width: 100%;
    }

    .idf-agent-content {
      display: flex;
      padding: 20px 20px 15px;
      width: 100%;
      border-top: 1px solid $bluegray;

      & > div {
        flex: 1;
        margin-right: 30px;
      }
    }

    .idf-data p {
      margin-bottom: 7px;
    }

    .idf-email {
      text-transform: lowercase;
    }

    .idf-toggle {
      --iron-icon-width: 18px;
      --iron-icon-height: 18px;
      --shadow-transition_-_transition: #{$btnanimation};
      @include allcapslabel();
      position: absolute;
      bottom: 0;
      left: 50%;
      padding: 1px 12px 1px 5px;
      border: 1px solid $bluegray;
      border-radius: 3px;
      background: white;
      color: $flatblue;
      line-height: 2;
      transform: translate(-50%, 50%);

      &.idf-active {
        border-color: $lightblue;
        color: $lightblue;

        iron-icon {
          transform: rotateX(180deg);
        }
      }

      &:hover {
        color: $blue!important;
      }

      iron-icon {
        animation: #{$btnanimation};
      }
    }
  }
  //tables
  .idf-claims-data {
    $columns: 7% 9.5% 26% 10% 17% 7% 1fr 37px;
    @include mediumTable($columns);
  }

  .idf-risk-profile-data {
    $columns: 1fr 13% 13% 13% 12% 10% 16% 10% 37px;
    @include mediumTable($columns);
    //current rec
    .idf-row {
      position: relative;

      &:after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        width: 4px;
        border-radius: 8px;
        background: $legacy;
        content: '';
      }

      .idf-rec-type {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        width: 10px;
      }

      &.idf-current:after {
        background: $green;
      }
    }
  }

  .idf-cases-data {
    $columns: 7% 10% 9% 14% 11% 11% 12% 12% 1fr 37px;
    @include mediumTable($columns);
  }

  .idf-drivers-data {
    $columns: 1fr 14% 10% 16% 10% 15% 10% 37px;
    @include mediumTable($columns);
  }

  .idf-vehicles-data {
    $columns: 150px 1fr 10% 14% 14% 14% 6% 37px;
    @include mediumTable($columns);
  }

  .idf-incidents-data {
    $columns: 1fr 20% 20% 20% 20% 37px;
    @include mediumTable($columns);
  }

  .idf-phones-data {
    $columns: 1fr 36% 37px;
    @include mediumTable($columns);
  }

  .idf-addresses-data {
    $columns: 1fr 20% 10% 10% 15% 37px;
    @include mediumTable($columns);
  }

  .idf-endorsements-data {
    $columns: 20% 23% 23% 20% 15%;
    @include mediumTable($columns);
  }
}

// asside

.idf-aside-content .idf-slot {
  .idf-is-overwrited .idf-details-summary {
    background: $bodytext !important;
  }

  .idf-risk-rules {
    margin-top: 1.8em;

    .idf-row {
      align-items: flex-end;

      .idf-all-caps-label {
        margin-left: 5px;
      }
    }

    & > .idf-row {
      justify-content: space-between;

      .idf-btn {
        margin-bottom: 15px;
      }
    }

    .idf-white {
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }

    .idf-title {
      margin-bottom: 1em;
    }

    .idf-rule-description {
      margin-bottom: 1.8em;
    }

    .idf-rules-list .cellListEvenItem,
    .idf-rules-list .cellListOddItem {
      margin-bottom: 20px;
    }

    .idf-list-details .idf-list-detail {
      margin-bottom: 0.55em;
    }

    .idf-list {
      margin-bottom: 2.3em;

      header {
        margin-bottom: 1.25em;
      }

      .idf-list-details {
        margin-bottom: 1em;
      }

      &.idf-qa-list {
        margin-bottom: 0;
      }
    }

    .idf-aside-content {
      padding: 0;
    }

    .idf-aside-steps {
      .idf-title {
        margin-bottom: 0;
      }
    }
  }
}
//Extra
/* Case Details Space */
.idf-case-details .idf-risk-rules {
  margin-top: 0!important;
}

.idf-policy-view {
  .idf-claims-modal {
    .idf-modal__body {
      margin: 0;
      padding-top: 0;
    }

    .idf-info-item__value {
      font-size: 0.8rem;
    }

    .idf-detail-data {
      &__header {
        border-top: none;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &__content {
        background: $gray;
      }
    }

    .idf-info-item {
      font-size: 0.8rem;
    }

    .idf-overflow-table {
      overflow-x: auto;
      background-color: $gray;

      .idf-table {
        .idf-header {
          padding-right: 4px;
          padding-left: 4px;
          border-radius: 0;
        }

        .idf-table-content {
          padding: 12px;
          background-color: $gray;

          .idf-flex-table {
            margin-top: 5px;
            border-radius: 4px;
            background-color: #fff;
            font-size: 0.8rem;
          }
        }
      }

      .idf-warning {
        display: block;
        margin-top: 5px;
        padding: 15px;
        border-radius: 4px;
        background-color: $flatblue;
        color: $darkblue;
      }
    }

    .idf-policy-detail-modal-section {
      margin-top: 30px;
      border: 1px solid $bluegray;
      border-radius: 6px;
    }
  }

  .idf-policy-contact-table {
    $columns: 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr;
    @include bigTable($columns);
  }

  .idf-policy-payment-table {
    $columns: 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr;
    @include bigTable($columns);

    .idf-flex-row {
      flex: auto !important;
      min-width: 100px !important;
    }
  }

  .idf-policy-note-table {
    $columns: 1fr, 1fr;
    @include bigTable($columns);
  }

  .idf-policy-paymentAgg-table {
    $columns: 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr;
    @include bigTable($columns);

    .idf-flex-row {
      flex: auto !important;
      min-width: 100px !important;
    }
  }
}

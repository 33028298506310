@use "../utilities/index" as *; /* Checkbox */

input[type="checkbox"].idf-checkbox {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

input[type="checkbox"].idf-checkbox + label {
  position: relative;
  display: block;
  padding-left: 1.5rem;
  cursor: pointer;
}

input[type="checkbox"].idf-checkbox + label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.15em;
  background: $darkgray;
  content: "";
  transition: background 0.15s ease;
}

input[type="checkbox"].idf-checkbox:disabled + label {
  color: $darkgray;
}

input[type="checkbox"].idf-checkbox:disabled + label,
input[type="checkbox"].idf-checkbox:disabled + label:before,
input[type="checkbox"].idf-checkbox:disabled + label:after {
  opacity: 0.6;
  cursor: not-allowed;
}

input[type="checkbox"].idf-checkbox:checked + label:before {
  background: $lightblue;
}

input[type="checkbox"].idf-checkbox:disabled:checked + label:before {
  background: #609DF2;
}

input[type="checkbox"].idf-checkbox + label:after {
  position: absolute;
  top: 0.2rem;
  left: 0.15rem;
  color: $gray;
  content: "\f00c";
  /* fa-check */
  font-size: 0.6rem;
  /* Font Awesome */
  font-family: "FontAwesome";
  transition: transform 0.2s $ease;
  transform: scale(0) rotate(90deg);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="checkbox"].idf-checkbox:checked + label:after {
  transform: scale(1) rotate(0deg);
}

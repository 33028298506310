@use "utilities/index" as *;

.idf-content-panel .idf-advanced-analytics-timeline {
  canvas {
    font-size: 1.6rem;
  }

  .idf-graph-tools {
    margin: 10px;

    paper-icon-button {
      padding: 13px;
      width: 47px;
      height: 47px;
      background: transparent;
      transition: all 0.2s ease;

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }
    }

    paper-icon-button + paper-icon-button {
      border-top: 1px solid #e5e5e5;
    }
  }

  .idf-graphic-view-wrapper {
    position: relative;
  }

  .idf-graphic-view {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: -50px;
    border: 1px solid $darkgray;
    background: white;

    .idf-cards-select {
      position: absolute;
      top: 10px;
      right: 0;
      z-index: 100;
      overflow: hidden;
      padding: 8px 12px;
      border-radius: 3px;
      background: white;
      font-weight: 600;
      opacity: 0;
      cursor: pointer;
      transition: transform 0.2s ease-out, opacity, 0.2s ease-out;
      transform: translate3d(5px, 0, 0);

      .idf-select-label {
        @include allcapslabel();
        color: $blue;
      }

      &:hover {
        background: $blue;
        color: #fff;

        .idf-select-label {
          color: #fff;
        }
      }
    }

    .idf-node.idf-cards-selected .idf-cards-select,
    .idf-node:not(.idf-cards-selected):hover .idf-cards-select {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    .idf-node.idf-cards-selected .idf-cards-select {
      bottom: 5px;
      padding: 0;
      width: 3px;
      background-color: $blue;

      .idf-select-label {
        opacity: 0;
      }
    }

    .idf-node {
      position: relative;
    }

    #timelineComponent {
      padding-top: 0;
      height: 50px;
      border-bottom: 1px solid $darkgray;

      .yfiles-svgpanel > div:nth-child(2),
      .yfiles-svgpanel > div:nth-child(3) {
        display: none !important;
      }
    }

    #graphComponent {
      height: 75vh;

      .yfiles-svgpanel > div:nth-child(3),
      .yfiles-svgpanel > div:nth-child(4) {
        display: none !important;
      }

      text {
        @include boldtext();
        font-weight: 700;
        font-family: "open sans", "Helvetica Neue", sans-serif;
        opacity: 0.85;
      }
    }
  }

  .idf-timeline-tools {
    @include box-shadow;
    position: relative;
    top: -20px;
    left: 10px;
    z-index: 1;
    display: inline-flex;
    overflow: hidden;
    padding: 10px;
    border: 1px solid $darkgray;
    border-radius: 4px;
    background-color: #fff;

    &__row {
      display: inline-flex;
      margin-left: 0;
      transition: margin-left 0.3s ease-in-out;
      gap: 10px;

      &.is-visible {
        .idf-btn.is-active {
          background: rgba($orange, 0.1);
          color: $orange;
        }
      }
    }

    &__filters {
      display: flex;
      justify-content: space-between;
      gap: 4px;

      .idf-btn {
        margin: 0;
        padding: 10px 12px;
        background-color: #fff;
        font-size: 0.8rem;
        line-height: 1.5;

        &:hover {
          background: rgba($orange, 0.1);
          color: $orange;
        }
      }
    }

    &__divider {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      text-align: center;

      &:before {
        position: absolute;
        top: 0;
        left: 9px;
        z-index: 1;
        width: 1px;
        height: 100%;
        background: #e5e5e5;
        content: "";
      }

      span {
        position: relative;
        z-index: 4;
        display: block;
        padding-top: 4px;
        padding-bottom: 3px;
        background: #fff;
        color: $darkgray;
        font-size: 0.7rem;
      }
    }

    &__toggle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
      min-width: inherit;
      width: 30px;
      border-left: 1px solid $darkgray;
      transition: color 0.3s ease-in-out;

      .bi {
        font-size: 20px;
        transition: transform 0.3s ease-in-out;
      }

      &:hover {
        color: $orange;
      }

      &.is-active {
        margin-left: -11px;
        width: 37px;

        .bi {
          transform: rotate(-180deg);
        }
      }
    }

    .idf-date-range {
      --vaadin-text-field-default-width: 135px;
      --lumo-font-size-m: 13px;
      --lumo-contrast-10pct: #dfe6ec;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      vaadin-date-piker {
        @include box-shadow;
      }
    }
  }

  .idf-list-view {
    .idf-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .idf-dl-number {
      margin-bottom: 0.1em;
    }

    .idf-premium-due .idf-name i:before {
      @include allcapslabel();
      position: absolute;
      top: 10px;
      left: 24px;
      color: $red;
      content: "$";
      font-style: normal;
      font-size: 0.95rem;
    }

    .idf-unpaid-amount {
      display: block;
      margin-top: -1px;
      margin-left: 3.5px;
      width: 16px;
      height: 16px;
    }

    .idf-mvr-status.idf-link {
      @include allcapslabel();
      float: right;
    }

    .cellListEvenItem,
    .cellListOddItem {
      position: relative;
      padding: 1.2em;
      width: 16rem;
      background: white;
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 0 $lightblue;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 0 $lightblue;
      -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

      &.cellListSelectedItem {
        -webkit-animation: selectedCell 0.3s forwards;
        animation: selectedCell 0.3s forwards;
      }

      & > div {
        .idf-analytics-history {
          position: absolute;
          right: 0;
          bottom: -5em;
          left: 0;
          display: none;
          height: 0;
          opacity: 0;
          pointer-events: none;

          header {
            padding: 0.6em 1em;
            background: $gray;
          }

          section {
            padding: 1em;
            height: 100%;
            background: white;

            .idf-analytics-log {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              justify-content: space-between;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;

              label {
                @include bodytext();
                margin: 0;
              }

              .idf-analytics-paid {
                color: $green;
              }

              .idf-analytics-storno {
                color: $red;
              }
            }
          }
        }

        &.idf_visible {
          .idf-analytics-history {
            z-index: 999;
            display: block;
            height: 5em;
            -webkit-box-shadow: 0 4px 30px -24px black;
            box-shadow: 0 4px 30px -24px black;
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
      }

      .idf-data {
        margin-top: 0.8em;
        width: 100%;
      }
    }

    .simplebar-content > div {
      display: grid;
      padding-top: 1rem;
      padding-bottom: 0.8rem;
      grid-gap: 10px;
      grid-auto-flow: column;
      grid-auto-columns: 16rem;
    }

    .idf-name {
      @include boldtext();
      float: left;
      color: $darkblue;
      text-transform: uppercase;

      i {
        display: inline-block;
        margin-right: 0.1rem;
        width: 1em;
        height: 1em;
        background: no-repeat center;
        vertical-align: middle;
      }

      .idf-icon-m {
        background-image: url("../img/graph/node-type/man.svg");
      }

      .idf-icon-f {
        background-image: url("../img/graph/node-type/woman.svg");
      }

      .idf-icon-vehicle {
        background-image: url("../img/graph/node-type/vehicle.svg");
      }

      .idf-icon-commercial {
        background-image: url("../img/commercial.svg");
      }

      .idf-icon-salvage {
        background-image: url("../img/salvage.svg");
      }

      .idf-icon-address {
        background-image: url("../img/graph/node-type/address.svg");
      }

      .idf-icon-phone {
        background-image: url("../img/graph/node-type/phone.svg");
      }

      .idf-icon-claim {
        background-image: url("../img/graph/node-type/claim.svg");
      }
    }

    .idf-state {
      @include allcapslabel();
      float: right;
      padding: 0.2em 0.4em;
      min-width: 2em;
      background: $lightblue;
      color: white;
    }
  }

  summary {
    padding: 15px 0 5px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }

  .idf-item-details {
    padding: 17px;

    .idf-list-details {
      display: flex;
      justify-content: space-between;
    }

    .idf-list-detail {
      align-items: center;
      margin: 0 5px;

      span {
        margin-right: 3px;
      }

      .idf-all-caps-label {
        color: $flatlightblue;
        font-weight: bold;
      }
    }

    .idf-item-type {
      margin: 0 0 10px;

      span {
        opacity: 0.72;
      }

      .idf-type-tag {
        text-transform: capitalize;
        opacity: 1;
      }
    }
  }
  //Search
  details {
    position: relative;
  }

  .idf-search-toggle {
    position: absolute;
    top: 2px;
    left: 100px;
    width: 36px;
    height: 36px;
    border: 1px solid transparent;

    &.idf-active {
      border: 1px solid #dfe6ec;
      border-bottom: none;
      border-radius: 2px;
      background: white;
    }
  }

  .idf-search-tab {
    .idf-card {
      align-items: center;
      max-width: 580px;
      width: 100%;
    }

    paper-input {
      margin-top: -11px;
      margin-right: 15px;
    }

    vaadin-combo-box {
      margin-right: 15px;
      max-width: 120px;
    }

    paper-button {
      display: flex;
      width: 165px;
    }
  }
}
@-webkit-keyframes selectedCell {
  100% {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1.5px $blue;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1.5px $blue;
  }
}
@keyframes selectedCell {
  100% {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1.5px $blue;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1.5px $blue;
  }
}
@-webkit-keyframes popUp {
  100% {
    min-width: 355px;
    width: auto;
    height: auto;
    opacity: 1;
    pointer-events: all;
  }
}
@keyframes popUp {
  100% {
    min-width: 355px;
    width: auto;
    height: auto;
    opacity: 1;
    pointer-events: all;
  }
}

.idf-node-popup-details {
  position: absolute;
  padding: 3px 18px 3px 13px;
  min-width: 0;
  width: 355px;
  height: 105px;
  border-radius: 3px;
  background-color: $lightblue;
  color: white;
  opacity: 0;
  -webkit-transition: transform 0.2s cubic-bezier(0.44, -0.13, 0.17, 1.21), opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
  transform-origin: top left;
  pointer-events: none;

  &:not(.idf-visible) {
    .simplebar-scrollbar:before {
      background: transparent;
    }
  }

  &.idf-visible {
    z-index: 2;
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }

  &.idf-accident {
    background-color: $red;
  }

  &.idf-underwrite {
    .simplebar-content > header > span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        display: inline-block;
        margin-top: -1px;
        margin-bottom: 5px;
      }

      label {
        display: inline-block;
      }
    }

    footer {
      text-align: left;
    }

    .simplebar-scrollbar:before {
      background: #bfcdd9;
    }
  }

  &.idf-claims,
  &.idf-endorsments,
  &.idf-incidents,
  &.idf-quotes {
    height: 206px;

    & > div {
      margin-right: -15px;
      height: 199px;
    }

    .simplebar-scrollbar:before {
      background: #bfcdd9;
    }
  }

  &.idf-endorsment {
    height: 119px;

    & > div {
      margin-right: -15px;
      height: calc(100% - 5px);
    }

    .simplebar-scrollbar:before {
      background: #bfcdd9;
    }

    .simplebar-content > div {
      margin: 5px 15px 5px 0;
      padding: 10px;
      border-radius: 3px;
    }

    footer {
      margin-top: 15px;
    }
  }

  &.idf-big,
  &.idf-expand-card {
    height: 306px;

    & > div {
      margin-right: -15px;
      height: 299px;
    }
  }

  .idf-active-policy {
    background: $darkblue;

    header .idf-right p {
      color: #57bb8a;
    }
  }

  .idf-cancel-policy {
    background: $darkblue;

    header .idf-right p {
      color: #e78b8b;
    }
  }
  //No Scroll
  & > footer,
  & > header {
    margin-right: -5px;
  }

  & > header {
    margin-top: 10px;
  }

  &.idf-claim {
    i {
      display: inline-block;
      margin-top: -0.3rem;
      margin-left: 0.3rem;
      width: 1.2em;
      height: 1.2em;
      background: no-repeat center;
      vertical-align: middle;
    }

    .idf-flag-icon {
      background-image: url("../img/flag.svg");
    }

    header label {
      @include allcapslabel();
      display: inline-block;
      margin-top: 4px;
      margin-bottom: 3px;
      color: white;
    }

    footer {
      padding: 10px 0 0;

      label {
        margin: 0 !important;
      }

      .idf-list-detail span {
        margin-left: 10px;
      }
    }
  }

  header {
    display: flex;
    flex-basis: unset;
    justify-content: space-between;

    label {
      cursor: default;
    }

    span:not(.idf-right) {
      p {
        margin: 8px 0 0;
        color: #9fabb6;
      }
    }

    .idf-right {
      label {
        @include bigboldtext();
        margin: 0;
        color: white;
        text-align: right;
        font-size: 1.15rem;
        cursor: default;
      }

      p {
        @include allcapslabel();
        margin-top: 2px;
        color: #9fabb6;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 0;

    .idf-list-detail {
      margin-bottom: 0;

      label {
        margin-left: 5px;
        color: rgba(255, 255, 255, 0.6);
      }

      span {
        display: inline-block;
        color: inherit;
      }
    }
  }

  .idf-event-details-list {
    & > div:not(.idf-idden) {
      display: block;
      margin-top: 6px;
      padding: 12px;
      border-radius: 3px;
      background: rgba(#000000, 0.2);

      .idf-bold-text:first-child {
        margin-top: 0;
      }
    }

    .idf-bold-text {
      margin-top: 12px;
      margin-bottom: 5px;
      opacity: 0.9;
    }

    p,
    span {
      margin: 0;
      line-height: 1.4;
      opacity: 0.7;
    }
  }
}

@use "../../utilities/index" as *;

.idf-investigate-info {
  .idf-add-wrapp {
    margin-bottom: 20px;

    vaadin-date-picker {
      margin-right: 20px;
      width: calc(50% - 10px);

      &+vaadin-date-picker {
        margin-right: 0;
      }
    }

    vaadin-combo-box {
      margin: 12px 0;
      width: calc(50% - 10px);
    }

    .idf-row vaadin-combo-box {
      margin: 0;
    }

    .idf-small {
      margin-top: 15px;
    }

    vaadin-upload {
      margin-top: 12px;
    }
  }

  .idf-team-leader {
    margin-top: 30px;
  }

  .idf-card {
    position: relative;
    margin: 5px 0 10px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
  }

  .idf-separator {
    margin: 0 0 20px;
  }

  .idf-item-actions {
    position: absolute;
    top: 14px;
    right: 16px;

    span {
      @include allcapslabel;
      margin-left: 15px;
      color: $blue;
      opacity: 0.65;
      cursor: pointer;
      transition: opacity 0.3s;

      &+span {
        color: $red;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
  //long text fix
  .idf-list-details .idf-list-detail span {
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

@use "../utilities/index" as *;

.idf-details-item {

  .idf-details-summary {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 18px;
    border-radius: 4px;
    background: $darkblue; //better visual distinction from table header
    cursor: pointer;
  }

  .idf-details-content {
    margin-top: -8px;
    padding: 26px 18px 18px;
    border: 1px solid $bluegray;
    border-radius: 4px;
  }
}

@use "../utilities/index" as *;

.idf-has-tooltip {
  position: relative;

  &:hover {
    .idf-tooltip {
      display: block;
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .idf-tooltip {
    @include allcapslabel();
    position: absolute;
    margin: 0;
    padding: 0.4em 1.1em;
    width: auto;
    border-radius: 4px;
    background-color: $lightblue;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.04);
    color: white;
    white-space: nowrap;
    font-style: normal;
    opacity: 0;
    transition: all 0.2s $ease, opacity 0.1s ease;
    transform: translateX(-20px);
    pointer-events: none;

    &:after {
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent $lightblue transparent transparent;
      content: "";
    }

    &--up {
      transform: translateY(-32px) !important;

      &:after {
        top: auto;
        right: 0;
        bottom: -12px;
        left: 0;
        margin: 0 auto;
        width: 1px;
        border-color: $lightblue transparent transparent transparent;
      }
    }
  }
}

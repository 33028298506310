@use "../utilities/index" as *;

.idf-big-table {
  overflow-x: auto;
  padding-bottom: 12px;

  table {
    width: 100%;
    font-size: 12px;
  }

  thead {
    th {
      padding: 12px 8px;
      min-width: 140px;
      border-bottom: 1px solid $bluegray;
      background-color: #ecf0f4;
      text-align: left;
      font-weight: 600;
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        td {
          background-color: #fafafa;
        }
      }
    }

    td {
      padding: 8px;
      min-width: 140px;
      border-bottom: 1px solid $bluegray;
      background-color: #fff;
      font-weight: 400;
    }
  }
}

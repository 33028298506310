// 7/9/2024 - @ercangvn
// spacing
$spacing-increment: 5px;
$max-spacing: 50px;
@function spacing-scale($index) {
  @return $index * $spacing-increment;
}
@mixin generate-spacing-classes($property, $shorthand) {
  @for $i from 1 through calc($max-spacing / $spacing-increment) {
    .#{$shorthand}b-#{$i * 5} {
      #{$property}-bottom: spacing-scale($i) !important;
    }

    .#{$shorthand}t-#{$i * 5} {
      #{$property}-top: spacing-scale($i) !important;
    }

    .#{$shorthand}l-#{$i * 5} {
      #{$property}-left: spacing-scale($i) !important;
    }

    .#{$shorthand}r-#{$i * 5} {
      #{$property}-right: spacing-scale($i) !important;
    }

    .#{$shorthand}y-#{$i * 5} {
      #{$property}-top: spacing-scale($i) !important;
      #{$property}-bottom: spacing-scale($i) !important;
    }

    .#{$shorthand}x-#{$i * 5} {
      #{$property}-left: spacing-scale($i) !important;
      #{$property}-right: spacing-scale($i) !important;
    }
  }
}
@include generate-spacing-classes(margin, m);
@include generate-spacing-classes(padding, p);
// layout
.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-none {
  display: none !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}
// text align

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-justify {
  text-align: justify !important;
}
// width

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}
// max-width

.max-w-100 {
  max-width: 100% !important;
}

.max-w-auto {
  max-width: auto !important;
}
// height

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}
// opacity

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}
// position

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

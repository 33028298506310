@use "../../utilities/index" as *;

.idf-powerbi-wrapper {
  width: calc(100% - 5px);
  height: calc(100vh - 130px);

  iframe {
    border: 1px solid $bluegray;
    border-radius: 6px;
  }
}

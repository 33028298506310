@use "../utilities/index" as *;
$table-header: $lightblue;
$table-header-border: $lightblue;
$table-border: $darkgray;
$row-bg: #f4f2f1;

.idf-table {
  /* IE grid placement support v.2 */
  @for $i from 1 through 18 {
    .idf-flex-table .idf-flex-row.idf-c-#{$i} {
      -ms-grid-column: $i;
    }
  }
  color: $darkblue;
  line-height: 1.3em;

  .idf-flex-table {
    display: flex;

    &.idf-header {
      @include boldtext();
      border-radius: 4px;
      background-color: $table-header;
      color: white;
      line-height: 1.05em;
    }

    &.idf-row {
      border-radius: 4px;
      background: none;
    }
  }

  .idf-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    overflow: hidden; //uneven columns fix
    padding: 0.8em;
    min-height: inherit; //IE Flexbox min-height issue fix
    -ms-flex-align: center;
  }

  .idf-header .idf-action,
  .idf-row .idf-action {
    padding: 0 !important;
  }

  .idf-row .idf-action-hover {
    opacity: 0;
  }

  .idf-row:hover .idf-action-hover {
    opacity: 1;
  }

  .idf-rowspan {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 100%;
  }

  .idf-column {
    padding: 0;
    width: 100%;

    .idf-flex-row {
      display: grid;
      padding: 0;
      width: 100%;
      border: 0;
      grid-template-columns: repeat(auto-fill, 33.3%);
      grid-template-rows: 100% 100% 100%;

      &:hover {
        background: #f5f5f5;
        transition: 500ms;
      }
    }
  }

  .idf-warning {
    display: block;
    padding: 40px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    opacity: 0.6;
  }

  .idf-bw {
    word-break: break-word;
  }

  .idf-disabled-row {
    opacity: 0.65;
  }

  .idf-gray-cell .idf-row {
    margin-top: 5px;
    background-color: $gray;
  }
  /*  Remove columns based on screen resolution */
  .idf-flex-table {
    @media only screen and (max-width: 1780px) {
      .idf-c-13 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1600px) {
      .idf-c-12 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1480px) {
      .idf-c-11 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1320px) {
      .idf-c-10 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1260px) {
      .idf-c-9 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1140px) {
      .idf-c-8 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1040px) {
      .idf-c-7 {
        display: none!important;
      }
    }
    //force visible
    .idf-flex-row.idf-action {
      display: flex!important;
      justify-content: flex-end;
      overflow: visible;
      margin-right: 2px;
      margin-left: auto;
      text-align: right;
    }
  }
  //Sortable table
  .idf-is-sortable {
    position: relative;
    cursor: pointer;

    &:before {
      width: 1em;
      height: 1em;
      background-image: url("../img/sort.svg");
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
      opacity: 0.4;
      transition: all 0.3s ease;
    }

    &:hover:before {
      opacity: 1;
    }

    &.idf-sort-asc {
      &:before {
        background-image: url("../img/sort-desc.svg");
        opacity: 0.8;
      }
    }

    &.idf-sort-desc {
      &:before {
        background-image: url("../img/sort-asc.svg");
        opacity: 0.8;
      }
    }
  }
}
/*  Remove columns based on screen resolution - FOR CONTAINER TABLE */

.idf-container {
  .idf-flex-table {
    @media only screen and (max-width: 1380px) {
      .idf-c-9 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1240px) {
      .idf-c-8 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1110px) {
      .idf-c-7 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1000px) {
      .idf-c-6 {
        display: none!important;
      }
    }
    @media only screen and (max-width: 1000px) {
      .idf-c-6 {
        display: none!important;
      }
    }
    //force visible
    .idf-flex-row.idf-action {
      display: flex!important;
      margin-left: auto;
    }
  }
}
//idf no data warning

.idf-no-data {
  .idf-header {
    display: none;
  }

  .idf-warning {
    display: block;
    padding: 0.7em 0.8em;
    border-radius: 4px;
    background-color: $flatblue;
    color: $darkblue;
  }

  .idf-gray-cell .idf-row {
    margin-top: 5px;
    background-color: $gray;
  }
}
//idf no data warning

.idf-no-data {
  /* IE grid placement support v.2 */
  @for $i from 1 through 18 {
    .idf-flex-table .idf-flex-row.idf-c-#{$i} {
      -ms-grid-column: $i;
    }
  }
  .idf-header {
    display: none;
  }

  .idf-warning {
    display: block;
    padding: 0.7em 0.8em;
    border-radius: 4px;
    background-color: $flatblue;
    color: $darkblue;
  }
}
//Contextual btn + menu

.idf-flex-table {
  .idf-action {
    position: relative;

    i.idf-contextual-menu-icon {
      display: block;
      float: right;
      margin-right: 4px;
      width: 2em;
      height: 100%;
      background-image: url("../img/contextual-menu.svg");
      background-position: center;
      background-size: 90%;
      background-repeat: no-repeat;

      &:hover {
        cursor: pointer;
      }
    }

    .idf-contextual-menu {
      min-width: 155px; //make it work for longer text
    }
  }
}

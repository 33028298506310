@use "../../utilities/index" as *;

.idf-dashboard-builder {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: calc(100vh - 100px);
  width: 100%;
  // height: 100%;
  gap: 0 !important;

  &:after {
    position: absolute;
    top: 172px;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 300px;
    height: calc(100% - 172px);
    border-left: 1px solid $darkgray;
    background: #fff;
    content: "";
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid $darkgray;
    background: #fff;
  }

  &__titleWrapper {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    paper-button {
      margin: 0;
      padding: 0;
      min-width: auto;

      i {
        font-size: 18px;
      }
    }

    & > button {
      padding: 0;
      border: none;
      background: none;
      line-height: 1;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: $orange;
      }
    }

    paper-input.idf-input {
      --paper-input-container_-_padding: 0 70px 0 0;
      width: 300px !important;
    }

    .idf-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 0;
      width: 60px;
      height: 28px;
      text-align: center;
      line-height: 28px;
    }
  }

  &__title {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

    paper-input {
      --paper-font-caption_-_line-height: 0 !important;
      width: auto !important;
    }

    &.is-active {
      input {
        border-color: $darkgray !important;
      }

      button {
        display: inline-block;
      }
    }
  }

  &__buttons {
    .idf-btn {
      margin-left: 10px;
    }
  }

  &__content {
    padding: 10px 10px 10px 0;
    width: calc(100% - 300px);
  }

  .idf-simple {
    padding: 10px;
  }

  .idf-widget-card {
    .idf-btn.idf-small {
      top: 8px;
      right: 4px;
    }
  }

  .col-lg-12,
  .col-lg-3,
  .col-lg-4,
  .col-lg-6,
  .col-lg-8,
  .col-lg-9 {
    padding-bottom: 30px;
  }

  .idf-widget-base {
    position: relative;
    height: 100%;

    & > div {
      height: 100%;

      .idf-widget-card {
        height: 100%;

        &.idf-stats {
          min-height: 25vh;
        }
      }
    }

    &__settings {
      display: none;
    }
  }

  .idf-impact-calculator-wrapper {
    height: 100%;

    .idf-container {
      height: 100%;

      .idf-impact-calculator {
        height: 100%;
      }
    }
  }

  .idf-top-connected-summary {
    padding: 5px;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 4px 25px rgba(0,0,0,.08);

    header {
      padding: 12px 5px 14px;

      label {
        margin: 0;
      }
    }
  }

  .idf-top-connected-summary .idf-table .idf-all-caps-label.idf-link {
    display: none;
  }

  .idf-impact-calculator-wrapper {
    .idf-impact-calculator {
      padding: 12px;
      border-radius: 6px;
    }

    .idf-stat-box {
      padding: 12px;
      width: 100%;

      &__bg {
        padding: 18px 12px;
      }

      &__title {
        margin-bottom: 12px;
        font-size: 1rem;
      }

      &__number {
        font-size: 2rem;
        line-height: 30px;

        small {
          font-size: 50%;
        }
      }

      &__list {
        margin: 12px 0 0;

        li {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__item {
        width: calc(100% - 85px);
      }

      &__result {
        width: 70px;
        font-size: 0.9rem;
      }
    }
  }

  .idf-case-widget__top {
    .bi {
      display: none;
    }
  }

  paper-dialog {
    overflow: hidden;
  }

  .idf-widget-options__button--export {
    display: none !important;
  }
}

.idf-select-dataset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid $darkgray;
  border-radius: 6px;

  &__column {
    padding: 15px;

    &--chart {
      width: 40%;
      border-right: 1px solid $darkgray;

      img {
        width: 100%;
      }
    }

    &--content {
      width: 60%;
    }

    &--wide {
      width: inherit;
    }
  }

  &__title {
    display: block;
    margin: -15px -15px 30px;
    padding: 15px;
    border-bottom: 1px solid $darkgray;
    color: $lightblue;
    font-size: 1rem;
  }

  figure {
    margin: 0;
    padding: 0;
  }
}

.widget-preview {
  position: fixed;
  z-index: 9999;
  padding: 12px;
  width: auto;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 25px rgba(0,0,0,.2);
  animation: previewFadeIn 0.15s;
  @keyframes previewFadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &__title {
    display: block;
    margin-bottom: 12px;
    color: $lightblue;
    font-weight: bold;
    font-size: 0.9rem;
  }

  &__image {
    img {
      max-width: 100% !important;
      width: 100%;
      border-radius: 6px;
    }
  }

  &.previewFadeOut {
    animation: previewFadeOut 0.15s;

    @keyframes previewFadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  }
}

.idf-slot{
  height: 100%;
}

@use "../../utilities/index" as *;

.idf-output-columns {
  .idf-all-caps-label {
    color: $darkblue;
  }

  .idf-output-wrapp {
    display: flex;
    flex-wrap: wrap;

    .idf-output-col {
      width: 25%;
    }

    p {
      margin: 19px 0 12px;
      color: $lightblue;
      font-size: 1rem;

      paper-checkbox {
        display: inline;
        padding-right: 2px;
        padding-left: 30px;
        color: #676a6c;
        vertical-align: bottom;
        font-weight: 500;
        font-size: 0.8rem;
      }
    }

    multiselect-combo-box {
      width: 90%;
    }

    paper-checkbox {
      --paper-checkbox-vertical-align: top;
      --paper-checkbox-margin: 2px 0 0 0;
      display: block;
      margin: 7px 0;
      padding-right: 40px;
    }
  }
}

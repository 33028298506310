@use "../utilities/index" as *;

.galleria-theme-classic {
  .galleria-info-text {
    @include box-shadow;
    border-radius: 8px;
    background-color: $lightblue;
  }

  .galleria-info-description {
    font-family: $openSans;
  }

  .galleria-info-close {
    top: 12px;
    right: 12px;
  }
}

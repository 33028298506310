@use "../../utilities/index" as *;

.yfiles-button {
    display: none;
}

.yfiles-canvascomponent .yfiles-scrollbar.yfiles-scrollbar-vertical {
    width: 7px;
}

.yfiles-canvascomponent .yfiles-scrollbar.yfiles-scrollbar-horizontal {
    height: 7px;
}

.yfiles-canvascomponent .yfiles-scrollbar .yfiles-scrollbar-range.yfiles-scrollbar-range-vertical {
    width: 7px;
    right: 0;
    top: 0px;
    bottom: 7px;
}
.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-horizontal .yfiles-scrollbar-slider{
    height:7px;
}
.yfiles-canvascomponent .yfiles-scrollbar .yfiles-scrollbar-range.yfiles-scrollbar-range-horizontal {
    height: 7px;
    left: 0;
    right: 7px;
    bottom: 0;
}

.yfiles-canvascomponent .yfiles-scrollbar.yfiles-scrollbar-vertical,
.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-vertical .yfiles-scrollbar-slider,
.yfiles-canvascomponent .yfiles-scrollbar.yfiles-scrollbar-horizontal,
.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-horizontal .yfiles-scrollbar-slider {
    background: transparent;
}

.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-horizontal .yfiles-scrollbar-slider .yfiles-scrollbar-slider-content,
.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-vertical .yfiles-scrollbar-slider .yfiles-scrollbar-slider-content {
    background: $darkblue;
    opacity: 0.3;
    transition: all .2s ease;
}

.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-vertical .yfiles-scrollbar-slider:hover,
.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-horizontal .yfiles-scrollbar-slider:hover {
    background: $darkblue;
    opacity: 1;
    transition: all .2s ease;
}

.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-horizontal .yfiles-scrollbar-slider .yfiles-scrollbar-slider-dragging,
.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-vertical .yfiles-scrollbar-slider .yfiles-scrollbar-slider-dragging {
    background: $darkblue;
    opacity: 1;
    transition: all .2s ease;
}

.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-horizontal .yfiles-scrollbar-slider .yfiles-scrollbar-slider-dragging,
.yfiles-canvascomponent .yfiles-scrollbar-range.yfiles-scrollbar-range-vertical .yfiles-scrollbar-slider .yfiles-scrollbar-slider-dragging {
    background: $darkblue;
    opacity: 1;
    transition: all .2s ease;
}


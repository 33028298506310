@use "../../utilities/index" as *;
@mixin vinCardView($responsive) {
  .idf-c-2,
  .idf-c-3 {
    display: none;
  }

  .simplebar-content > div {
    width: 100%;
  }

  .simplebar-content > div > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
    
    @if $responsive == true {
      @media only screen and (min-width: 1650px) {
        & > div {
          width: calc(20% - 20px);
        }
      }
      @media only screen and (max-width: 1650px) {
        & > div {
          width: calc(25% - 20px);
        }
      }
      @media only screen and (max-width: 1400px) and (min-width: 1100px) {
        & > div {
          width: calc(33.33% - 20px);
        }
      }
      @media only screen and (max-width: 1100px) {
        & > div {
          width: calc(50% - 20px);
        }
      }
      @media only screen and (max-width: 630px) {
        & > div {
          width: calc(100% - 20px);
        }
      }
    }

    > div {
      margin: 10px;
      padding-bottom: 36px;
      width: calc(50% - 20px);
    }
  }

  .idf-table-content {
    height: 100%!important; //cards Container

    .idf-row {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 0;
      height: calc(100% + 36px);

      &:hover {
        background: white;
      }

      .idf-only-for-card {
        display: block;
      }

      .idf-list-details {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 20px;

        span {
          margin: 0 0.3rem 0 0;
        }
      }

      i.idf-contextual-menu-icon {
        margin-right: -4px;
        height: 30px;
      }

      .idf-view-more {
        @include allcapslabel();
        color: $blue;
        line-height: 30px;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      .idf-flex-row {
        padding: 0;
        border: none;
      }

      .idf-title {
        padding-left: $cardpadding;
        width: auto;
        color: $darkblue;
        font-weight: $bold;
        font-size: 1.2em;
        line-height: 1.35;
      }

      .idf-c-1 {
        flex: initial;
        padding-top: $cardpadding;
        line-height: 0.8;
      }

      .idf-c-5 {
        flex: initial;
        padding-top: $cardpadding * 0.7;
      }

      .idf-c-6 {
        padding-bottom: $cardpadding;
      }

      .idf-c-1,
      .idf-c-4,
      .idf-c-5,
      .idf-c-6 {
        padding-left: $cardpadding;
        width: auto;
      }

      .idf-vin-pic {
        position: absolute;
        top: $cardpadding * 0.8;
        right: $cardpadding * 0.8;
        display: inline-block;
        width: 100px;
        height: clamp(56%, 100px, 53%);
        border-radius: 4px;
        background-position: center!important;
        background-size: cover!important;
      }

      .idf-action {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 2px $cardpadding!important !important;
        width: 100%;
        border: 1px solid $bluegray;
        border-radius: 4px;
        background: $gray;
      }
    }
  }
}

.idf-vin-search {
  $columns: 1fr 13% 20% 12% 11% 11% 85px;
  @include bigTable($columns);
  @include filterVisible();
  @media only screen and (max-width: 960px) {
    //only cards
    @include vinCardView(false);
  }
  @media only screen and (max-width: 630px) {
    //only cards
    @include vinCardView(false);

    .simplebar-content > div > div > div {
      width: calc(100% - 20px);
    }
  }
  //Card
  .idf-card-view {
    @include vinCardView(true);
  }
}

@use "../../utilities/index" as *;

.idf-global-search.idf-list-view {
  overflow: hidden;
  margin: auto;
  padding: 0 !important;
  width: auto !important;
  height: inherit;

  .idf-container.idf-row {
    height: 100%;
  }

  .idf-results-container {
    margin-right: 10px;

    .idf-warning {
      display: block;
      padding: 40px 0;
      text-align: center;

      p {
        margin: 7px auto;
        max-width: 220px;
        color: $flatlightblue;
        line-height: 1.55em;
      }

      .idf-title {
        margin-top: 2px;
        max-width: 256px;
        letter-spacing: 0.4px;
        font-weight: $bold;
        font-size: 19px;
      }
    }
  }

  .idf-search-by {
    position: absolute;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    div {
      margin-right: 6px;
    }

    span {
      font-weight: $bold;
      opacity: 0.6;
    }

    .idf-all-caps-label {
      display: inline-block;
      padding: 0.3em 0.55em;
      border-radius: 2px;
      background: $bluegray;
      color: $lightblue;
      transition: all 0.3s ease;

      &:hover {
        color: $blue;
        cursor: pointer;
      }

      &.idf-active {
        background: $lightblue;
        color: $bluegray;
      }
    }

    .idf-bold-text {
      margin-right: 10px;
    }
  }

  .idf-scroll-wrapper {
    margin-top: 30px;
    height: calc(100% - 30px);
  }

  .idf-results-container {
    flex: 1;
  }

  .idf-filters-container {
    max-width: 410px;
    width: 100%;

    .idf-search-filters {
      padding: 30px;

      .idf-btn {
        margin-top: 7px;
      }

      .idf-btn {
        margin-left: 0;
      }
    }

    .idf-filter-actions {
      margin-top: 12px;
    }

    .idf-main-fields {
      align-items: flex-end;

      .idf-btn {
        margin-bottom: 3px;
        margin-left: 10px;
        width: 100px; //flex width
        line-height: 36px;
      }

      paper-icon-button {
        padding: 0;
        width: 12px;
        height: 12px;
      }
    }

    .idf-extra-actions {
      margin: 0 0 -20px;

      .idf-link {
        margin-right: 0;
        padding: 0;
        width: calc(50% - 5px);
        text-align: center;
      }

      .idf-red {
        margin-right: 10px;
      }
    }

    paper-button {
      display: flex;

      paper-icon-button {
        margin-right: 5px;
        padding: 0;
        width: 13px;
        height: 13px;
      }
    }
  }

  .idf-search-filters {
    position: sticky;
    top: 0;
  }
  //result item
  .idf-result-item {
    $iconSize: 24px;
    $iconSpace: 70px;
    $sidePadding: calc($iconSpace / 2) - calc($iconSize / 2);
    position: relative;
    margin-right: 12px;
    margin-bottom: 6px;
    padding: 0;

    &:before {
      position: absolute;
      top: calc(50% - #{$iconSize} / 2);
      left: $sidePadding;
      width: $iconSize;
      height: $iconSize;
      background-position: center;
      background-size: $iconSize;
      background-repeat: no-repeat;
      content: '';
    }
    //Icon fo every Type
    &.idf-policy:before {
      background-image: url("../img/graph/node-type/policy.svg");
    }

    &.idf-address:before {
      background-image: url("../img/graph/node-type/address.svg");
    }

    &.idf-person:before {
      background-image: url("../img/graph/node-type/person.svg");
    }

    &.idf-vehicle:before {
      background-image: url("../img/graph/node-type/vehicle.svg");
    }

    .idf-row {
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 2% $sidePadding 2% $iconSpace;
      border: 2px solid transparent;
      transition: border-color 0.4s ease-out;
    }

    .idf-date {
      transition: opacity 0.5s ease-out;
      transition-delay: 0.15s;
    }

    .idf-btn {
      position: absolute;
      right: $sidePadding;
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      .idf-btn {
        opacity: 1;
        pointer-events: all;
      }

      .idf-date {
        opacity: 0;
        transition-delay: 0.0s;
      }

      .idf-row {
        border-color: $flatblue;
        cursor: pointer;
      }
    }
    //Item Title
    .idf-important {
      font-size: 1.1rem;
      line-height: 1.3;
    }
  }

  .idf-saved-search-list {
    .idf-all-caps-label {
      position: absolute;
      right: 0;
      color: $blue;
      opacity: 0.6;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
  //FILTERS
  .idf-separator {
    @include allcapslabel();
    position: relative;
    display: block;
    margin: 30px 0 13px;
    width: 100%;
  }

  .idf-filter {
    display: flex;
    flex-flow: wrap;

    & > * {
      margin-bottom: 15px;
      width: 100%;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    .idf-split {
      width: calc(50% - 10px);

      &:nth-child(2n+1) {
        margin-left: 20px;
      }
    }
  }
}

.idf-save-search {
  paper-dialog {
    min-width: 400px;
  }

  paper-input {
    margin-top: -7px;
    margin-bottom: -8px;
  }

  .idf-actions {
    display: flex;

    .idf-btn {
      width: 50%;
      text-align: center;
    }
  }
}

@use "../../utilities/index" as *;

.idf-uw-review-import {
  .idf-fields-wrapp {
    .idf-warning {
      display: none;
    }

    vaadin-combo-box {
      width: 180px;
    }

    .idf-warning-msg {
      display: block;
      margin-top: 4px;
      margin-left: 2px;
    }

    &.idf-disabled {
      cursor: not-allowed;

      .idf-fields {
        padding: 10px;
        border: 1px solid red;
        border-radius: 3px;

        & * {
          pointer-events: none;
        }
      }

      .idf-warning {
        display: block;
      }
    }

    .idf-row {
      align-items: flex-end;
      margin-bottom: 20px;
    }

    .idf-btn {
      margin-top: 20px;
    }
  }

  section {
    margin-top: 45px;
  }

  .idf-import-history {
    $columns: 20% 13% 29% 1fr 10%;
    @include fixTable($columns);

    .idf-row {
      margin-top: 5px;
      background: $gray;
    }

    .idf-status {
      flex-direction: column;
      align-self: center;
    }

    .idf-SUBMITTED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $lightblue;
    }

    .idf-RUNNING i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $blue;

      &:before {
        position: absolute;
        display: table;
        border-radius: 50%;
        background-color: $blue;
        content: "";
        animation: pointPulse 900ms ease-in-out infinite;
      }
    }

    .idf-FINISHED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $green;
    }

    .idf-FAILED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $red;
    }

    .idf-CANCELED i {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $flatblue;
    }

    span.idf-link {
      float: right;
      margin: 15px 0;
    }
  }
}

@use "../../utilities/index" as *;

.idf-dashboard {
  .idf-dashboard-toolbox {
    position: sticky;
    top: 0;
    // position: fixed;
    right: 0;
    overflow-y: auto;
    margin: 0 !important;
    padding: 10px 0 !important;
    width: 300px;
    height: auto !important;
    // min-height: 100%;
    background: #fff;
  }
}

.idf-dashboard-toolbox {
  position: relative;
  z-index: 10;
  border-left: 1px solid $darkgray;

  &__nav {
    padding: 0 10px;
    border-bottom: 1px solid $darkgray;
  }

  &__search {
    padding: 10px;

    paper-input {
      margin-top: -10px;
    }
  }

  &__button {
    position: relative;
    bottom: -1px;
    z-index: 1;
    margin-right: 6px;
    padding: 8px 24px;
    border: 1px solid $darkgray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #fff;
    color: $darkblue;
    font-weight: 600;
    transition: color 0.3s;

    strong {
      font-weight: 600;
    }

    &:hover {
      color: $orange;
    }

    &.is-active {
      border-bottom-color: transparent;
      color: $orange;
    }
  }

  &__panel {
    display: none;

    &.is-active {
      display: block;
    }

    &--layout {
      .idf-widget-item__preview {
        display: none;
      }
    }

    &--widgets {
      padding-bottom: 60px;
    }
  }

  &__widgetButton {
    position: fixed;
    right: 60px;
    bottom: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 5px;
    width: 230px;
    line-height: 1.5;

    svg {
      position: relative;
      top: -1px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}

.idf-widget-list {
  &__item {}
}

.idf-dashboard-toolbox-list {
  margin: 0;
  list-style-type: none;

  .idf-dashboard-toolbox-list:first-child {
    .idf-dashboard-toolbox-list__button {
      border-top: 1px solid $darkgray;
    }
  }

  &__item {
    // &:first-child {
    //   .idf-dashboard-toolbox-list__button {
    //     border-top: 1px solid $darkgray;
    //   }
    // }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    width: 100%;
    outline: transparent;
    border: none;
    border-bottom: 1px solid $darkgray;
    background: #fff;
    color: $darkblue;
    font-weight: 600;
    font-size: 14px;
    transition: color 0.3s;

    strong {
      font-weight: 600;
    }

    .icon-chevron-right {
      font-size: 0.7rem;
      transition: transform 0.3s;
    }

    &:hover {
      color: $orange;
    }

    &.is-active {
      color: $orange;

      .icon-chevron-right {
        transform: rotate(90deg);
      }
    }
  }

  &__number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $darkgray;
    font-size: 10px;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@use "sass:color";

// colors
$darkblue: #2f4050;
$lightblue: #425a70;
$flatlightblue: #8F99A3;
$green: #2EAA7D;
$gray: #F3F5F7;
$darkgray: #e5e5e5;
$hovercolor: rgba(0, 0, 0, 0.05);
$activecolor: rgba(0, 0, 0, 0.12);
$bodytext: #676a6c;
$blue: #0878CF;
$red: #AA2E2E;
$flatblue: #BFCDD9;
$bluegray: #DFE6EC;
$legacy: #FFCC00;
$accent: #888DFF;
$teal: #00D9AF;
$bold: 600;
$orange: #CE4D16;
$white-1: rgba(255,255,255,.1);
$white-2: rgba(255,255,255,.2);
$white-3: rgba(255,255,255,.3);
$white-4: rgba(255,255,255,.4);
$white-5: rgba(255,255,255,.5);
$black-1: rgba(0,0,0,.1);
$black-2: rgba(0,0,0,.2);
$black-3: rgba(0,0,0,.3);
$black-4: rgba(0,0,0,.4);
$black-5: rgba(0,0,0,.5);

// animation
$ease: cubic-bezier(0.18, 0.89, 0.68, 1.3); //cubic-bezier(0.175, 0.885, 0.52, 1.775);
$btnanimation: all 0.25s ease-in-out;

// container
$container: 1190px;
$cardpadding: 20px;

// typography
$openSans: "open sans","Helvetica Neue",sans-serif;

@use "../../utilities/index" as *;

.idf-policy-audit {
    .idf-scroll-slot{
        height: inherit;
    }
    .cellListWidget{
        margin-left: 4px;

        //shorten line for last item
        & > div > div:nth-last-child(n) .idf-history-item:before{
            bottom: 0;
        }
    }
}

.idf-history-item{
    margin-bottom:35px;
    position: relative;
    padding-left:20px;

    &:before{
        content:'';
        position: absolute;
        left: 0;
        top:7px;
        bottom: -46px;
        width:2px;
        background: $flatblue;
        border-radius: 3px;
    }
    &:after{
        content:'';
        position: absolute;
        left: -3px;
        top:7px;
        width: 8px;
        height: 8px;
        background: $flatblue;
        border-radius: 4px;
    }

    .idf-tag {
        margin-right: 14px;
        line-height: 1;
    }
    .idf-row{
        margin-bottom: 10px;
    }
    .idf-change-type {
        @include bigboldtext();
        margin-bottom: 3px;
    }
    .idf-history-change{
        span {
            margin-right:10px;
        }
    }
    .idf-history-rec{
        margin-bottom:13px;

        .idf-row:last-of-type {
            margin-bottom:0;
        }
    }
}

.idf-history-item-claim{
    margin-bottom:35px;
    position: relative;
    padding-left:20px;

    &:before{
        content:'';
        position: absolute;
        left: 0;
        top:7px;
        bottom: -46px;
        width:2px;
        background: red;
        border-radius: 3px;
    }
    &:after{
        content:'';
        position: absolute;
        left: -3px;
        top:7px;
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 4px;
    }

    .idf-tag {
        margin-right: 14px;
        line-height: 1;
    }
    .idf-row{
        margin-bottom: 10px;
    }
    .idf-change-type {
        @include bigboldtext();
        margin-bottom: 3px;
    }
    .idf-history-change{
        span {
            margin-right:10px;
        }
    }
    .idf-history-rec{
        margin-bottom:13px;

        .idf-row:last-of-type {
            margin-bottom:0;
        }
    }
}

@use "../../utilities/index" as *;

.idf-dashboard {
  .idf-widget-card {
    display: flex;
    padding: 5px;
    min-height: 14vh;
    border-radius: 4px;
    background: white;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);

    .idf-btn.idf-small {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 8px;
      line-height: 20px;
    }
  }

  .idf-title {
    display: block;
    color: #2f4050;
    text-transform: uppercase;
    letter-spacing: 0.035rem;
    font-weight: 600;
    font-size: 0.7rem;
  }

  .idf-simple {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 15px 15px;
    width: 100%;

    .idf-title {
      margin-bottom: 6px;
    }

    p {
      margin: 0.1em 0 0.4em;
      color: $lightblue;
    }

    footer {
      display: flex;
      // margin-top: auto;
      flex-wrap: wrap;
    }

    .idf-stat-display {
      display: flex;

      paper-spinner {
        width: 14px;
        height: 14px;
      }

      &--summary {
        position: relative;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 14px;
        padding-bottom: 30px;
        min-width: calc(50% - 14px);

        .idf-stat-img {
          margin-right: 0;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          object-fit: contain;
        }

        .idf-stat-content {
          padding-right: 0;
          padding-left: 6px;
          width: calc(100% - 44px);
        }

        .idf-stat-display__label {
          display: block;
          margin-bottom: 6px;
          color: #68727a;
          font-weight: 600;
          font-size: 0.8rem;
        }

        .idf-stat-display__number {
          color: $lightblue;
          font-weight: 700;
          font-size: 1.1rem;
        }
      }
    }

    .idf-stat-img {
      margin-right: 5px;
      width: 34px;
      height: 34px;
      border-radius: 4px;
      background: no-repeat center;
      background-size: 100%;

      &.idf-type-policy {
        background-image: url("../img/graph/node-type/white/policy.svg");
      }
    }

    .idf-stat-content {
      padding-right: 2.5vw;
    }
  }

  .idf-stats {
    position: relative;
    height: 19vh;

    &--hasPeriod {
      position: relative;
      padding-bottom: 20px;

      .idf-stats__period {
        position: absolute;
        bottom: 15px;
        left: 15px;
        display: inline-flex;
        align-items: center;
        color: rgba($lightblue, .8);
        font-size: 0.7rem;
        line-height: 1.2;

        p {
          margin-bottom: 0;
        }

        .bi {
          margin-right: 4px;
          width: 12px;
          height: 12px;
        }
      }
    }

    paper-spinner {
      position: absolute;
      top: calc(50% - 14px);
      left: calc(50% - 14px);
    }

    .idf-stat {
      position: relative;
      padding-top: 30px;
      width: 100%;
    }

    .idf-title {
      @include allcapslabel();
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      padding: 12px 5px 14px;
      color: #2f4050;
    }
  }

  .idf-last-triggered-rules {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 35.5vh;
    $columns: 1fr 15% 10% 12% 12% 20% 37px;
    @include mediumTable($columns);

    .idf-table {
      overflow: hidden;
      height: 100%;
    }

    header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      button {
        display: inline-block;
        float: right;
        padding: 0;
        border: none;
        background: none;
        color: #0878CF;
        letter-spacing: 0.02rem;
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 1;
        -webkit-appearance: none;
      }
    }
  }

  .idf-flex-table.idf-row {
    margin-top: 1px;
    background: white;
  }

  aside {
    .idf-flex-table.idf-row {
      margin-top: 0;
    }
  }

  .idf-summary {
    &--hasPeriod {
      position: relative;
      padding-bottom: 20px;

      .idf-summary__period {
        position: absolute;
        bottom: 15px;
        left: 15px;
        display: inline-flex;
        align-items: center;
        color: rgba($lightblue, .8);
        font-size: 0.7rem;
        line-height: 1.2;

        p {
          margin-bottom: 0;
        }

        .bi {
          margin-right: 4px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.idf-widget-list {
  display: none;
  overflow-y: scroll;
  margin: 0;
  padding: 10px;
  max-height: 540px;
  border-bottom: 1px solid $darkgray;
  background: #f8f8f8;
  list-style-type: none;

  &.is-active {
    display: block;
  }

  &__item {
    margin-bottom: 20px;
  }

  .idf-widget-item {
    margin-bottom: 20px;
  }
}

.idf-widget-item {
  position: relative;

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    color: $darkblue;
    font-weight: 600;
    font-size: 13px;

    span {
      display: inline-block;
      width: calc(100% - 40px);
      cursor: all-scroll;

      small {
        display: block;
        color: $flatlightblue;
        line-height: 1.4;
      }
    }

    i {
      position: relative;
      top: -2px;
      display: inline-block;
      display: inline-block;
      margin-right: 8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $flatblue;
      box-shadow: 6px 0 0 0 $flatblue;
      cursor: all-scroll;

      &:before {
        position: absolute;
        bottom: -6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $flatblue;
        box-shadow: 6px 0 0 0 $flatblue;
        content: '';
      }

      &:after {
        position: absolute;
        top: -6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $flatblue;
        box-shadow: 6px 0 0 0 $flatblue;
        content: '';
      }
    }
  }

  &__button {
    padding: 0;
    width: 33px;
    border: none;
    background: transparent;
    color: $blue;
    font-weight: 600;
    font-size: 12px;
    transition: color 0.3s;

    i {
      margin-right: 6px;
    }

    &.is-active,
    &:hover {
      color: $orange;
    }
  }

  &__image {
    margin: 0;
    padding: 5px;
    max-width: 100%;
    width: 100%;
    border: 1px solid $darkgray;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color 0.3s;

    img {
      max-width: 100%;
      width: 100%;
      cursor: all-scroll;
    }
  }

  &__preview {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 6px;
    border: none;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.2);
    color: $lightblue;
    vertical-align: middle;
    transition: all 0.3s;

    svg {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }

    img {
      display: none;
    }

    &:hover {
      color: $orange;
    }
  }

  &:Hover {
    .idf-widget-item__image {
      border-color: rgba($orange, .4);
    }
  }

  &.is-active {
    .idf-widget-item__image {
      border-color: $orange;
    }
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
    user-select: none;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: rgba(#ffffff, 0.6);
      content: '';
    }
  }
}

.idf-widget-options {
  position: absolute;
  right: -210px;
  bottom: -246px;
  width: 200px;
  height: auto !important;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 16px rgba(0,0,0,0.15);

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid $darkgray;
    background: #fff;
    color: $darkblue;
    font-size: 0.9rem;
    transition: background 0.3s;

    &:not(.idf-hidden) {
      &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      &:last-of-type {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    strong {
      font-weight: 600;
      font-size: 0.85rem;
    }

    &:hover {
      background: #f1f6fa;
    }

    &--first {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &--last {
      border-bottom: none;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &--noHover {
      &:Hover {
        background: none;
      }
    }

    &--remove {
      color: $orange;
    }

    &.has-sub {
      cursor: pointer;

      &:Hover {
        .idf-widget-options__dropdown {
          display: block;
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    right: -126px;
    display: none;
    margin: 0;
    padding: 0;
    width: 120px;
    border: 1px solid $darkgray;
    border-radius: 4px;
    box-shadow: 0 6px 16px rgba(0,0,0,0.15);
    list-style-type: none;

    &:before {
      position: absolute;
      top: 0;
      left: -8px;
      width: 8px;
      height: 100%;
      background: transparent;
      content: '';
    }

    &:after {
      position: absolute;
      top: 0;
      right: -8px;
      width: 8px;
      height: 100%;
      background: transparent;
      content: '';
    }

    .idf-widget-options__button {
      &:last-child {
        border: none;
      }
    }
  }

  &__dimensions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__size {
    padding: 10px;
    width: 33.3333%;
    border: none;
    border-right: 1px solid $darkgray;
    border-bottom: 1px solid $darkgray;
    background: #fff;
    text-align: center;
    transition: background 0.3s;

    &:nth-of-type(3n) {
      border-right: none;
    }

    label {
      display: block;
      margin-top: 6px;
      margin-right: 0;
      margin-bottom: 0;
      font-size: 0.7rem;
      line-height: 1;
      transition: color 0.3s;
    }

    &.active,
    &:hover {
      background: $blue;

      .idf-width-box {
        border-color: #fff;

        &:before {
          background: #fff;
        }
      }

      label {
        color: #fff;
      }
    }
  }
}

.idf-width-box {
  position: relative;
  width: 100%;
  height: 22px;
  border: 1px dashed $flatblue;
  border-radius: 4px;
  transition: border-color 0.3s;

  &:before {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 14px;
    border-radius: 2px;
    background: $flatblue;
    content: '';
    transition: background 0.3s;
  }

  &--25 {
    &:before {
      width: calc(25% - 1.5px);
    }
  }

  &--33 {
    &:before {
      width: 33.333333%;
    }
  }

  &--50 {
    &:before {
      width: calc(50% - 3px);
    }
  }

  &--67 {
    &:before {
      width: 66.666667%;
    }
  }

  &--75 {
    &:before {
      width: calc(75% - 4.5px);
    }
  }

  &--100 {
    &:before {
      width: calc(100% - 6px);
    }
  }
}

.idf-table-widget {
  padding: 5px;
  height: auto !important;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 25px rgba(0,0,0,.08);

  header {
    padding: 5px;
  }

  &--responsive {
    overflow-x: auto !important;
    padding-bottom: 5px;

    .idf-table {
      width: 680px;
    }
  }
}

paper-dialog {
  .idf-widget-item {
    &__top {
      .icon-th {
        display: none;
      }

      span {
        cursor: default;
      }
    }

    &__image {
      img {
        cursor: default;
      }
    }
  }
}

.idf-slot {
  height: 100%;
}

.idf-case-widgets-wrapper {
  padding: 15px;
}

.idf-case-widget {
  border: 1px solid $darkgray;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid $darkgray;
  }

  &__title {
    font-weight: 600;
    font-size: 0.9rem;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 15px;

    .dashboardBox {
      width: 100%;
    }
  }
}

.dashboard-box {
  position: relative;
  margin: 7.5px 0 !important;
  width: calc(33.33333% - 10px);

  &--wide {
    width: calc(50% - 7.5px);
  }

  .dashboardBox {
    padding: 30px 5px;
    border: none;
    border-radius: 6px;

    .dashboardNo {
      margin-bottom: 6px;
      font-size: 24px;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
      }
    }

    .dashboardLabel {
      font-weight: 600;
      font-size: 12px;
    }
  }

  &--unassigned {
    .dashboardBox {
      background-color: #dfe6ec;
      background-image: url("../img/dashboard/icon-unassigned.svg");
      background-position: bottom right;
      background-size: 80px;
      background-repeat: no-repeat;
      transition: box-shadow 0.3s;

      .dashboardLabel,
      .dashboardNo {
        color: #425A70 !important;
      }

      .dashboardLabel {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 0 0 2px rgba(#425A70, .4);
      }
    }
  }

  &--assigned {
    .dashboardBox {
      background-color: #f9eacc;
      background-image: url("../img/dashboard/icon-assigned.svg");
      background-position: bottom right;
      background-size: 80px;
      background-repeat: no-repeat;
      transition: box-shadow 0.3s;

      .dashboardLabel,
      .dashboardNo {
        color: #E19500 !important;
      }

      .dashboardLabel {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 0 0 2px rgba(#E19500, .4);
      }
    }
  }

  &--reAssigned {
    .dashboardBox {
      background-color: #f5dbd0;
      background-image: url("../img/dashboard/icon-reassigned.svg");
      background-position: bottom right;
      background-size: 80px;
      background-repeat: no-repeat;
      transition: box-shadow 0.3s;

      .dashboardLabel,
      .dashboardNo {
        color: #CE4D16 !important;
      }

      .dashboardLabel {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 0 0 2px rgba(#CE4D16, .4);
      }
    }
  }

  &--inProcess {
    .dashboardBox {
      background-color: #cee4f5;
      background-image: url("../img/dashboard/icon-in-process.svg");
      background-position: bottom right;
      background-size: 80px;
      background-repeat: no-repeat;
      transition: box-shadow 0.3s;

      .dashboardLabel,
      .dashboardNo {
        color: #0878CF !important;
      }

      .dashboardLabel {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 0 0 2px rgba(#0878CF, .4);
      }
    }
  }

  &--inProcessSiu {
    .dashboardBox {
      background-color: #ceeef5;
      background-image: url("../img/dashboard/icon-in-process-siu.svg");
      background-position: bottom right;
      background-size: 80px;
      background-repeat: no-repeat;
      transition: box-shadow 0.3s;

      .dashboardLabel,
      .dashboardNo {
        color: #009cbe !important;
      }

      .dashboardLabel {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 0 0 2px rgba(#009cbe, .4);
      }
    }
  }

  &--completed {
    .dashboardBox {
      background-color: #d9ecdb;
      background-image: url("../img/dashboard/icon-completed.svg");
      background-position: bottom right;
      background-size: 80px;
      background-repeat: no-repeat;
      transition: box-shadow 0.3s;

      .dashboardLabel,
      .dashboardNo {
        color: #409E49 !important;
      }

      .dashboardLabel {
        opacity: 0.8;
      }

      &:hover {
        box-shadow: 0 0 0 2px rgba(#409E49, .4);
      }
    }
  }
}

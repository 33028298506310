@use "../../utilities/index" as *;

.idf-aside-content .idf-policy-view {
  overflow: auto;
  height: auto;

  .idf-top-actions {
    display: none!important;
  }

  .idf-top-details {
    position: relative;
    max-width: 100%;
    width: auto;
    border-top: none;
    border-right: none;
    border-left: none;

    .idf-details-left {
      width: 100%;
      border: none;
    }

    .idf-title-wrapper {
      padding: 0;
      border: none;
    }

    .idf-title {
      @include bigboldtext();
    }

    .idf-legacy-quote {
      margin-bottom: 12px;
    }

    .idf-tag {
      @include allcapslabel();
    }

    nav {
      .idf-btn {
        display: none;
      }
    }

    .idf-details-panel {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      width: calc(100% - 100px); //make room for risk score

      .idf-list-detail {
        flex-direction: column;
        margin-bottom: 10px;

        & > * {
          margin-bottom: 7px;
        }
      }

      & > * {
        margin-right: 20px;
        width: calc(50% - 40px)!important;
      }

      *:not(.idf-hidden) {
        display: block!important;
      }

      & > div {
        margin-bottom: 0;
      }
    }

    .idf-contextual-menu .idf-hidden {
      display: block!important;
      height: auto;
    }
    //risk score
    .idf-details-right {
      position: absolute;
      top: 52px;
      right: 0;
      width: 115px;
      height: 115px;
      border: 1px solid $flatblue;
      border-radius: 4px;

      .idf-score {
        margin-top: -11px;
        line-height: 1;
        opacity: 0.85;
      }
    }

    .idf-contextual-menu {
      .idf-btn {
        display: block;
        padding: 6px 22px;
        line-height: 20px;
      }
    }
  }

  .idf-tables {
    padding-top: 0;

    section.idf-hide-view-all {
      padding-bottom: 5px;
    }

    .idf-slot {
      margin: 0;
      padding: 0;
    }

    .idf-table .idf-row {
      background: $gray;
    }
    //table columns remove
    .idf-addresses-data .idf-c-5,
    .idf-cases-data .idf-c-5,
    .idf-cases-data .idf-c-6,
    .idf-cases-data .idf-c-7,
    .idf-cases-data .idf-c-8,
    .idf-drivers-data .idf-c-3,
    .idf-drivers-data .idf-c-5,
    .idf-drivers-data .idf-c-6,
    .idf-risk-profile-data .idf-c-4,
    .idf-risk-profile-data .idf-c-5,
    .idf-risk-profile-data .idf-c-6,
    .idf-vehicles-data .idf-c-5,
    .idf-vehicles-data .idf-c-6,
    .idf-vehicles-data .idf-c-7 {
      display: none;
    }
    //columns resize
    .idf-risk-profile-data .idf-c-1 {
      max-width: 135px;
    }

    .idf-cases-data .idf-c-1 {
      width: 10%;
    }

    .idf-cases-data .idf-c-3,
    .idf-risk-profile-data .idf-c-7,
    .idf-vehicles-data .idf-c-3 {
      width: 15%;
    }

    .idf-cases-data .idf-c-2,
    .idf-cases-data .idf-c-4,
    .idf-drivers-data .idf-c-2,
    .idf-risk-profile-data .idf-c-7,
    .idf-vehicles-data .idf-c-4 {
      width: 20%;
    }

    .idf-risk-profile-data .idf-c-2,
    .idf-risk-profile-data .idf-c-3 {
      width: 25%;
    }

    .idf-addresses-data .idf-c-2 {
      width: 30%;
    }
  }
}

.idf-aside-panel {
  .idf-idresolve-answers__failReasons {
    gap: 0;

    li {
      margin-right: 35px;
      width: auto;

      &:first-child {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
      }

      .idf-info-card {
        padding: 10px;
        width: 220px;
        border: 1px solid $bluegray;
        border-radius: 8px;
        list-style-type: none;
        font-size: 0.8rem;

        &__title {
          display: block;
          margin-bottom: 2px;
          font-weight: 600;
        }
      }
    }
  }
}

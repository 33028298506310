@use "../../utilities/index" as *;

.idf-dashboard-grid {
  margin-bottom: 10px;
  padding: 60px 30px 30px;
  height: 100%;
  border: 1px solid $darkgray;
  border-radius: 4px;
  background: #fff;
  color: $lightblue;

  &__empty {
    margin-top: 50px;
    margin-bottom: 80px;
    text-align: center;

    h1 {
      display: block;
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 2rem;
      line-height: 1.5 !important;
    }

    small {
      font-size: 1rem;

      a {
        color: $lightblue;
        text-decoration: underline;
        font-weight: 600;
        transition: color 0.3s;

        &:hover {
          color: $orange;
        }
      }
    }

    &--view {
      width: 100%;

      & > div {
        margin-right: auto;
        margin-left: auto;
        padding: 50px;
        max-width: calc(100% - 30px);
        width: 600px;
        border: 1px solid $darkgray;
        border-radius: 6px;
        background: #fff;
      }

      small {
        display: block;
        margin-top: 15px;
        margin-bottom: 30px;
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }
  }

  &--edit {
    .idf-bs-row {
      margin-right: -5px !important;
      margin-left: -5px !important;
    }
  }

  .idf-widget-base {
    position: relative;
    z-index: 0;
    margin: 15px 0;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: border-color 0.3s;

    &__edit {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border: none;
      border-top-left-radius: 3px;
      border-bottom-right-radius: 3px;
      background: $orange;
      opacity: 0.2;
      transition: background 0.3s, opacity 0.3s;

      img {
        vertical-align: middle;
      }

      &:hover {
        background: #b53600;
      }
    }

    &:hover {
      border-color: rgba($orange, .6);

      .idf-widget-base__edit {
        opacity: 1;
      }
    }

    &.is-active {
      z-index: 280;
      border-color: $orange;
    }
  }

  & > div {
    position: relative;
  }
}

.idf-bs-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.idf-bs-col {
  padding-right: 15px;
  padding-left: 15px;
}

.idf-dashboard-row {
  position: relative;
  margin-bottom: 60px;
  border: 2px solid #ffab87;
  border-radius: 4px;
  background: #fff;

  &__tool {
    position: absolute;
    top: -32px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;

    div {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 4px 10px 10px;
      height: 32px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: #ffab87;
      color: $orange;
      cursor: all-scroll;
    }

    span {
      display: inline-block;
      margin-right: 8px;
      font-weight: 600;
    }

    img {
      padding: 5px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      transition: background 0.3s;

      &:Hover {
        background: rgba(#fff, .2);
      }
    }
  }

  .col-lg-12,
  .col-lg-3,
  .col-lg-4,
  .col-lg-6,
  .col-lg-8,
  .col-lg-9 {
    transition: flex 0.3s, max-width 0.3s;
  }
}

.idf-dashboard-col {
  // padding-right: 5px;
  // padding-left: 5px;
  &--layout {
    padding: 0;
  }

  &--border {
    border-left: 2px solid #ffab87;
  }
}

.idf-dashboard-item {
  // border: 1px dashed $darkgray;
  // border-radius: 4px;
  // background: #fcfcfc;
  @for $i from 1 through 12 {
    .idf-col-#{$i} {
      padding-right: 15px;
      padding-left: 15px;

      &.is-active {
        position: relative;
        z-index: 280;
      }
    }
  }
  position: relative;
  padding: 5px 10px;
  height: 100%;

  .idf-col-3 {
    width: 33.333333%;
  }

  .idf-col-2 {
    width: 50%;
  }

  .idf-col-1 {
    width: 100%;
  }
}

.idf-add-widget {
  margin: 15px 0;
  padding: 15px;
  min-height: 186px;
  width: 100%;
  height: 100%;
  outline: transparent;
  border: 1px dashed rgba($lightblue, .5);
  border-radius: 4px;
  background: #fff;
  color: $lightblue;
  text-align: center;
  transition: all 0.3s;

  &__icon {
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    border: 1px dashed $lightblue;
    border-radius: 50%;
    line-height: 40px !important;
    transition: opacity 0.3s;
  }

  &__title {
    display: block;
    margin-top: 15px;
    color: $lightblue;
    font-weight: 600;
    font-size: 1rem;
    transition: opacity 0.3s;
  }

  &:hover {
    background: rgba($lightblue, .05);
  }

  &.is-active {
    border-color: $orange;
    background: #e9f4ff;
  }
}

.idf-dashboard-structure {
  color: $lightblue;
  text-align: center;

  &__title {
    font-weight: 600;
    font-size: 18px;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    strong {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      margin: 0 auto;
      color: #607487;
      text-align: center;
      font-weight: 600;
      transform: translateY(-50%);
      user-select: none;
    }

    &:hover {
      .idf-dashboard-structure__box {
        background: #b8ccde;
      }
    }
  }

  &__box {
    height: 120px;
    border-right: 2px solid #fff;
    background: #DFE6EC;
    transition: background 0.3s;
    user-select: none;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-right: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &--3 {
      width: 25%;
    }

    &--4 {
      width: 33.33333%;
    }

    &--6 {
      width: 50%;
    }

    &--8 {
      width: 75%;
    }

    &--12 {
      width: 100%;
    }
  }
}

@use "../../utilities/index" as *;

.idf-global-search-top {
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;
  text-align: left;

  *:focus {
    outline: none;
  }

  paper-input {
    margin-top: 2px;
  }

  .idf-results-wrapper {
    position: absolute;
    z-index: 99;
    display: none;
    flex-direction: column;
    overflow: hidden;
    margin-top: 3px;
    max-height: 505px;
    width: 490px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(9, 30, 66, 0.2) 0 4px 15px, rgba(9, 30, 66, 0.3) 0 0 2px;

    .idf-results-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 4px 6px;
    }
  }

  .idf-tabs-filter {
    position: relative;
    display: flex;
    margin-top: 3px;
    margin-bottom: 13px;
    padding: 0 5px;
    border-bottom: 2px solid $bluegray;
    font-weight: $bold;

    & > div {
      margin: 0 7.5px;
      line-height: 26px;
      opacity: 0.7;
      transition: all 0.3s ease;

      &:hover {
        color: $darkblue;
        opacity: 1;
        cursor: pointer;
      }
    }

    .idf-active {
      position: relative;
      color: $blue;
      opacity: 1;

      &:before {
        position: absolute;
        right: 0;
        bottom: -2px;
        left: 0;
        height: 2px;
        background: $blue;
        content: '';
      }
    }
  }

  paper-spinner {
    align-self: center;
    padding: 10px;
  }

  .idf-results-links {
    display: flex;
    align-items: center;
    padding: 4px 0 4px 15px;
    border-top: 1px solid $bluegray;
    //compensate for links
    span {
      margin-right: 10px;
      font-family: var(--paper-font-common-base_-_font-family, 'Open Sans');
    }
  }

  .idf-search-results {
    display: none;
  }
  //Handle active state
  .idf-active {
    .idf-results-wrapper {
      display: flex;
    }

    paper-input {
      width: 490px !important;
    }
  }
  //Handle Results list on type
  .idf-results {
    .idf-search-results {
      display: block;
    }

    .idf-recent-search {
      display: none;
    }
  }

  .idf-title {
    margin: 0 0 2px;
    padding: 12px 12px 3px;
  }

  .idf-warning {
    padding-right: 12px;
    padding-left: 12px;
    color: $orange;
    font-weight: $bold;
  }

  .idf-search-item,
  .idf-warning {
    display: flex;
    margin-bottom: 1px;
    padding: 8px 10px;
    border-radius: 3px;
    line-height: 0.9;
  }

  .idf-search-item {
    background-color: transparent;
    color: $darkblue;
    line-height: 1;
    transition: background-color 0.3s ease-out;

    &:before {
      margin-right: 4px;
      width: 15px;
      height: 13px;
      background-image: url("../img/magnifier.svg");
      background-position: center;
      background-size: 13px;
      background-repeat: no-repeat;
      content: '';
    }

    .idf-hover-msg {
      margin-left: auto;
      text-align: right;
      opacity: 0;
      transition: 0.3s ease-in;
    }
    //Icon for every Type
    &.idf-policy:before {
      background-image: url("../img/graph/node-type/policy.svg");
    }

    &.idf-address:before {
      background-image: url("../img/graph/node-type/address.svg");
    }

    &.idf-person:before {
      background-image: url("../img/graph/node-type/person.svg");
    }

    &.idf-vehicle:before {
      background-image: url("../img/graph/node-type/vehicle.svg");
    }

    &.idf-in-focus {
      background-color: $bluegray;

      &:after {
        margin-left: auto;
        color: $lightblue;
        content: '↵';
        font-size: 1.6em;
        line-height: 11px;
        opacity: 0.8;
      }

      .idf-hover-msg {
        opacity: 0.8;
      }
    }

    &:hover {
      background-color: $bluegray;
      cursor: pointer;

      .idf-hover-msg {
        opacity: 0.8;
      }
    }
  }
}
//temp

.idf-pending .idf-warning {
  display: none!important;
}

.idf-content-panel, 
.idf-aside-panel {
    --idf-display-block:block;
    --idf-display-hidden:none;

     //vaadin msg style fix
     .requiredField,
     .floatLeft{
         float: none!important;
     }
     
}
paper-button[disabled]{
    opacity: .6;
}
body{
    --lumo-font-size-m: 14px;
    --paper-font-body1_-_font-family: "open sans", "Helvetica Neue", sans-serif;
}
.idf-readonly{
    --idf-display-hidden:block;
    --idf-display-block:none;
}

paper-input {
    label{
        margin:0;
        padding: 0;
    }
}

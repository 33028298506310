@use "sass:string";
@use "sass:list";
@use "variables" as *;
@mixin bodytext() {
  color: $bodytext;
  font-size: 0.8rem;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1;
}
@mixin boldtext() {
  letter-spacing: 0.02rem;
  font-weight: $bold;
  font-size: 0.8rem;
  line-height: 1;
}
@mixin bigboldtext() {
  color: $darkblue;
  letter-spacing: 0.02rem;
  font-weight: $bold;
  font-size: 1rem;
  line-height: 1;
}
@mixin allcapslabel() {
  text-transform: uppercase;
  letter-spacing: 0.035rem!important;
  font-weight: 600!important;
  font-size: 0.7rem!important;
  line-height: 1;
}
@mixin btn-medium-light() {
  padding: 0.6rem 1.3rem;
  border: 1px solid $lightblue;
  background-color: white;
  color: $darkblue;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "open sans", "Helvetica Neue", sans-serif;
  line-height: 1;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
}
@mixin btn-samll-dark() {
  @include allcapslabel();
  padding: 0.4rem 1rem;
  border: 1px solid $lightblue;
  background-color: $lightblue;
  color: white;
  font-family: "open sans", "Helvetica Neue", sans-serif;
  transition: all 0.2s ease;

  &:hover {
    border: 1px solid $darkblue;
    background-color: $darkblue;
  }

  &:focus {
    outline: none;
  }
}
@mixin border-radius() {
  border-radius: 5px;
}
@mixin box-shadow() {
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}
//helpers
@mixin reset-list($display) {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: $display;
  }
}
@mixin box-sizing {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}
//Interactions
@mixin i-scale {
  cursor: pointer;
  transition: all 0.25s ease, transform $ease 200ms;
  transform: scale(1);

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1)!important;
  }

  &:focus {
    outline: none;
  }
}
//responsive
@mixin sm {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin md {
  @media (max-width: 1500px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 1700px) {
    @content;
  }
}
@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
//table filters
@mixin filterVisible ($before: 153px, $after: 226px) {
  &.idf-filters-visible {
    height: calc(100% - #{$before});
    @media only screen and (max-width: 1270px) {
      height: calc(100% - #{$after});
    }
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0,0,0,.1);
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-color: transparent;
    border-radius: 6px;
    background: $black-4;

    &:hover {
      background: rgba(0,0,0,.6);
    }
  }
}

.scrollbar-slim {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-color: transparent;
    border-radius: 6px;
    background: $black-2;

    &:hover {
      background: rgba(0,0,0,.6);
    }
  }
}

.sticky-table-header {
  position: sticky;
  top: 106px;
  z-index: 20;
  box-shadow: 0 -2px 0 0 #f3f3f4;
}
@mixin fixTable($columns) {
  .idf-flex-table {
    @for $i from 1 through list.length($columns) {
      .idf-c-#{$i} {
        @if list.nth($columns, $i) == 1fr {
          flex: 1;
          min-width: 10%;
        } @else {
          width: list.nth($columns, $i);
        }
      }
    }
  }
}
@mixin bigTable($columns) {
  .idf-flex-table {
    @for $i from 1 through list.length($columns) {
      .idf-c-#{$i} {
        @if list.nth($columns, $i) == 1fr {
          flex: 1;
          min-width: 10%;
        } @else {
          width: list.nth($columns, $i);
        }
      }
    }
    @media only screen and (max-width: 1500px) {
      @for $i from 1 through list.length($columns) {
        .idf-c-#{$i} {
          @if list.nth($columns, $i) == 1fr {
            flex: 1;
            min-width: 12%;
          } @else
          if (string.index(to-string(list.nth($columns, $i)), px)) {
            width: list.nth($columns, $i);
          } @else {
            width: calc(#{list.nth($columns, $i)} + 2%);
          }
        }
      }
    }
    @media only screen and (max-width: 1300px) {
      @for $i from 1 through list.length($columns) {
        .idf-c-#{$i} {
          @if list.nth($columns, $i) == 1fr {
            flex: 1;
            min-width: 15%;
          } @else
          if (string.index(to-string(list.nth($columns, $i)), px)) {
            width: list.nth($columns, $i);
          } @else {
            width: calc(#{list.nth($columns, $i)} + 4.5%);
          }
        }
      }
    }
  }
}
@mixin mediumTable($columns) {
  .idf-flex-table {
    @for $i from 1 through list.length($columns) {
      .idf-c-#{$i} {
        @if list.nth($columns, $i) == 1fr {
          flex: 1;
          min-width: 10%;
        } @else {
          width: list.nth($columns, $i);
        }
      }
    }
    @media only screen and (max-width: 1300px) {
      @for $i from 1 through list.length($columns) {
        .idf-c-#{$i} {
          @if list.nth($columns, $i) == 1fr {
            flex: 1;
            min-width: 12%;
          } @else
          if (string.index(to-string(list.nth($columns, $i)), px)) {
            width: list.nth($columns, $i);
          } @else {
            width: calc(#{list.nth($columns, $i)} + 2%);
          }
        }
      }
    }
    @media only screen and (max-width: 1000px) {
      @for $i from 1 through list.length($columns) {
        .idf-c-#{$i} {
          @if list.nth($columns, $i) == 1fr {
            flex: 1;
            min-width: 15%;
          } @else
          if (string.index(to-string(list.nth($columns, $i)), px)) {
            width: list.nth($columns, $i);
          } @else {
            width: calc(#{list.nth($columns, $i)} + 4.5%);
          }
        }
      }
    }
  }
}

@use "../../utilities/index" as *; //Card View
@mixin agentCardView($responsive) {
  .idf-address,
  .idf-header,
  .idf-state {
    display: none;
  }

  .simplebar-content > div {
    width: 100%;
  }

  .simplebar-content > div > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
    @if $responsive == true {
      @media only screen and (min-width: 1650px) {
        & > div {
          width: calc(20% - 20px);
        }
      }
      @media only screen and (max-width: 1650px) {
        & > div {
          width: calc(25% - 20px);
        }
      }
      @media only screen and (max-width: 1400px) and (min-width: 1100px) {
        & > div {
          width: calc(33.33% - 20px);
        }
      }
      @media only screen and (max-width: 1100px) {
        & > div {
          width: calc(50% - 20px);
        }
      }
      @media only screen and (max-width: 630px) {
        & > div {
          width: calc(100% - 20px);
        }
      }
    }

    & > div {
      margin: 10px;
      padding-bottom: 36px;
      width: calc(50% - 20px);
    }
  }

  .idf-table-content {
    height: 100%!important; //cards Container

    .idf-row {
      position: relative;
      display: block;
      margin-top: 0;
      padding-bottom: $cardpadding;
      height: calc(100% + 36px);

      &:hover {
        background: white;
      }

      .idf-only-for-card {
        display: block;
      }

      .idf-firstname,
      .idf-lastname {
        display: inline-flex;
        padding-top: $cardpadding !important;
        width: auto!important;
        color: $darkblue;
        font-weight: $bold;
      }

      .idf-firstname {
        padding-left: $cardpadding!important;
      }

      i.idf-contextual-menu-icon {
        margin-right: -4px;
        height: 30px;
      }

      .idf-view-more {
        @include allcapslabel();
        color: $blue;
        line-height: 30px;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      .idf-flex-row {
        padding: 0;
        border: none;
      }

      .idf-no {
        float: right;
        padding: $cardpadding;
        width: auto;
        color: $darkblue;
      }

      .idf-email {
        padding-left: $cardpadding;
        width: auto;
      }

      .idf-org {
        padding-left: $cardpadding;
      }

      .idf-orgno {
        padding-left: $cardpadding;
      }

      .idf-c-10,
      .idf-c-9 {
        width: auto;
        padding-left: $cardpadding;
        margin: 4px 0;
      }

      .idf-org,
      .idf-orgno {
        display: inline-flex;
        width: auto;
      }

      .idf-card-address {
        margin-top: 2px;
        padding-top: $cardpadding * 0.8;
        padding-bottom: $cardpadding;
        padding-left: $cardpadding;
        line-height: 1.4;
      }

      .idf-action {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 2px $cardpadding !important;
        width: 100%;
        border: 1px solid $bluegray;
        border-radius: 4px;
        background: $gray;
      }
    }
  }
}

.idf-agent-search {
  //Table Columns
  $columns: 8%, 8%, 8%, 15%, 15%, 9%, 10%, 5%, 11%, 8% 37px;
  @include bigTable($columns);
  @media only screen and (max-width: 1500px) {
    .idf-c-7 {
      display: none;
    }
  }
  @media only screen and (max-width: 960px) {
    //only cards
    @include agentCardView(false);
  }
  @media only screen and (max-width: 630px) {
    //only cards
    @include agentCardView(false);

    .simplebar-content > div > div > div {
      width: calc(100% - 20px);
    }
  }

  .idf-table .idf-flex-table.idf-header {
    @extend .sticky-table-header;
  }
  //Card
  .idf-card-view {
    @include agentCardView(true);
  }
}

.idf-agent-policies {
  $columns: 28%, 33%, 9%, 15%;
  @include bigTable($columns);
  // .idf-table .idf-flex-table.idf-header {
  //   @extend .sticky-table-header;
  // }
  .idf-flex-table.idf-row {
    margin-top: 5px;
    background-color: $gray;
  }
}

.idf-search-table {
  .idf-search-wrapper {
    .idf-filters-wrapper {
      margin-bottom: 20px;
    }

    .idf-card {
      margin-top: 10px;
    }
  }
}
// .idf-aside-content {
//   .idf-agent-policies {
//     .idf-table .idf-flex-table.idf-header {
//       position: relative;
//       top: auto;
//       box-shadow: none;
//     }
//   }
// }

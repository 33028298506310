@use "../utilities/index" as *;

.idf-aside-panel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 700px;
  height: 100%;
  border-left: 1px solid $flatblue;
  background: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0);
  transition: all 0.3s ease;
  transform: translateX(100%);
  @media only screen and (max-width: 979px) {
    top: 61px;
    height: calc(100vh - 62px);
  }

  &.idf-active {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(0);
  }

  .idf-aside-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px 5px 25px;
    border-bottom: 1px solid $bluegray;

    div {
      display: flex;
    }
    //Navigation (Back) Support
    .idf-back {
      @include allcapslabel();
      display: inline-flex;
      align-items: center;
      margin-right: 4px;
      padding: 3px;
      font-size: 13px;

      iron-icon {
        margin-right: 3px;
      }
    }

    paper-icon-button {
      transition: color 0.3s;

      &:hover {
        color: $orange;
      }
    }
  }

  .idf-aside-content {
    padding: 0 25px;
    height: calc(100% - 50px);

    .idf-slot {
      margin-right: -18px;
      padding-top: 25px;
      padding-right: 18px;
      padding-bottom: 25px;
      height: 100%;
    }
  }

  .idf-workflow {
    .idf-avatar {
      img[src=""] {
        display: none;
      }
    }
  }

  &--wide {
    max-width: calc(100% - 220px);
    width: 1200px;
  }
}

@use "../utilities/index" as *; //the holder

.idf-contextual {
  position: relative;
  width: 32px;
  height: 32px;
}
//the icon

.idf-contextual-menu-icon {
  position: relative;
  display: block;
  width: inherit;
  height: inherit;
  background-image: url("../img/contextual-menu.svg");
  background-position: center;
  background-size: 26px;
  background-repeat: no-repeat;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 3px;
    background-color: $bluegray;
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover:before {
    opacity: 1;
  }
}
//the menu

.idf-contextual-menu {
  @include reset-list(block);
  position: absolute;
  top: 0.7em;
  right: 1.33em;
  z-index: 99;
  padding: 4px;
  border: 1px solid $darkgray;
  border-radius: 4px;
  border-radius: 4px;
  background-color: #f8fafb;
  box-shadow: 0 6px 16px rgba(0,0,0,0.15);
  color: $bodytext;
  text-align: left;
  transition: all 0.2s $ease, opacity 0.1s ease;
  transform: translateX(-4px) translateY(2.5px);

  &.idf-hidden {
    display: none;
    opacity: 0;
  }

  & li {
    padding: 6px 22px;
    border-radius: 3px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.15s ease;

    &:hover {
      background: #e5e8ea;
    }

    &:active {
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease;
    }
  }
}
//Highcharts Contetxual menu

.highcharts-menu {
  border: 1px solid $darkgray;
  background-color: $gray;
  box-shadow: 0 4px 21px 0 rgba(0, 0, 0, 0.05);
  color: $bodytext;
  transition: all 0.2s $ease, opacity 0.1s ease;

  & li {
    padding: 4px 22px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.15s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &:active {
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease;
    }
  }
}
